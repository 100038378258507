import axios from "axios";
import {getAccessTokenFromCookie} from "../../../auth/TokenTools";


export async function getOpp(oppID){

    var AccessToken = getAccessTokenFromCookie();
    const URL = process.env.REACT_APP_OPP_API + "opp/" + oppID;

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/json', Authorization : `Bearer ${AccessToken}`}
    })

    return (await client.get(URL));

}

export async function activateOpp(oppID){

    var AccessToken = getAccessTokenFromCookie();
    const URL = process.env.REACT_APP_OPP_API + "activateOpp/" + oppID;

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/json', Authorization : `Bearer ${AccessToken}`}
    })

    return (await client.get(URL));

}


export async function getEvents(hostID) {

    var AccessToken = getAccessTokenFromCookie();
    const URL = process.env.REACT_APP_EVENT_API + "events/" + hostID;

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/json', Authorization : `Bearer ${AccessToken}`}
    })
    return (await client.get(URL));

}


export async function getOpportunites(eventID) {

    var AccessToken = getAccessTokenFromCookie();
    const URL = process.env.REACT_APP_EVENT_API + "potentialopportunities/" + eventID;

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/json', Authorization : `Bearer ${AccessToken}`}
    })
    return (await client.get(URL));

}

export async function getOppsInvited(eventID) {

    var AccessToken = getAccessTokenFromCookie();
    const URL = process.env.REACT_APP_EVENT_API + "getinvitedopps/" + eventID;

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/json', Authorization : `Bearer ${AccessToken}`}
    })
    return (await client.get(URL));

}

export async function postOppInvite(eventID, opportunityID){

    let dataObject = {
        eventID:eventID,
        OpportunityID:opportunityID
    }

    var AccessToken = getAccessTokenFromCookie();
    const URL = process.env.REACT_APP_EVENT_API + "eventoppinvite";

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/json', Authorization : `Bearer ${AccessToken}`}
    })

    return (await client.post(URL, JSON.stringify(dataObject)));

}


export async function getOpportunitesForCompany(companyID) {

    var AccessToken = getAccessTokenFromCookie();
    const URL = process.env.REACT_APP_OPP_API + "oppbybiz/" + companyID;

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/json', Authorization : `Bearer ${AccessToken}`}
    })
    return (await client.get(URL));

}

export async function getExpiredOpportunitesForCompany(companyID) {

    var AccessToken = getAccessTokenFromCookie();
    const URL = process.env.REACT_APP_OPP_API + "oppbybizexpired/" + companyID;

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/json', Authorization : `Bearer ${AccessToken}`}
    })
    return (await client.get(URL));

}

export async function getInvitesForOpportunites(oppID) {

    var AccessToken = getAccessTokenFromCookie();
    const URL = process.env.REACT_APP_EVENT_API + "geteventsoppinvitedto/" + oppID;

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/json', Authorization : `Bearer ${AccessToken}`}
    })
    return (await client.get(URL));
}

export async function getVotesForOpportunites(oppID) {

    var AccessToken = getAccessTokenFromCookie();
    const URL = process.env.REACT_APP_OPP_API + "oppvotes/" + oppID;

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/json', Authorization : `Bearer ${AccessToken}`}
    })
    return (await client.get(URL));
}

// Gets the invite status for an invite but as Post
export async function postInviteStatus(eventID, opportunityID){

    let dataObject = {
        eventID:eventID,
        oppID:opportunityID
    }

    var AccessToken = getAccessTokenFromCookie();
    const URL = process.env.REACT_APP_OPP_API + "inviteStatus/";

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/json', Authorization : `Bearer ${AccessToken}`}
    })

    return (await client.post(URL, JSON.stringify(dataObject)));

}

// Posts an Acceptance or decline for an Opp Invite to an event
export async function postInviteResponse(eventID, opportunityID, accepting){

    let dataObject = {
        EventID:eventID,
        OpportunityID:opportunityID
    }

    var AccessToken = getAccessTokenFromCookie();
    var URL='';

    if(accepting){
        URL = process.env.REACT_APP_EVENT_API + "eventoppinviteaccept/";
    }
    else{
        URL = process.env.REACT_APP_EVENT_API + "eventoppinvitedecline/";
    }

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/json', Authorization : `Bearer ${AccessToken}`}
    })

    return (await client.put(URL, JSON.stringify(dataObject)));

}

