import {
    Box,
    Center,
    Container,
    Flex,
    Grid,
    GridItem,
    HStack,
    ListItem,
    Square,
    Text,
    UnorderedList
} from '@chakra-ui/react'
import React, {useState} from "react";
import PreRegisterForm from "../forms/PreRegister";
import { useMediaQuery } from 'react-responsive';

export default function PreRegister() {

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    let myGridWidth, myContainerWidth, myContainerHeight;
    if(isTabletOrMobile){
        myContainerWidth='600px';
        myContainerHeight='1500px';
        myGridWidth='repeat(1, 1fr)';
    }
    else{
        myContainerWidth='1200px';
        myContainerHeight='700px';
        myGridWidth='repeat(2, 1fr)';
    }

    return (

        <Container maxWidth={myContainerWidth} minHeight={myContainerHeight} overflow={"visible"} bg='transparent'>

            <Box maxWidth={myContainerWidth} bg={'white'} minHeight={myContainerHeight} height={"auto"}
                 overflow={"auto"} marginTop={'50px'} padding={'20px'} borderRadius={'10px'}
                 className={'montserrat-free'} textAlign='justify'>
                <Grid h='auto' templateRows='repeat(1, 1fr)' templateColumns={myGridWidth} gap={4}>
                    <GridItem colSpan={1} bg='white' padding={'10px'}>

                        <Box paddingBottom={'15px'}><img src="/Foundry%20Fuel_multi.jpg" width={'500px'}
                                                         alt={'Foundry Fuel Logo'}/></Box>

                        <strong><code>Foundry Fuel</code></strong> is a technology platform for Technologists by
                        Technologists that aims to dramatically improve tech recruitment. We have an exciting roadmap of
                        features and will be going live with the first of these in late 2024.

                        <br/><br/>
                        <strong><code>Our Promise to you</code></strong>

                        <UnorderedList marginTop={'10px'} marginBottom={'20px'}>
                            <ListItem>You are a person, not a commodity</ListItem>
                            <ListItem>We align you with what you are interested in</ListItem>
                            <ListItem>We will never share data unless you ask us to</ListItem>
                            <ListItem>Engage with opportunities at your comfort level</ListItem>
                            <ListItem>Hold those assessing you to account</ListItem>
                            <ListItem>We're founder/startup friendly</ListItem>
                            <ListItem>We give back to your local tech community</ListItem>
                            <ListItem>Technology for technologists by technologists</ListItem>
                        </UnorderedList>
                        <p>No more ghosting, no more false promises and no more bogus job ads. We want to change how
                            technologists find opportunities and how founders find technologists. Be part of that change.</p><br/>
                        <h1 className={'montserrat-h1'}>Let's change how Tech teams are made</h1><br/>

                    </GridItem>
                    <GridItem colSpan={1} bg='white'>
                        <PreRegisterForm></PreRegisterForm>
                        <HStack marginTop={'20px'} spacing='14px'>
                            <Box w='20px' h='20px'>
                                <a href={'https://x.com/FuelTheFoundry'} target={"_blank"} title={'Follow us on X'}><img src='/x.png' width={'20px'}/></a>
                            </Box>
                            <Box w='20px' h='20px'>
                                <a href={'https://www.linkedin.com/company/foundry-fuel'} target={"_blank"} title={'Follow us on LinkedIn'}><img src='/linkedIn.png' width={'20px'}/></a>
                            </Box>
                        </HStack>
                    </GridItem>
                </Grid>
            </Box>
            <Container maxWidth={'1200px'} color={"white"}>Copyright &#169; 2024 Foundry Fuel Ltd | <a href={'/licenses'} target={'_blank'}>Licenses</a> </Container>
        </Container>

    );
}