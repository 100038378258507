import axios from "axios";
import {getAccessTokenFromCookie} from "../../../auth/TokenTools";

export async function getAddresses(businessID) {

    var AccessToken = getAccessTokenFromCookie();
    const URL = process.env.REACT_APP_EVENT_API + "addresses/" + businessID;

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/json', Authorization : `Bearer ${AccessToken}`}
    })
    return (await client.get(URL));

}

export async function postOpp(dataObject){

    var AccessToken = getAccessTokenFromCookie();
    const URL = process.env.REACT_APP_EVENT_API + "event";

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/json', Authorization : `Bearer ${AccessToken}`}
    })

    return (await client.post(URL, dataObject));

}
