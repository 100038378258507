import React, {useState} from "react";
import {getBusinessEvents, getBusinessPastEvents} from "./scripts/InviteData";
import {background, Box, Button, ButtonGroup, Card, CardBody, CardFooter, Container, Divider, Grid, GridItem, Heading, HStack, Image, Stack, StackDivider, Text, useDisclosure, VStack, Wrap, WrapItem} from "@chakra-ui/react";
import {getIDFromCookie} from "../../auth/TokenTools";
import {getBusinessIDFromCog, getHostIDFromCog} from "../../../common/UserAPI/UserCalls";
import {tidyDateTime} from "./scripts/Helper";
import {CalendarIcon} from '@chakra-ui/icons'
import {guardthispage} from "../../auth/PageTools";


export default function ManageE() {
    const [eventsLoaded, setEventsLoaded] = useState(false);
    const [eventsList, setEventsList] = useState([]);
    const [pastEventList, setPastEventList] = useState([])
    const [bizID, setBizID] = useState();


    if(!eventsLoaded){
        guardthispage("Business");
        getIDFromCookie().then((response) => {
            getBusinessIDFromCog(response).then((response2) => {
                setBizID(response2);
                const eventListRaw = getBusinessEvents(response2);
                const pastEventListRaw = getBusinessPastEvents(response2);
                eventListRaw.then(result=>{
                    setEventsList(result.data);
                    //console.log(result.data);
                })
                pastEventListRaw.then(result=>{
                    setPastEventList(result.data);
                    //console.log(result.data);
                })
                setEventsLoaded(true);
            });
        });
    }

    return (

        <Grid templateRows='repeat(1, 1fr)' templateColumns='repeat(7, 1fr)' gap={5} paddingLeft={'10px'}>
            <GridItem colSpan={7} >
                <Box padding={'10px'} textColor={'darkslategray'} >

                    <Heading marginY={'20px'}>Upcoming Events</Heading>
                    <Text marginY={'20px'}>Here are the details of your upcoming events</Text>
                    <Box paddingY={'20px'}>
                        <Wrap justify='left' spacing={'10px'}>
                            {eventsList.map(
                                ((event, index)=>(
                                        <WrapItem  key={event.id}>
                                            <Card width={'340px'} key={event.id} height={'300px'} backgroundColor={'white'} borderWidth={'2px'} borderColor={'gray.200'}>
                                                <CardBody padding={'0px'} margin={'0px'}>
                                                    <Stack mt='6' spacing='1'>
                                                        <Box backgroundColor={'#7CDAFF'} textColor={'slategray'} paddingX={'10px'} paddingY={'5px'}>
                                                            <Heading size='lg'>{event.eventName}</Heading>
                                                        </Box>
                                                        <Box paddingX={'10px'} paddingY={'5px'}>
                                                            <Text size='md' height={'30px'} overflow={'hidden'}><strong>You are presenting : {event.opportunityName}</strong></Text>
                                                            <Divider marginY={'5px'} />
                                                            <Grid templateColumns='repeat(6, 1fr)' gap={0}>
                                                                <GridItem w='100%' colSpan={2}><CalendarIcon h={3} color={"darkslategrey"}/> Date</GridItem>
                                                                <GridItem w='100%' colSpan={4} textAlign={'right'}><strong>{tidyDateTime(event.eventDate)}</strong></GridItem>
                                                            </Grid>
                                                            <Divider marginY={'5px'} />
                                                            <Grid templateColumns='repeat(6, 1fr)' gap={0}>
                                                                <GridItem w='100%' colSpan={2}>Name</GridItem>
                                                                <GridItem w='100%' colSpan={4} textAlign={'right'}>{event.eventAddress1}</GridItem>
                                                                <GridItem w='100%' colSpan={2}>Street</GridItem>
                                                                <GridItem w='100%' colSpan={4} textAlign={'right'}>{event.eventAddress2} {event.eventAddress3}</GridItem>
                                                                <GridItem w='100%' colSpan={2}>Town / City</GridItem>
                                                                <GridItem w='100%' colSpan={4} textAlign={'right'}>{event.eventAddress4}, {event.eventAddress5}</GridItem>
                                                                <GridItem w='100%' colSpan={2}>Postcode</GridItem>
                                                                <GridItem w='100%' colSpan={4} textAlign={'right'}>{event.eventAddress6}</GridItem>
                                                            </Grid>
                                                        </Box>
                                                    </Stack>
                                                </CardBody>
                                            </Card>
                                        </WrapItem>
                                    )
                                ))}
                        </Wrap>
                    </Box>

                    <Divider></Divider>
                    <Heading marginY={'20px'}>Past Events</Heading>
                    <Box paddingY={'20px'}>
                        <Wrap justify='left' spacing={'20px'}>
                            {pastEventList.map(
                                ((event, index)=>(
                                        <WrapItem  key={event.id}>
                                            <Card width={'320px'} key={event.id} backgroundColor={'white'} borderWidth={'2px'} borderColor={'gray.200'}>
                                                <CardBody padding={'0px'} margin={'0px'}>
                                                    <Stack mt='6' spacing='3'>
                                                        <Box backgroundColor={'#FF7E79'} textColor={'white'} paddingX={'10px'} paddingY={'5px'}>
                                                            <Heading size='lg'>{event.eventName}</Heading>
                                                        </Box>
                                                        <Box paddingX={'10px'} paddingY={'5px'}>
                                                            <Text size='md' height={'30px'} overflow={'hidden'}><strong>You presented : {event.opportunityName}</strong></Text>
                                                            <Divider marginY={'5px'} />
                                                            <Grid templateColumns='repeat(6, 1fr)' gap={0}>
                                                                <GridItem w='100%' colSpan={2}><CalendarIcon h={3} color={"darkslategrey"}/> Date</GridItem>
                                                                <GridItem w='100%' colSpan={4} textAlign={'right'}><strong>{tidyDateTime(event.eventDate)}</strong></GridItem>
                                                            </Grid>
                                                            <Divider marginY={'5px'} />
                                                            <Grid templateColumns='repeat(6, 1fr)' gap={0}>
                                                                <GridItem w='100%' colSpan={2}>Name</GridItem>
                                                                <GridItem w='100%' colSpan={4} textAlign={'right'}>{event.eventAddress1}</GridItem>
                                                                <GridItem w='100%' colSpan={2}>Street</GridItem>
                                                                <GridItem w='100%' colSpan={4} textAlign={'right'}>{event.eventAddress2} {event.eventAddress3}</GridItem>
                                                                <GridItem w='100%' colSpan={2}>Town / City</GridItem>
                                                                <GridItem w='100%' colSpan={4} textAlign={'right'}>{event.eventAddress4}, {event.eventAddress5}</GridItem>
                                                                <GridItem w='100%' colSpan={2}>Postcode</GridItem>
                                                                <GridItem w='100%' colSpan={4} textAlign={'right'}>{event.eventAddress6}</GridItem>
                                                            </Grid>
                                                        </Box>
                                                    </Stack>
                                                </CardBody>
                                            </Card>
                                        </WrapItem>
                                    )
                                ))}
                        </Wrap>
                    </Box>

                </Box>
            </GridItem>
        </Grid>


    );



}
