import {ChooseBiz} from "./cards/ChooseBiz";
import {ChooseHost} from "./cards/ChooseHost";
import {ChooseTech} from "./cards/ChooseTech";
import {
    Box, Button,
    ButtonGroup,
    Container,
    Divider,
    Grid,
    GridItem,
    Heading, Link,
    SimpleGrid,
    Text,
    VStack
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {getIDFromCookie} from "../auth/TokenTools";
import {getRegStatus} from "../../common/DataAPI/AuthCalls";
import {Navigate, useNavigate} from "react-router-dom";
import {getUserTypeNameFromCog} from "../../common/UserAPI/UserCalls";
import {useMediaQuery} from "react-responsive";
import {Footer} from "../../common/Footer";
import Cookies from "js-cookie";


export default function Choose() {

    const [cogID, setCogID] = useState("")
    const [status, setStatus] = useState([])

    try{
        const token = Cookies.get('eventID');
        if(token.length>0){
            navigate('/dashboard/tech/vote?event=' + token);
        }
    }
    catch(e){
        console.log("No existing event found")
    }


    const navigate = useNavigate();

    if(cogID.length<1){
        getIDFromCookie().then((response) => {
            setCogID(response);
        });
    }
    else if(cogID.length>1 && status.length<1){
        try {
            //console.log(cogID);
            getRegStatus(cogID).then((response) => {
                if(response.length>0) {
                    var obj = JSON.parse(response);
                    if (obj.complete) {
                        navigate('/dashboard');
                    }
                }
            })
        }
        catch (ex){
            console.log("New User!");
        }
    }

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    let myGridWidth, myContainerWidth, myContainerHeight;
    if(isTabletOrMobile){
        myContainerWidth='600px';
        myContainerHeight='1500px';
        myGridWidth='repeat(1, 1fr)';
    }
    else{
        myContainerWidth='1200px';
        myContainerHeight='700px';
        myGridWidth='repeat(3, 1fr)';
    }

    return (

        <Container maxWidth={myContainerWidth} minHeight={myContainerHeight} overflow={"visible"} bg='transparent'>

            <Box maxWidth={myContainerWidth} bg={'white'} minHeight={myContainerHeight} height={"auto"} overflow={"auto"} marginTop={'50px'} padding={'20px'} borderRadius={'10px'} className={'montserrat-free'} textAlign='justify'>
                <Grid h='auto' templateRows='repeat(1, 1fr)' templateColumns={myGridWidth} gap={1}>
                    <GridItem colSpan={1} bg='white' padding={'10px'}>

                        <Box paddingBottom={'15px'} display="flex" alignItems="center" justifyContent="center">
                            <a href={'/'}>
                                <img src="/Foundry%20Fuel_multi.jpg" width={'500px'} alt={'Foundry Fuel Logo'}/>
                            </a>
                        </Box>

                        <Box height={'auto'} bg='transparent' paddingBottom={'40px'}>
                            <Container maxWidth={'1200px'} bg='transparent' centerContent>
                                <Box maxWidth={'1200px'} padding={'20px'} bg='white' centerContent borderRadius={'10px'}
                                     className={'montserrat-free'} textAlign={"justify"}>

                                    <Box>
                                        <Container maxWidth={'1200px'} bg='transparent' centerContent>
                                            <Grid h='auto' templateRows='repeat(1, 1fr)' templateColumns={myGridWidth} gap={4}>
                                                <GridItem bg='none'><ChooseTech/></GridItem>
                                                <GridItem bg='none'><ChooseBiz/></GridItem>
                                                <GridItem bg='none'><ChooseHost/></GridItem>
                                            </Grid>
                                        </Container>
                                    </Box>


                                </Box>
                            </Container>
                        </Box>

                    </GridItem>
                </Grid>
            </Box>
            <Footer/>
        </Container>

    );
}