
import {useNavigate, useRouteError} from "react-router-dom";
import {Box, Center, Divider, Image, Text} from "@chakra-ui/react";
import FFLogo from "../assets/Foundry Fuel_multi.jpg";
import Unlink from "../assets/ICONS/unlink.png";
import { useSearchParams } from 'react-router-dom'
import * as React from "react";
import {useEffect, useState} from "react";
import {getEventSummary} from "../common/EventAPI/EventCalls";


export default function Event() {

    const [firstPass, setFirstPass] = useState(false)
    const [hasCode, setHasCode] = useState(false);
    const [codeValid, setCodeValid] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [eventData, setEventData] = useState("");
    const [qrSrc, setQrSrc] = useState('');
    const [logoSrc, setLogoSrc] = useState('');

    if(!firstPass) {

        const eventcode = searchParams.get('code')
        if(eventcode!=null){
            setHasCode(true);
            // Use the event code to get a summary from the DB
            getEventSummary(eventcode).then((result)=>{
                setEventData(result.data);
                setQrSrc('data:image/jpeg;base64,' + result.data.qr);
                setLogoSrc('data:image/jpeg;base64,' + result.data.hostImage);
            });

        }
        setFirstPass(true);
    }

    if(!firstPass){
        return (
            <Box bg='white' w='468px' minHeight='468px' margin="auto" overflowY="auto" alignItems={"center"} borderRadius='lg' textAlign={"center"} marginTop={100} p={2} color='#183c67'>
                <a href="/" title="Link to Foundry Fuel Homepage"><img src={FFLogo} alt="Foundry Fuel Logo"/></a>
                <Divider paddingTop={"20px"} />
                <Text fontSize='36px' paddingTop={"20px"}>LOADING</Text>

                <Text fontSize='16px' paddingY={"30px"}>Loading details for you</Text>


            </Box>
        );
    }
    else if(eventData!="" && eventData.name!=""){
        return (
            <Box bg='white' w='468px' minHeight='468px' margin="auto" overflowY="auto" alignItems={"center"} borderRadius='lg' textAlign={"center"} marginTop={20} p={2} color='#183c67'>
                <a href="/" title="Link to Foundry Fuel Homepage"><img src={FFLogo} alt="Foundry Fuel Logo"/></a>
                <Divider paddingTop={"20px"} />

                <Center padding={"0px"}>
                    <img width={300} src={logoSrc}/>
                </Center>
                <Text fontSize='16px'>presents</Text>

                <Center padding={"10px"}>
                    <img width={300} src={qrSrc}/>
                </Center>

                <Text fontSize='36px' paddingTop={"0px"}>{eventData.name}</Text>
                <Text fontSize='20px' paddingBottom={"20px"}>{eventData.when}</Text>

            </Box>
        );
    }
    else{
        return (
            <Box bg='white' w='468px' minHeight='468px' margin="auto" overflowY="auto" alignItems={"center"} borderRadius='lg' textAlign={"center"} marginTop={100} p={2} color='#183c67'>
                <a href="/" title="Link to Foundry Fuel Homepage"><img src={FFLogo} alt="Foundry Fuel Logo"/></a>
                <Divider paddingTop={"20px"} />
                <Text fontSize='36px' paddingTop={"20px"}>LET'S CHECK IN</Text>

                <Center paddingTop={"30px"} >
                    <Image src={Unlink} alt="Error logo" width={"200px"}/>
                </Center>

                <Text fontSize='16px' paddingY={"30px"}>Sorry but it looks like this event doesn't exist or just isn't visible yet for check in. </Text>


            </Box>
        );
    }



}