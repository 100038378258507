import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardFooter,
    Divider,
    GridItem,
    Heading,
    Image,
    Link, SimpleGrid,
    Stack,
    Text
} from "@chakra-ui/react";
import Accountable from '../assets/photos/accountable.jpg';
import onehundred from "../assets/ICONS/road.png";


export function HomeAccountable(){

    return(
        <Card maxW='100%' variant={'elevated'} className={'montserrat-free'}>
            <CardBody padding='20px' paddingTop={'0px'}>
                {/*<Image src={Accountable} alt='Code image' borderRadius='lg' />*/}
                <Stack mt='6' spacing='3'>
                    <SimpleGrid columns={8} spacing={0}>
                        <GridItem colSpan={1}>
                            <img src={onehundred} width={'50px'}/>
                        </GridItem>
                        <GridItem colSpan={7} padding={'12px'}>
                            <Heading size='md' textAlign={'left'}>Roadmap for the future</Heading>
                        </GridItem>
                    </SimpleGrid>
                    <Text textAlign={"justify"}>
                        We have several new products coming each quarter that will make tech recruitment better, more transparent and in your control. Register for free and we'll let you know whats coming and when.
                    </Text>
                </Stack>
            </CardBody>
            {/*<Divider />
            <CardFooter>
                <ButtonGroup spacing='2'>
                    <Link href={process.env.REACT_APP_REG_LINK}>
                        <Button variant='solid' colorScheme='pink'>
                            Register
                        </Button>
                    </Link>
                    <Button variant='ghost' colorScheme='blue'>
                        Find out more
                    </Button>
                </ButtonGroup>
            </CardFooter>*/}
        </Card>
    )
}