import FFLogo from "../assets/Foundry Fuel_blue.jpg";
import {Box, Container} from "@chakra-ui/react";
import * as React from "react";

export default function Licenses() {

    return (
        <Box bg='white' w='468px' minHeight='468px' margin="auto" overflowY="auto" borderRadius='lg' marginTop={100}
             p={2} padding={'20px'} color='#183c67'>
            <img src={FFLogo} alt="Foundry Fuel Logo"/><br/>
            <h1>ICON Licenses</h1>
            <Container padding="20px">
                <ul>
                    <li>
                        <a href="https://www.flaticon.com/free-icons/clock" title="clock icons">Clock icons created by
                            Freepik - Flaticon</a>
                    </li>
                    <li><a href="https://www.flaticon.com/free-icons/superhero" title="superhero icons">Superhero icons
                        created by Freepik - Flaticon</a></li>
                    <li><a href="https://www.flaticon.com/free-icons/sad" title="sad icons">Sad icons created by Flat
                        Icons - Flaticon</a></li>
                    <li><a href="https://www.flaticon.com/free-icons/free" title="free icons">Free icons created by
                        Abrrakhlaed2626 - Flaticon</a></li>
                        <li><a href="https://www.flaticon.com/free-icons/100-percent" title="100 percent icons">100
                            percent
                            icons created by Smashicons - Flaticon</a></li>
                            <li><a href="https://www.flaticon.com/free-icons/event" title="event icons">Event icons
                                created by
                                Freepik - Flaticon</a></li>
                                <li><a href="https://www.flaticon.com/free-icons/road" title="road icons">Road icons
                                    created by
                                    DinosoftLabs - Flaticon</a></li>
                </ul>
            </Container>
            <h1>Photo Licenses</h1>
            <Container padding="20px">
                <ul>
                    <li><p>Photo by ThisIsEngineering:
                        https://www.pexels.com/photo/woman-coding-on-computer-3861958/</p></li>
                        <li><p>Photo by Matheus Bertelli:
                            https://www.pexels.com/photo/photo-of-people-sitting-on-chairs-3321789/</p></li>
                            <li><p>Photo by William Fortunato :
                                https://www.pexels.com/photo/unrecognizable-man-showing-thumb-up-on-city-street-6140706/</p></li>
                                <li><p>Photo by gdtography: https://www.pexels.com/photo/white-textile-911738/</p></li>
                                    <li><p>Photo by fauxels:
                                        https://www.pexels.com/photo/high-angle-shot-of-group-of-professionals-3183172/</p></li>
                                        <li><p>Photo by Helena Lopes:
                                            https://www.pexels.com/photo/group-of-friends-hanging-out-933964/</p></li>
                                            <li><p>Photo by Tomas Anunziata:
                                                https://www.pexels.com/photo/black-concrete-road-during-night-412026/</p></li>
                </ul>
            </Container>


            {/*            <a href="https://www.flaticon.com/free-icons/unlink" target="_blank" title="unlink icons">Unlink icons
                created by kerismaker - Flaticon</a>
            <a href="https://www.flaticon.com/free-icons/activity" target="_blank" title="activity icons">Activity icons
                created by kerismaker - Flaticon</a>
            <a href="https://www.flaticon.com/free-icons/heart" target="_blank" title="heart icons">Heart icons created
                by kerismaker - Flaticon</a>
            <a href="https://www.flaticon.com/free-icons/work-life-balance" target="_blank"
               title="work life balance icons">Work life balance icons created by kerismaker - Flaticon</a>
            <a href="https://www.flaticon.com/free-icons/best-quality" title="best quality icons">Best quality icons
                created by kerismaker - Flaticon</a>
            <a href="https://www.flaticon.com/free-icons/house" title="house icons">House icons created by kerismaker -
                Flaticon</a>
            <a href="https://www.flaticon.com/free-icons/social-networks" title="social networks icons">Social networks
                icons created by kerismaker - Flaticon</a>
            <a href="https://www.flaticon.com/free-icons/programmer" title="programmer icons">Programmer icons created
                by kerismaker - Flaticon</a>
            <a href="https://www.flaticon.com/free-icons/employee-benefit" title="employee benefit icons">Employee
                benefit icons created by kerismaker - Flaticon</a>
            <a href="https://www.flaticon.com/free-icons/space-shuttle" title="space shuttle icons">Space shuttle icons
                created by Freepik - Flaticon</a>*/}
                        {/*
<a href="https://www.flaticon.com/free-icons/complaint" title="complaint icons">Complaint icons created by Uniconlabs - Flaticon</a>

            <p>Photo by ThisIsEngineering: https://www.pexels.com/photo/woman-coding-on-computer-3861958/</p>
            <p>Photo by Matheus Bertelli: https://www.pexels.com/photo/photo-of-people-sitting-on-chairs-3321789/</p>
            <p>Photo by William  Fortunato : https://www.pexels.com/photo/unrecognizable-man-showing-thumb-up-on-city-street-6140706/</p>
            <p>Photo by gdtography: https://www.pexels.com/photo/white-textile-911738/</p>
            <p>Photo by fauxels: https://www.pexels.com/photo/high-angle-shot-of-group-of-professionals-3183172/</p>
            <p>Photo by Helena Lopes: https://www.pexels.com/photo/group-of-friends-hanging-out-933964/</p>
            <p>Photo by Tomas Anunziata: https://www.pexels.com/photo/black-concrete-road-during-night-412026/</p>*/}


        </Box>
);
}