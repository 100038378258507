import {useRouteError} from "react-router-dom";
import FFLogo from "./assets/Foundry Fuel_blue.jpg";
import Complaint from "./assets/ICONS/complaint.png";
import {Box, Center, Circle, Divider, Image, Square, Text} from "@chakra-ui/react";
import * as React from "react";
import { useNavigate } from "react-router-dom";

export default function NotYet() {

    return (
        <Box bg='white' w='468px' minHeight='468px' margin="auto" overflowY="auto" alignItems={"center"} borderRadius='lg' textAlign={"center"} marginTop={100} p={2} color='#183c67'>
            <a href="/" title="Link to Foundry Fuel Homepage"><img src={FFLogo} alt="Foundry Fuel Logo"/></a>
            <Divider paddingTop={"20px"} />
            <Text fontSize='36px' paddingTop={"20px"}>We're checking you out</Text>

            <Center paddingTop={"30px"} >
                <Image src={Complaint} alt="Error logo" width={"200px"}/>
            </Center>

            <Text fontSize='16px' paddingY={"30px"}>Thanks for signing up.<br/><br/>As we get ready with the platform Host and Business accounts need to be approved to prevent abuse. We are currently reviewing your account and should have this reviewed within 24hrs.<br/><br/>This
                is a temporary measure for the initial trials but we appreciate your support.</Text>


        </Box>
    );
}