import axios from "axios";
import {getAccessTokenFromCookie} from "../../../auth/TokenTools";

export async function getEvents(hostID) {

    var AccessToken = getAccessTokenFromCookie();
    const URL = process.env.REACT_APP_EVENT_API + "events/" + hostID;

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })
    return (await client.get(URL));

}

export async function getUnstartedEvents(hostID) {

    var AccessToken = getAccessTokenFromCookie();
    const URL = process.env.REACT_APP_EVENT_API + "unstartedEvents/" + hostID;

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })
    return (await client.get(URL));

}

export async function getPastEvents(hostID) {

    var AccessToken = getAccessTokenFromCookie();
    const URL = process.env.REACT_APP_EVENT_API + "pastEventSummaries/" + hostID;

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })
    return (await client.get(URL));

}


export async function getBusinessEvents(bizID) {

    var AccessToken = getAccessTokenFromCookie();
    const URL = process.env.REACT_APP_EVENT_API + "businessEventInvitesUpcoming/" + bizID;

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })
    return (await client.get(URL));

}

export async function getBusinessPastEvents(bizID) {

    var AccessToken = getAccessTokenFromCookie();
    const URL = process.env.REACT_APP_EVENT_API + "businessEventInvitesPast/" + bizID;

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })
    return (await client.get(URL));

}


export async function getEventStats(eventID) {

    var AccessToken = getAccessTokenFromCookie();
    const URL = process.env.REACT_APP_EVENT_API + "eventinvitestats/" + eventID;

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })
    return (await client.get(URL));

}



export async function getEventPotentialTechCount(eventID) {

    var AccessToken = getAccessTokenFromCookie();
    const URL = process.env.REACT_APP_EVENT_API + "potentialInviteesCount/" + eventID;

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })
    return (await client.get(URL));

}


export async function getOpportunites(eventID) {

    var AccessToken = getAccessTokenFromCookie();
    const URL = process.env.REACT_APP_EVENT_API + "potentialopportunities/" + eventID;

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })
    return (await client.get(URL));

}

export async function getOppsInvited(eventID) {

    var AccessToken = getAccessTokenFromCookie();
    const URL = process.env.REACT_APP_EVENT_API + "getinvitedopps/" + eventID;

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })
    return (await client.get(URL));

}

export async function postOppInvite(eventID, opportunityID){

    let dataObject = {
        eventID:eventID,
        OpportunityID:opportunityID
    }

    var AccessToken = getAccessTokenFromCookie();
    const URL = process.env.REACT_APP_EVENT_API + "eventoppinvite";

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/json', Authorization : `Bearer ${AccessToken}`}
    })

    return (await client.post(URL, JSON.stringify(dataObject)));

}


export async function countTechs(eventID){

    var techCounter =0;
    const techcount = getEventPotentialTechCount(eventID);
    techcount.then(result=>{
        techCounter =result.data;
    })
    return techCounter;

}


