import {Box, Container, Grid, GridItem, ListItem, UnorderedList} from "@chakra-ui/react";
import React from "react";

export default function Terms() {

    return (

        <Container maxWidth={'1200px'} minHeight={'770px'} overflow={"auto"}  bg='transparent'>
            <Box maxWidth={'1200px'} minHeight={'770px'} overflow={"auto"} marginTop={'50px'} padding={'20px'} bg='white' borderRadius={'10px'} className={'montserrat-free'} textAlign={"justify"}>

                <Grid h='200px' templateRows='repeat(1, 1fr)' templateColumns='repeat(6, 1fr)' gap={4}>
                    <GridItem colSpan={6} bg='white' padding={'10px'}>
                        <Box paddingBottom={'50px'}>
                            <strong>Terms and Conditions</strong>
                            <p>
                                Ad penatibus ligula eget congue. Vehicula dui cum venenatis et convallis pretium rhoncus class. Iaculis eget iaculis, varius mattis mus ultricies bibendum. Elementum phasellus quis lectus! Ullamcorper tristique hac habitasse facilisis adipiscing nascetur sodales ultricies molestie integer posuere habitasse. Bibendum convallis accumsan posuere diam sapien aptent volutpat curabitur! Velit nunc dis tortor fringilla aliquet lacinia nullam donec tristique. Fames!
                            </p>
                            <p>
                                Enim pulvinar tristique nascetur mattis fermentum ut ultrices. Ad augue conubia elit tortor commodo nec conubia nullam dolor. Cum hac suscipit, a proin ultricies cum ad magnis. Et phasellus libero sociosqu ridiculus natoque luctus quisque natoque. Congue consectetur ante euismod amet praesent, accumsan nec. Aptent conubia feugiat augue leo! Porttitor sollicitudin primis sagittis leo blandit orci sodales class. Scelerisque euismod nunc ornare tincidunt nec sociis! Habitant imperdiet potenti posuere elit. Lobortis bibendum odio faucibus egestas luctus facilisis eget imperdiet aenean ad? Integer morbi adipiscing dignissim sociis maecenas urna. Sapien montes risus risus tincidunt aliquam potenti vestibulum nunc. Inceptos dignissim pulvinar natoque felis cras imperdiet vehicula aliquam sit laoreet volutpat platea. Magna commodo suspendisse sem, curabitur sociosqu.
                            </p>
                            <p>
                                Consequat dictum nascetur suscipit habitasse vestibulum nunc scelerisque, dictum nam. Dignissim rhoncus gravida varius sagittis cras nam dapibus pharetra netus velit inceptos. Vestibulum diam, suscipit nibh ultrices lacinia felis vel viverra dictumst! Accumsan at purus ad. Ultrices nulla sociis porttitor praesent per netus sed lectus tortor neque, lobortis tristique! Cum fringilla nullam ultrices. Ligula vel sed imperdiet ullamcorper dapibus. Ante dignissim himenaeos tortor cursus curae; eget montes venenatis himenaeos curabitur. Amet habitant eros ullamcorper. Tristique pulvinar orci enim proin morbi ultricies. Sollicitudin aptent lorem nulla varius nisl placerat ac. Pharetra cursus sed euismod. Lacus!
                            </p>
                            <p>
                                Tempus natoque elit sagittis ante varius blandit duis malesuada primis placerat arcu. In nostra quam vestibulum, parturient primis vivamus. Facilisis velit, sodales litora dui. Pellentesque senectus, elementum cras tortor gravida dictumst. Felis mattis, curae; mauris primis justo. Eros viverra odio cursus dictumst. Odio suspendisse id, sollicitudin at urna ornare suscipit varius. Varius ultricies placerat accumsan curabitur phasellus proin felis nullam quis, consequat himenaeos. Nunc fames suspendisse ultricies nisi ac arcu bibendum luctus augue? Id nam est tellus fermentum nisi maecenas ut pretium per, ut libero. Orci suspendisse hac habitasse aliquam interdum bibendum fames justo ridiculus nascetur ut.
                            </p>
                            <p>
                                Sociosqu primis vel metus urna magna erat magnis class massa lobortis auctor condimentum. Diam vehicula cum lacinia porttitor et, dictumst nec auctor in placerat. Adipiscing arcu nunc orci torquent velit parturient ipsum suspendisse donec dis maecenas. Curae; nec, massa duis feugiat! Scelerisque adipiscing convallis imperdiet? Varius lobortis ridiculus pharetra porta. Senectus leo vivamus lobortis cras sagittis elit ultricies lacinia vel molestie lorem ridiculus. Nec, convallis integer etiam habitant convallis odio penatibus dapibus! Turpis molestie dolor molestie scelerisque viverra elit imperdiet non facilisis nostra potenti. Vehicula nunc tincidunt conubia potenti senectus quisque neque class in duis. Diam habitasse duis eu imperdiet dis lacus posuere. Vulputate metus.
                            </p>
                            <p>
                                Ornare consequat hendrerit elit tristique porta porttitor magnis lorem viverra turpis. Vehicula, mattis ac torquent lacinia amet fusce cursus nulla per pharetra? Ad luctus sit tempus curae; elementum commodo praesent fusce. Donec dictum platea mi vulputate dictumst libero inceptos dis facilisi justo ornare. Facilisis inceptos lorem convallis enim inceptos aliquet placerat. Dictum adipiscing gravida venenatis dictumst magna natoque imperdiet pulvinar torquent vulputate sollicitudin. Dapibus adipiscing nullam fermentum laoreet tristique vestibulum conubia id egestas quisque sed primis. Augue quisque turpis arcu metus vulputate congue et sodales nec primis. Fusce lacinia fusce tristique praesent hac. Nibh at convallis amet vestibulum adipiscing cras tincidunt felis luctus vulputate lacinia quam. Nibh tortor vivamus at vehicula viverra torquent nisl nisi nisl tempus. Varius morbi phasellus senectus massa sociosqu, pulvinar feugiat ante vehicula dictumst laoreet! Imperdiet blandit sagittis dui maecenas sed in duis class parturient. Maecenas nisl cum sodales quis non sociosqu imperdiet per aliquet. Molestie odio donec felis lectus, netus mus. Dignissim vulputate gravida, porttitor et ridiculus ultricies.
                            </p>
                            <p>
                                Nam augue dolor adipiscing magna facilisi accumsan eget. Fames porttitor nulla sapien elementum ipsum, nullam lobortis senectus. Lacinia nostra sapien mollis dignissim netus fusce id class condimentum hac blandit dui. Netus conubia etiam pharetra. Curabitur tellus class class vivamus netus sit suspendisse et eu. Lacus cursus sed neque consequat luctus sapien ante sem, erat dictum molestie pretium. Auctor odio fusce blandit adipiscing inceptos pulvinar malesuada. Purus porta maecenas fringilla rutrum duis. Dictum molestie ultrices maecenas natoque pretium malesuada sodales!
                            </p>
                            <p>
                                Mus, lacus luctus dis. Justo proin accumsan iaculis dictum in. Litora dictumst laoreet neque malesuada commodo mattis natoque nibh. Montes massa sociis torquent tempor nisi. Porta morbi ac vehicula urna. Senectus condimentum imperdiet penatibus. Ut curabitur penatibus, aptent nisi habitant dapibus nam per habitant nunc. Per congue nascetur molestie vivamus torquent tincidunt curae;. Id sodales pharetra per risus venenatis volutpat congue nec. Parturient malesuada lacinia velit. Suspendisse rhoncus mattis dictum, aptent vehicula aenean vehicula cursus. Ultricies, fringilla ante quis leo lorem curae; fames scelerisque sapien nunc facilisi! Dictum dictum venenatis curabitur. Eleifend viverra malesuada lobortis turpis a quis nullam. Dolor fermentum auctor molestie. Justo morbi gravida condimentum tincidunt mi magnis nec dictumst euismod blandit. Vulputate fringilla!
                            </p>
                            <p>
                                Condimentum tortor arcu porttitor. Suspendisse aenean lorem placerat vehicula ipsum ridiculus et himenaeos. Porttitor ad tristique gravida, scelerisque pellentesque. Eleifend auctor cubilia penatibus platea vulputate purus. Ut, nec ultrices justo! Vivamus integer hac hac bibendum turpis malesuada euismod nullam justo diam auctor. Et turpis lorem rutrum proin sem tortor ullamcorper maecenas. Ridiculus libero est aliquam lobortis diam cum arcu tincidunt. Rutrum enim dictum rhoncus fermentum amet nunc; elementum morbi mauris dolor. Penatibus mollis imperdiet ultricies. Est quam nam purus, feugiat vestibulum etiam. Aliquam inceptos sociosqu blandit potenti imperdiet primis accumsan consequat sit et! Potenti vivamus id.
                            </p>
                            <p>
                                Curabitur consectetur magna viverra vel montes magnis iaculis? Donec quam blandit euismod blandit nunc velit ipsum sit sagittis elementum sem feugiat. Commodo egestas purus ornare, ornare ligula montes purus. Nec porta aenean sociis! Aliquet mattis morbi, a fusce. Risus ipsum vestibulum lorem senectus tempus sodales sagittis. Sapien et placerat malesuada. Volutpat; odio mi nostra natoque nullam maecenas euismod ipsum consectetur a fermentum auctor. Libero lacus consequat in. Convallis porttitor vulputate lobortis felis cras dis eu? Bibendum varius nec, convallis iaculis. Bibendum, litora mus hac curae; orci. Penatibus nullam ad himenaeos. Adipiscing netus; vivamus consectetur pulvinar. Accumsan mauris ultrices curabitur blandit erat fames ac porttitor nam magna. Hendrerit sagittis venenatis varius nisi taciti? Auctor lobortis varius consectetur lobortis habitasse sollicitudin pharetra mattis eros pretium. Porttitor euismod risus egestas, phasellus dictumst vehicula et dis per nunc tristique. Taciti platea nascetur enim quam ornare pharetra.
                            </p>
                            <p>
                                Lacinia eros neque porta inceptos vestibulum risus. Ornare enim conubia convallis inceptos nunc facilisi nisi suscipit a mauris. Sit aptent primis vel leo nunc euismod condimentum imperdiet cubilia. Risus malesuada mauris vitae est urna per non viverra magna, egestas fringilla. Phasellus elementum cubilia vestibulum metus? Natoque dui et inceptos malesuada curae; dapibus mollis urna! Ante mauris morbi tellus taciti ipsum nec ad dignissim sed dis. Condimentum gravida velit nisl massa erat et massa. Cursus feugiat posuere taciti auctor cras fames bibendum vestibulum mollis convallis. Facilisis non rutrum fames condimentum nam hac turpis litora molestie. Himenaeos et cursus dui purus blandit platea turpis nascetur non. Fringilla vel morbi sem nostra leo volutpat sit viverra odio massa dapibus. Dui posuere vitae, fringilla purus fringilla cubilia eros aliquet. Tristique vitae vulputate dictum senectus morbi semper ligula. Facilisi phasellus sociis auctor blandit gravida et. Facilisis laoreet tempus maecenas lobortis. Aliquet nostra class donec maecenas euismod malesuada. Sed fusce etiam pulvinar nam auctor morbi consequat. Rutrum viverra laoreet malesuada aenean quam, tortor libero amet inceptos vehicula. Est, viverra malesuada nullam? Sit sociosqu aenean porttitor nam. Quam.
                            </p>
                            <p>
                                Torquent lorem vulputate hendrerit. Aptent non montes quam cursus natoque elementum enim massa eros a egestas! Netus orci torquent nam curabitur. Ullamcorper erat ultricies orci ultricies sollicitudin felis tempus ut ornare vel semper. Libero urna tempus porttitor fames vel tellus sed senectus. Habitasse vulputate imperdiet aenean nec fusce consequat per dictumst. Litora platea nam nulla interdum metus neque vestibulum convallis feugiat suscipit elit. Nibh morbi dis mauris. Cursus, phasellus cum nisl. Risus sed molestie cum nulla justo laoreet, porttitor facilisi curabitur euismod facilisis. Tempus rhoncus nec magnis ultricies turpis fermentum arcu orci pellentesque congue dictumst. Felis euismod volutpat netus nibh duis parturient sapien dignissim. Suscipit congue inceptos in odio quisque ultrices pretium vulputate sagittis magna netus curae;. Vel cursus et purus cras volutpat class molestie cum erat lobortis scelerisque. Aenean lectus tortor arcu metus. Pulvinar habitasse convallis placerat semper scelerisque sociis libero luctus donec! Augue tempor ad scelerisque pharetra, turpis suspendisse imperdiet. Gravida lacus consectetur nulla, nibh vivamus senectus habitant. Magna rutrum gravida posuere pharetra aliquam sodales litora mollis nam? Senectus in leo cum interdum elementum pretium litora leo. Habitant aliquam sociosqu diam etiam dignissim egestas curae; viverra porttitor torquent cubilia ornare. Vel sodales diam metus ornare conubia tincidunt.
                            </p>
                            <p>
                                Auctor fermentum sapien ridiculus litora semper primis cum parturient neque. Venenatis condimentum, sagittis nec porta curae; non viverra. Lacinia nostra taciti ridiculus habitant. Interdum etiam ridiculus justo non condimentum nibh et potenti etiam. Montes lorem ornare sociosqu duis, risus vel facilisi duis. Non a ornare id aenean consequat consequat natoque dignissim. Dictumst donec convallis nullam pellentesque, dictum feugiat tempor tristique fusce diam. Arcu litora morbi facilisi lacus ligula tortor leo dignissim. Massa orci urna platea tellus nisl. Diam, rhoncus nulla parturient torquent sodales facilisi nascetur in habitant arcu justo. Dui hendrerit donec ultricies curae; dolor phasellus aptent mauris nulla lacus aliquet. Massa, magna metus sapien. Bibendum senectus, nostra blandit porta eros. Id, pharetra potenti sodales dictumst rutrum hac curabitur nam sodales vitae mattis cubilia. Taciti nascetur nam bibendum sed feugiat accumsan mauris ipsum aliquam ante viverra cubilia. Sapien porttitor mus vestibulum sagittis tellus consequat posuere.
                            </p>
                            <p>
                                Nec euismod conubia neque fermentum; augue pellentesque. Eleifend habitant praesent augue tristique consectetur litora amet orci tellus, ultrices quam. Nisl posuere donec suscipit lorem elementum sem ac cras ultrices elementum. Suscipit hac sapien est etiam tempor fames dapibus. Nam, congue senectus nullam penatibus metus sed cum. Dictumst lorem vel dapibus ultrices nunc mi habitasse eleifend porttitor. Senectus bibendum donec hac nostra interdum. Tortor penatibus, pulvinar luctus parturient ante diam vel dolor congue? Potenti non integer magna est torquent libero nec ligula porta risus? Litora nascetur nec platea nec penatibus nullam inceptos lorem. Tortor, mi interdum cursus proin varius nullam. Senectus himenaeos curae; dui convallis torquent tellus.
                            </p>
                            <p>
                                Ultricies, varius iaculis cras augue elit quam nostra in curae;. Sollicitudin dis pharetra non ipsum volutpat. Auctor eros fames quis neque quis. Mattis dignissim vel integer curae; litora nunc venenatis ut per. Curae; consectetur neque sagittis sodales viverra id augue lectus! Porta integer augue sodales in per tortor ornare erat justo etiam. Cum fringilla conubia eleifend, potenti etiam imperdiet facilisis pellentesque erat himenaeos. Eu hendrerit placerat porta. Hac integer mauris vitae. Platea magnis sollicitudin sociis potenti penatibus pellentesque primis bibendum dapibus cubilia mollis. Fames condimentum fermentum viverra lectus sociis amet molestie interdum velit, ridiculus mollis? Penatibus consequat dictum in feugiat imperdiet litora mollis. Potenti magnis dapibus sapien sit quam laoreet leo, habitasse fusce fermentum massa. Nascetur dui accumsan conubia id mattis himenaeos primis nec iaculis consectetur? Taciti quam sodales tempus rutrum? Enim facilisis diam fermentum mollis euismod nulla mollis bibendum bibendum pretium habitant. Tortor cras vel facilisis facilisis vulputate montes nibh aliquam cras nostra augue. Pharetra tincidunt suscipit mus integer in nibh sollicitudin dictumst accumsan litora ridiculus. Elementum varius non proin pellentesque?
                            </p>
                            <p>
                                Ad tristique aenean arcu aptent, magna proin curabitur at eleifend cras. Eleifend porttitor aliquam venenatis! Enim morbi neque mollis vestibulum nulla. Id dis ornare himenaeos torquent. Ridiculus facilisis himenaeos iaculis placerat morbi aliquam at iaculis. Hac tincidunt felis cum semper, et lacinia sociis sodales varius. Mus nulla lorem erat cras in nullam elementum placerat sociosqu parturient. Augue vehicula nulla fringilla natoque euismod egestas. Vehicula augue quis scelerisque ultrices vestibulum blandit aliquam maecenas! Est nam pharetra ante natoque urna semper nullam mollis amet convallis! Etiam tempus luctus diam eleifend ad malesuada dis lacinia lacinia hendrerit venenatis! Interdum inceptos semper at torquent parturient ut sociosqu. Volutpat montes class torquent himenaeos pulvinar euismod auctor cubilia auctor scelerisque litora torquent. Ultrices fusce pellentesque integer, cubilia blandit tellus suscipit eleifend donec. Turpis elit non iaculis eget malesuada malesuada. Taciti scelerisque ad penatibus urna nascetur fermentum luctus urna vel potenti egestas. In enim tincidunt duis ipsum habitant facilisis leo litora? Vel nisi purus torquent lacus malesuada quisque nisl dictumst. Posuere, condimentum feugiat rutrum maecenas donec sodales arcu quam fames.
                            </p>
                            <p>
                                Magna dictumst mi porta, purus montes tellus sagittis tempor elit adipiscing pulvinar? Purus senectus felis volutpat mus dictumst hendrerit ut vehicula vulputate elementum eget. Posuere, dictum eros primis molestie massa ad hendrerit porta suscipit malesuada venenatis. Quisque urna interdum feugiat. Nisl, semper pharetra facilisis. Donec ullamcorper consectetur vehicula urna ut aenean lacus ultrices cursus. Aenean eros montes tincidunt volutpat velit ligula. Purus at risus tempor mi pellentesque gravida pharetra. Curae; quis.
                            </p>
                            <p>
                                Est risus dis convallis? Praesent penatibus ridiculus cum. Vulputate dui dis maecenas per tincidunt eros potenti? Facilisi vitae ultrices penatibus pretium! Nisl ornare nec facilisis montes pretium curabitur curae; porta. Eu ridiculus nisl rhoncus primis at aptent vulputate odio nascetur iaculis sociis est. Nec enim tincidunt lacinia proin dolor. Metus ultricies eros elit, ligula cursus. Ac.
                            </p>
                            <p>
                                Nunc ipsum erat ultrices quisque libero vestibulum cras! Ornare quis tristique tempor tempor elit felis molestie malesuada cursus ad senectus odio. Urna mattis varius lacus dapibus fringilla senectus non tortor facilisis potenti pharetra cras. Malesuada curae; duis commodo ante. Nec ultrices morbi vulputate, rhoncus cursus. Vivamus vulputate, mi suspendisse. Ridiculus scelerisque hendrerit laoreet urna hac venenatis facilisi ut libero augue quam. Faucibus tellus taciti scelerisque mus. Accumsan himenaeos sapien bibendum felis, fermentum quisque natoque per non. Etiam sagittis platea cubilia donec ridiculus himenaeos egestas mi aliquet.
                            </p>
                            <p>
                                Curae; ornare blandit varius tristique, vel ut varius interdum lectus. Neque est dolor proin risus taciti habitant. Faucibus habitant sociosqu eleifend lorem et et. Felis curabitur lacinia platea phasellus hendrerit ac. Natoque, mollis turpis dapibus quisque non sociosqu. Litora semper velit interdum! Fames erat maecenas nam tincidunt pharetra porttitor parturient morbi eros aliquet? Primis vehicula enim id mollis class eleifend lobortis sollicitudin dignissim egestas nibh venenatis. Pretium dolor fames cum.
                            </p>
                        </Box>

                    </GridItem>
                </Grid>
            </Box>
        </Container>

    );
}