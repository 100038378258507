import {Grid, GridItem} from "@chakra-ui/react";
import HostOne from "../../forms/HostOne";

export default function Host() {

    return (
        <div>
            <HostOne></HostOne>
        </div>
    );
}