export function tidyDateTime(datetimeStr) {
    // Parse the input datetime string
    const date = new Date(datetimeStr);

    // Define options for formatting
    const options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
    };

    // Format the date and time
    return date.toLocaleString("en-US", options);
}