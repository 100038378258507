import React, {useEffect, useState} from "react";
import axios from "axios";
import {useCookies} from "react-cookie";


export function RefreshTokenGrabber(props){

    const [posts, setPosts] = useState([]);
    const [cookies, setCookie, removeCookie] = useCookies(['user']);

    const URL= process.env.REACT_APP_API_AUTH + "/Refresh";

    const client = axios.create({
        baseURL:URL
    })

    const params = new URLSearchParams();
    params.append('refreshToken', props.trim());

    useEffect(() => {
        client.post(URL,params).then((response) => {
            try{
                let JSONtokens = JSON.parse(response.data);
                setCookie('Access', JSONtokens.access_token, { path: '/' });
                setCookie('ID', JSONtokens.id_token, { path: '/' });
                setPosts(response.data);
            }
            catch(err){
                console.log("ERR 101 - Error parsing token into cookies");
            }

        });
    }, []);

    //console.log(posts)

    if(posts.length>0){
        return posts.toString();
    }
    else{
        return null;
    }


}
