import {Box, Button, Divider, Stack, VStack} from '@chakra-ui/react'
import { Icon } from '@chakra-ui/react'
import { MdHome, MdAddLink, MdOutlineCalendarMonth, MdAutoAwesome, MdManageHistory } from 'react-icons/md'

export default function Techno() {
    return (
        <VStack spacing={'15px'} color={'#222222'}>
            <Divider />
            <Button leftIcon={<MdHome/>} w='100%' h='30px' bg='white.100' borderRadius={'lg'} paddingY={'2px'} paddingX={'10px'} justifyContent="flex-start" onClick={event =>  window.location.href='/dashboard/tech'}>Dashboard</Button>
            <Button leftIcon={<MdAddLink/>} w='100%' h='30px' bg='white.100' borderRadius={'lg'} paddingY={'2px'} paddingX={'10px'} justifyContent="flex-start" onClick={event =>  window.location.href='/dashboard/tech/profile'}>My Profile</Button>
            <Button leftIcon={<MdOutlineCalendarMonth/>} w='100%' h='30px' bg='white.100' borderRadius={'lg'} paddingY={'2px'} paddingX={'10px'} justifyContent="flex-start" onClick={event =>  window.location.href='/dashboard/tech/invites'}>Manage Invites</Button>
            <Button leftIcon={<MdAutoAwesome/>} w='100%' h='30px' bg='white.100' borderRadius={'lg'} paddingY={'2px'} paddingX={'10px'} justifyContent="flex-start" onClick={event =>  window.location.href='/dashboard/tech/history'}>Voting History</Button>
        </VStack>
    )
}