import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem, AccordionPanel,
    Box, Button,
    Grid,
    GridItem,
    Heading, Image,
    Text
} from "@chakra-ui/react";
import redseats from "../../assets/host/redseats.jpg";
import bulbs from "../../assets/host/bulbs.jpg";
import woman from "../../assets/host/woman.jpg";
import React from "react";

export default function TechnoDash() {

    return (
        <Grid width={'100%'} templateRows='repeat(1, 1fr)' padding={'20px'} templateColumns='repeat(10, 1fr)' gap={0} textColor={'slateGray'} >


            <GridItem rowSpan={1} colSpan={3} marginRight={'10px'} paddingRight={'10px'}>

                <Heading marginBottom={'10px'}>Let's get started</Heading>
                <Text marginBottom={'20px'}>As a <strong>Technologist</strong> on Foundry Fuel, your power lies in seizing opportunities. These opportunities represent ways that you can engage with companies and co-founders. Update your profile and get invited to events where you can vote for opportunities. When you vote, your details are shared with founders/businesses and they then connect with you offline.</Text>

            </GridItem>
            <GridItem rowSpan={1} colSpan={7} bg={'ghostwhite'} height={'auto'}>
                <Accordion allowToggle width={'100%'} defaultIndex={[0]} >
                    <AccordionItem>
                        <h2>
                            <AccordionButton bg={'lightgray'} textColor={'slategray'} >
                                <Box as='span' flex='1' textAlign='left'>
                                    <strong>1 - Ensure your profile is up to date</strong>
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <Grid width={'100%'} templateRows='repeat(1, 1fr)' padding={'2px'} templateColumns='repeat(3, 1fr)' gap={0} textColor={'slateGray'} >
                                <GridItem rowSpan={1} colSpan={1} bg={'ghostwhite'} height={'auto'} marginRight={'15px'} marginTop={'5px'}>
                                    <Image src={woman} alt='balloons image' borderRadius={'lg'} border={'solid'} marginBottom={'10px'}/>
                                </GridItem>
                                <GridItem rowSpan={1} colSpan={2} bg={'ghostwhite'} height={'auto'}>
                                    You're profile not only advertises you as a technologist but also connects you to events and opportunities in your area so it's essential is up to date and contains as much info as possible.<Button colorScheme='blue' onClick={event =>  window.location.href='/dashboard/tech/profile/'}>Update my Profile</Button>
                                </GridItem>
                            </Grid>
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <h2>
                            <AccordionButton bg={'lightgray'} textColor={'slategray'} >
                                <Box as='span' flex='1' textAlign='left'>
                                    <strong>2 - Review your invites</strong>
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <Grid width={'100%'} templateRows='repeat(1, 1fr)' padding={'2px'} templateColumns='repeat(3, 1fr)' gap={0} textColor={'slateGray'} >
                                <GridItem rowSpan={1} colSpan={1} bg={'ghostwhite'} height={'auto'} marginRight={'15px'} marginTop={'5px'}>
                                    <Image src={redseats} alt='balloons image' borderRadius={'lg'} border={'solid'} marginBottom={'10px'}/>
                                </GridItem>
                                <GridItem rowSpan={1} colSpan={2} bg={'ghostwhite'} height={'auto'}>
                                    When events are created in your area you may be invited to the event. Events have limited spaces so you need to accept any you recieve so that you can check in on the night to vote. Check out any invites here<br/><br/>
                                    <Button colorScheme='blue' onClick={event =>  window.location.href='/dashboard/tech/invites/'}>Manage Invites</Button>
                                </GridItem>
                            </Grid>
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <h2>
                            <AccordionButton bg={'lightgray'} textColor={'slategray'} >
                                <Box as='span' flex='1' textAlign='left'>
                                    <strong>3 - Review your connections</strong>
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <Grid width={'100%'} templateRows='repeat(1, 1fr)' padding={'2px'} templateColumns='repeat(3, 1fr)' gap={0} textColor={'slateGray'} >
                                <GridItem rowSpan={1} colSpan={1} bg={'ghostwhite'} height={'auto'} marginRight={'15px'} marginTop={'5px'}>
                                    <Image src={bulbs} alt='balloons image' borderRadius={'lg'} border={'solid'} marginBottom={'10px'}/>
                                </GridItem>
                                <GridItem rowSpan={1} colSpan={2} bg={'ghostwhite'} height={'auto'}>
                                    Been to events? Not sure what you expressed an interest in? Review the opportunities you were interested in and when you connected with them.<br/><br/>
                                    <Button colorScheme='blue'
                                            onClick={event => window.location.href = '/dashboard/tech/history'}>Voting history</Button>
                                </GridItem>
                            </Grid>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </GridItem>
        </Grid>
    );
}