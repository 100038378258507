import {
    Box,
    Button,
    Card,
    CardBody, CardFooter, CardHeader,
    Container, Grid, GridItem,
    Heading,
    SimpleGrid,
    Stack,
    StackDivider,
    Text,
    VStack, Wrap, WrapItem
} from "@chakra-ui/react";
import InviteStatus from "./components/InviteStatus";
import React, {useState} from "react";
import {
    getEvents, getInvitesForOpportunites,
    getOpportunites,
    getOpportunitesForCompany,
    getOppsInvited, postInviteResponse,
    postOppInvite
} from "../opps/scripts/OppsData";
import {getIDFromCookie} from "../../auth/TokenTools";
import {getBusinessIDFromCog} from "../../../common/UserAPI/UserCalls";
import {guardthispage} from "../../auth/PageTools";

export default function ManageInvites() {

    const [oppsLoaded, setOppsLoaded] = useState(false);
    const [punchback, setPunchback] = useState(false);
    const [inviteList, setInviteList] = useState([])
    const [oppList, setOppList] = useState([]);
    const [oppSelected, setOppSelected] = useState(0);
    const [selectedOppName, setSelectedOppName] = useState("");
    const [bizID, setBizID] = useState()

    if(!oppsLoaded){
        guardthispage("Business");
        getIDFromCookie().then((response) => {
            getBusinessIDFromCog(response).then((response2) => {
                setBizID(response2);
                const oppsListRaw = getOpportunitesForCompany(response2);
                oppsListRaw.then(result=>{
                    setOppList(result.data);
                })
                setOppsLoaded(true);
            });
        });



    }

    if(punchback){
        const inviteListRaw = getInvitesForOpportunites(oppSelected);

        inviteListRaw.then(result=>{
            setInviteList(result.data);
        })
        setPunchback(false);
    }

    function punchOpp(oppId, oppName){
        setInviteList([]);
        setOppSelected(oppId);
        setSelectedOppName(oppName);
        const inviteListRaw = getInvitesForOpportunites(oppId);
        inviteListRaw.then(result=>{
            //console.log(result.data);
            setInviteList(result.data);
        })
    }

    function punchInvite(eventId, response){
        postInviteResponse(eventId, oppSelected, response).then(result=>{
            setInviteList([]);
            setPunchback(true);
        })
    }

    return (
        <Box padding={'20px'} textColor={'darkslategray'}>
            <Grid templateRows='repeat(1, 1fr)' templateColumns='repeat(5, 1fr)' gap={2}>
                <GridItem colSpan={3} >
                    <Heading>View your invites to events</Heading>
                    <Text marginY={'20px'}>If you have been invited to pitch at an event the invitations will be stored here awaiting your response. Be quick though as the event will have to happen at some point and the host will want to know if you are pitching.</Text>
                    <Box>
                        <VStack divider={<StackDivider borderColor='gray.200' />} spacing={4} align='stretch'  >
                            <Heading size='md'>Active Opportunities</Heading>
                            <Wrap>
                                {oppList.map(
                                    ((opp, index)=>(
                                            <WrapItem>
                                                <Card width={'207px'} height={'250px'} key={opp.id} background={'whitesmoke'}>
                                                    <CardHeader borderBottomColor={'slategray'} borderBottomWidth={'1px'}><Heading size='md' margin={'0px'}>{opp.name}</Heading></CardHeader>
                                                    <CardBody background={'white'} borderBottomColor={'slategray'} borderBottomWidth={'1px'}>
                                                        <Text><strong>Type</strong> : {opp.opportunityTypeName}</Text>
                                                        <Text marginTop={'2px'}><strong>Pending Invites</strong> : {opp.inviteCount}</Text>
                                                    </CardBody>
                                                    <CardFooter margin={'0px'}>
                                                        <Button variant='solid' margin={'0px'} marginRight={'5px'} colorScheme='blue' onClick={() => {punchOpp(opp.id, opp.name)}}>See Invites</Button>
                                                    </CardFooter>
                                                </Card>
                                            </WrapItem>
                                        )
                                    ))}
                            </Wrap>
                        </VStack>
                    </Box>
                </GridItem>
                <GridItem colSpan={2} borderLeft={"solid"} paddingLeft={'20px'}>
                    {selectedOppName!=="" ? (<Heading as={'h2'} size={'lg'}>Invites for {selectedOppName}</Heading> ) : ( <Heading as={'h2'} size={'lg'}>Invites</Heading> )}
                    {selectedOppName!=="" ? (<Text marginY={'20px'}>Any pending invites are listed below</Text> ) : ( <Text marginY={'20px'}>Select an opportunity on the left and any invites will be displayed here</Text>)}



                    <Box>
                        <VStack divider={<StackDivider borderColor='gray.200' />} spacing={4} align='stretch'  >

                            <Wrap>



                                {inviteList.map(
                                    ((invite, index)=>(
                                            <WrapItem>
                                                <Card width={'400px'} key={invite.id} background={'whitesmoke'} marginBottom={'20px'}>
                                                    <CardHeader borderBottomColor={'slategray'} borderBottomWidth={'1px'}>
                                                        <Grid templateColumns='repeat(5, 1fr)' gap={0} backgroundColor={'whitesmoke'}>
                                                            <GridItem w='100%' h='10' colSpan={3}><Heading size='md' margin={'0px'} marginTop={'4px'} alignItems="center">{invite.name}</Heading></GridItem>
                                                            <GridItem w='100%' h='10' colSpan={2} display="flex" justifyContent="flex-end"><InviteStatus eventId={invite.id} oppId={oppSelected}/></GridItem>
                                                        </Grid>
                                                    </CardHeader>
                                                    <CardBody background={'white'} borderBottomColor={'slategray'} borderBottomWidth={'1px'}>
                                                        <Heading size='md'>{invite.name}</Heading>
                                                        <Text>Date : {invite.when}</Text>
                                                        <Text>{invite.notes}</Text>
                                                    </CardBody>
                                                    <CardFooter margin={'0px'}>
                                                            <Button variant='solid' width={'100px'} margin={'5px'} marginTop={'0px'} colorScheme='green' onClick={() => {punchInvite(invite.id, true)}}>Accept</Button>
                                                            <Button variant='solid' width={'100px'} margin={'5px'} marginTop={'0px'} colorScheme='red' onClick={() => {punchInvite(invite.id, false)}}>Decline</Button>
                                                    </CardFooter>
                                                </Card>
                                            </WrapItem>
                                        )
                                    ))}
                            </Wrap>
                        </VStack>
                    </Box>
                </GridItem>
            </Grid>
        </Box>
    );
}
