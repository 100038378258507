import axios from "axios";
import {useEffect} from "react";
import {jwtDecode} from "jwt-decode";
import qs from 'qs';
import {RefreshTokenGrabber} from "./RefreshTokenGrabber";

async function checkAccessToken(){

    let result = false;
    let cookies = document.cookie;
    const cookieArray=cookies.split(";");

    const URL= process.env.REACT_APP_API_AUTH + "/authCheck";

    var AccessToken="";
    cookieArray.forEach((element) => {if(element.indexOf("Access=")>=0){AccessToken=element}});
    AccessToken = AccessToken.replace("Access=","");

    const client = axios.create({
        baseURL:URL,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })

    const params = new URLSearchParams();
    params.append('accessToken', AccessToken.trim());

    try{
        client.get(URL).then(
            function (response){
                result = true;
                return result;

            }
        ).catch(
            function(error){console.log(error)
                if(error.response.status==401){
                    console.log("401 : Unauthorised Access Token")
                }
            }

        );

    }
    catch (err){
        console.log("ERROR : " + err);
    }

    return result;

}

export function doRefresh(){

    let cookies = document.cookie;
    const cookieArray=cookies.split(";");

    var RefreshToken="";
    cookieArray.forEach((element) => {if(element.indexOf("Refresh=")>=0){RefreshToken=element}});
    RefreshToken = RefreshToken.replace("Refresh=","");

    //console.log("Refreshtoken " + RefreshToken);

    const refreshResponse = RefreshTokenGrabber(RefreshToken);

}

export function getAccessTokenFromCookie(){

    let cookies = document.cookie;
    const cookieArray=cookies.split(";");

    var AccessToken="";
    cookieArray.forEach((element) => {if(element.indexOf("ID=")>=0){AccessToken=element}});
    AccessToken = AccessToken.replace("ID=","");

    const URL= process.env.REACT_APP_API_AUTH + "/GetCognitoUserInfo";


    const client = axios.create({
        baseURL:URL,
        headers: {'content-type': 'application/x-www-form-urlencoded' }
    })

    return AccessToken;  

}

export function isTokenExpired() {

    let cookies = document.cookie;
    const cookieArray=cookies.split(";");

    var AccessToken="";
    cookieArray.forEach((element) => {if(element.indexOf("ID=")>=0){AccessToken=element}});
    AccessToken = AccessToken.replace("ID=","");

    // Split the token to get the payload (second part of the JWT token)
    const tokenParts = AccessToken.split('.');
    if (tokenParts.length !== 3) {
        throw new Error("Invalid token format");
    }

    // Decode the payload (it's base64 encoded)
    const payload = JSON.parse(atob(tokenParts[1]));

    // Get current time in seconds since UNIX epoch
    const currentTime = Math.floor(Date.now() / 1000);

    // Check if the token has expired
    return payload.exp < currentTime;
}

export async function getUserFromCookie(){

    let cookies = document.cookie;
    const cookieArray=cookies.split(";");

    try{
    var AccessToken="";
    cookieArray.forEach((element) => {if(element.indexOf("Access=")>=0){AccessToken=element}});
    AccessToken = AccessToken.replace("Access=","");

    const URL= process.env.REACT_APP_API_AUTH + "/GetCognitoUserInfo";


    const client = axios.create({
        baseURL:URL,
        headers: {'content-type': 'application/x-www-form-urlencoded' }
    })

    const params = new URLSearchParams();
    params.append('accessToken', AccessToken);


    let response = client.post(URL, params);
    return response;
    }
    catch(e){
        console.log('issue in getUserFromCookie');
        return "";
    }


}

export async function getIDFromCookie(){

    let cookies = document.cookie;
    const cookieArray=cookies.split(";");

    try{
        var IDToken="";
        cookieArray.forEach((element) => {if(element.indexOf("ID=")>=0){IDToken=element}});
        IDToken = IDToken.replace("ID=","");

        const decoded = jwtDecode(IDToken);
        //console.log(decoded);

        return(decoded["sub"]);
    }
    catch(e){
        console.log('issue in getIDFromCookie');
        return "";
    }



}

export function getEmailFromCookie(){

    let cookies = document.cookie;
    const cookieArray=cookies.split(";");
try{
    var IDToken="";
    cookieArray.forEach((element) => {if(element.indexOf("ID=")>=0){IDToken=element}});
    IDToken = IDToken.replace("ID=","");

    const decoded = jwtDecode(IDToken);
    //console.log(decoded);

    return(decoded["email"]);
}
catch(e){
    console.log('issue in getEmailFromCookie');
    return "";
}

}

export async function isValidToken(){

    let cookies = document.cookie;
    const cookieArray=cookies.split(";");

    var AccessToken="";
    cookieArray.forEach((element) => {if(element.indexOf("Access=")>=0){AccessToken=element}});
    AccessToken = AccessToken.replace("Access=","");

    const URL= process.env.REACT_APP_API_AUTH + "/GetCognitoID";
    const URL2= process.env.REACT_APP_API_AUTH + "/authCheck";

    const client = axios.create({
        baseURL:URL,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })

    try{
        client.get(URL2).then(
            function (response){
                //console.log(response);
                return response;
            }
        ).catch(
            function(error){console.log(error)
                if(error.response.status==401){
                    console.log("401")
                }
            }

        );

    }
    catch (err){
        console.log("ERROR : " + err);
    }
}

export async function tryToValidate(){

    // Check the AccessToken
    console.log("Validating current Access Token");

    if(!checkAccessToken()){
        console.log("Access Token failed - Trying to reissue with Refresh Token");
        // If Access Fails try Refresh and Access again
        doRefresh();
        console.log("Validating new Access Token");
        if(!checkAccessToken()){
            // If all fails kick back
            console.log("Validation failed");
            return false;
        }
   }
    else{console.log("Validation success");return true;}

}