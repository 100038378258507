import React, {useEffect, useState} from "react";
import NewTokenGrabber from "./auth/NewTokenGrabber";
import UserPool from "../UserPool";
import {Box, Container, Spinner, StackDivider, VStack} from "@chakra-ui/react";
import FFLogo from "../assets/Foundry Fuel_multi.jpg";

export default function Auth() {

    const url = process.env.REACT_APP_COGNITO_URL;
    const queryParameters = new URLSearchParams(window.location.search)
    const AWSCode = queryParameters.get("code");
    const clientId = UserPool.getClientId();
    const userPoolId = UserPool.getUserPoolId();
    const clientSecret = process.env.REACT_APP_COGNITO_CLIENT_SEC;
    const AUTH = btoa(clientId + ":" + clientSecret);
    const body = `client_id=${clientId}&client_secret=${clientSecret}&code=${AWSCode}&redirect_uri=https://localhost:3000&grant_type=authorization_code`


    return (
        <div>
            <p><NewTokenGrabber code={AWSCode}></NewTokenGrabber></p>
            <Container width={'350px'} marginY={'30px'} padding={'15px'} bg='white' borderColor={'lightgray'} borderRadius='lg' textAlign={"center"}>
                <Box width={'320px'} margin={'0px'} padding={'0px'} marginTop={'20px'} borderRadius='lg'>
                    <VStack spacing={4} align='stretch'>
                        <Box h='140px' alignContent={'center'}>
                            <center><img src={FFLogo} alt="Foundry Fuel Logo" width={'250px'}/></center>
                        </Box>
                        <Container><Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' /></Container>

                        <Box>Loading</Box>
                    </VStack>
                </Box>
            </Container>

        </div>
    );
}