import React, {useState} from "react";
import {getEventPotentialTechCount, getPastEvents} from "../scripts/InviteData";
import {Grid, GridItem} from "@chakra-ui/react";
import {StarIcon} from "@chakra-ui/icons";

export default function HowManyTechs(props) {

    const [hasLoaded, setHasLoaded] = useState(false);
    const [counter, setCounter] = useState(0);

    if(!hasLoaded){
        const techcount = getEventPotentialTechCount(props.id);
        techcount.then(result=>{
            setCounter(result.data);
        })
        setHasLoaded(true);
    }


    return(
        <Grid templateColumns='repeat(6, 1fr)' gap={0} margin={'0px'} padding={'0px'}>
            <GridItem w='100%' colSpan={5}><StarIcon h={3} color={"darkslategrey"}/> Potential Techs</GridItem>
            <GridItem w='100%' colSpan={1} textAlign={'right'}>{counter}</GridItem>
        </Grid>
    )

}