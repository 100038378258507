import {
    Box,
    Button,
    Container,
    FormControl,
    Grid, GridItem,
    Heading,
    Input,
    InputGroup,
    InputLeftAddon,
    Text,
    useToast
} from "@chakra-ui/react";
import {
    getAddressBookFromHostID,
    getCountries,
    getHostIDFromCog,
    getRegionsByCountry, postAddress
} from "../../../../common/UserAPI/UserCalls";
import {getIDFromCookie} from "../../../auth/TokenTools";
import {useEffect, useState} from "react";
import {useFormik} from 'formik';
import {guardthispage} from "../../../auth/PageTools";


export default function CreateAddressForm() {

    // State Variables
    const [firstLoad, setFirstLoad] = useState(false);
    const [addressData, setAddressData] = useState("");
    const [regionList, setRegionList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [countrySelected, setCountrySelected] = useState(0);
    const [date, setDate] = useState(new Date());
    const [hostID, setHostID] = useState(0);
    const [submitted, setSubmitted] = useState(false);
    const [addressBookId, setAddressBookId] = useState(0);

    const toast = useToast()

    // Form filler functions
    if(!firstLoad) {

        guardthispage("Host");
        getCountries().then(countries => {
            setCountryList(countries);
        });

        getIDFromCookie().then((response) => {
            getHostIDFromCog(response).then((response2) => {
                setHostID(response2);
                getAddressBookFromHostID(response2).then((response3) => {
                    setAddressBookId(response3);
                })
                setFirstLoad(true);
            });
        });
    }

    function handleCountryChange(countryId){
        const selected = countryId;
        setCountrySelected(selected);
        getRegionsByCountry(selected).then(regions => {
            setRegionList(regions);
        });
    }

    const validate = values => {
        const errors = {};
        // name errors
        if (!values.name) { errors.name = 'Please enter your property name'; }
        // theme errors
        if (!values.number) { errors.number = 'Please enter the street number'; }
        // address errors
        if (values.street=="") { errors.street = 'Please enter the street name'; }
        // address errors
        if (values.town=="") { errors.town = 'Please enter the town name'; }
        // address errors
        if (values.city=="") { errors.city = 'Please enter the city name'; }
        // address errors
        if (values.region=="") { errors.region = 'Please select a region'; }
        // address errors
        if (values.country=="") { errors.country = 'Please select a country'; }

        return errors;
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            number:'',
            street:'',
            town: '',
            city: '',
            postcode:'',
            country:'',
            region:''
        },
        validate,
        onSubmit: values => {

                let fullData = {
                    ID:0,
                    Name:values.name,
                    Number:values.number,
                    Street:values.street,
                    Town:values.town,
                    City:values.city,
                    Postcode:values.postcode,
                    CountryID:Number(values.country),
                    RegionID:Number(values.region),
                    AddressBookID:addressBookId,
                }
                const dataObject = (JSON.stringify(fullData, null, 2));
                //console.log(dataObject);

                postAddress(fullData, addressBookId).then((response2) => {
                    toast({
                        title: 'Venue logged',
                        description: "Your Address has been posted",
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    });
                });

/*            // Post the data object
            postOpp(JSON.stringify(fullData)).then(function (response) {
                //console.log(response);
                toast({
                    title: 'And we are off!',
                    description: "Your Address has been posted",
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
            })
                .catch(function (error) {
                    toast({
                        title: 'Issues detected!',
                        description: "Nope sorry - something ain't working as expected",
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                });*/

        },
    });

    useEffect(() => {
        if(firstLoad){
            const selectedCountry = formik.values.country;
            handleCountryChange(selectedCountry);
            formik.setFieldValue('region', ''); // Reset city selection when country changes
        }}, [formik.values.country]);



    return (
        <Box padding={'10px'}  textColor={'darkslategray'}>


            <Grid templateRows='repeat(1, 1fr)' templateColumns='repeat(5, 1fr)' gap={10}>
                <GridItem colSpan={3} >
                    <Heading>Create a new venue address</Heading>

                    <Text marginY={'20px'}>In order to create your events you need to first create a venue and this means adding it to your Address Book. You can add a new venue address by completing the following form.</Text>

                    <FormControl textColor={'darkslategray'}>
                        <form onSubmit={formik.handleSubmit}>
                            <InputGroup marginY={'5px'}>
                                <InputLeftAddon width={'120px'}>Name</InputLeftAddon>
                                <Input id='name' name='name' onChange={formik.handleChange} value={formik.values.name}/>
                            </InputGroup>

                            <InputGroup marginY={'5px'}>
                                <InputLeftAddon width={'120px'}>Number</InputLeftAddon>
                                <Input id='number' name='number' onChange={formik.handleChange} value={formik.values.number}/>
                            </InputGroup>

                            <InputGroup marginY={'5px'}>
                                <InputLeftAddon width={'120px'}>Street</InputLeftAddon>
                                <Input name="street"  onChange={formik.handleChange} value={formik.values.street}/>
                            </InputGroup>


                            <InputGroup marginY={'5px'}>
                                <InputLeftAddon width={'120px'}>Town</InputLeftAddon>
                                <Input name="town"  onChange={formik.handleChange} value={formik.values.town}/>
                            </InputGroup>

                            <InputGroup marginY={'5px'}>
                                <InputLeftAddon width={'120px'}>City</InputLeftAddon>
                                <Input name="city"  onChange={formik.handleChange} value={formik.values.city}/>
                            </InputGroup>


                            <InputGroup marginY={'5px'}>
                                <InputLeftAddon width={'120px'}>Postcode</InputLeftAddon>
                                <Input name="postcode" onChange={formik.handleChange} value={formik.values.postcode}/>
                            </InputGroup>

                            <InputGroup marginY={'5px'}>
                                <InputLeftAddon width={'120px'}>Country</InputLeftAddon>
                                <Input as="select" name='country' onChange={formik.handleChange} value={formik.values.country}>
                                    <option key="" value="">Please Select</option>
                                    {countryList.length > 0 && countryList.map((option) => <option key={option.Id} value={option.Id}>{option.Name}</option>)}
                                </Input>
                            </InputGroup>

                            <InputGroup marginY={'5px'}>
                                <InputLeftAddon width={'120px'}>Region</InputLeftAddon>
                                <Input as="select" name='region' onChange={formik.handleChange} value={formik.values.region}>
                                    <option key="" value="">Please Select</option>
                                    {regionList.length > 0 && regionList.map((option) => <option key={option.Id} value={option.Id}>{option.Name}</option>)}
                                </Input>
                            </InputGroup>





                            <Button mt={4} colorScheme='teal' type='submit'>Submit</Button>
                        </form>
                    </FormControl>

                </GridItem>
                <GridItem colSpan={2} borderLeft={"solid"} paddingLeft={'20px'}>
                    <Heading>Validation</Heading>
                    <Text marginY={'20px'}>As you complete the form helpful text will appear here to help keep you on track.</Text>
                    {formik.errors.name ? <Container backgroundColor={'pink'} marginY={'7px'} borderRadius={'5px'} className={'montserrat-free'}>{formik.errors.name}</Container> : null}
                    {formik.errors.number ? <Container backgroundColor={'pink'} marginY={'7px'} borderRadius={'5px'} className={'montserrat-free'}>{formik.errors.number}</Container> : null}
                    {formik.errors.street ? <Container backgroundColor={'pink'} marginY={'7px'} borderRadius={'5px'} className={'montserrat-free'}>{formik.errors.street}</Container> : null}
                    {formik.errors.town ? <Container backgroundColor={'pink'} marginY={'7px'} borderRadius={'5px'} className={'montserrat-free'}>{formik.errors.town}</Container> : null}
                    {formik.errors.city ? <Container backgroundColor={'pink'} marginY={'7px'} borderRadius={'5px'} className={'montserrat-free'}>{formik.errors.city}</Container> : null}
                    {formik.errors.country ? <Container backgroundColor={'pink'} marginY={'7px'} borderRadius={'5px'} className={'montserrat-free'}>{formik.errors.country}</Container> : null}
                    {formik.errors.region ? <Container backgroundColor={'pink'} marginY={'7px'} borderRadius={'5px'} className={'montserrat-free'}>{formik.errors.region}</Container> : null}
                </GridItem>
            </Grid>
        </Box>
    );
}
