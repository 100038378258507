import {
    Box,
    Button,
    Container,
    FormControl, Grid, GridItem, Heading,
    Input,
    InputGroup,
    InputLeftAddon, Text,
    useToast
} from "@chakra-ui/react";
import {getOTypes} from '../scripts/FormData'
import {postOpp} from "../scripts/FormData";
import {useState} from "react";
import {useFormik} from 'formik';
import {getIDFromCookie} from "../../../auth/TokenTools";
import {getBusinessIDFromCog} from "../../../../common/UserAPI/UserCalls";
import {guardthispage} from "../../../auth/PageTools";

export default function CreateOppForm() {

    // State Variables
    const [firstLoad, setFirstLoad] = useState(false);
    const [oppTypes, setOppTypes] = useState("");
    const [date, setDate] = useState(new Date());
    const [BizID, setBizID] = useState(0)

    const toast = useToast()

    // Form filler functions
    if(!firstLoad) {
        guardthispage("Business");
        const OTData = getOTypes();
        OTData.then((value) => {
            setOppTypes(value.data);
        })


        getIDFromCookie().then((response) => {
            getBusinessIDFromCog(response).then((response2) => {
                setBizID(response2);
                setFirstLoad(true);
            });
        });


    }





    const validate = values => {
        const errors = {};
        // name errors
        if (!values.name) { errors.name = 'Please enter the name of your event'; } else if (values.name.length < 6) { errors.name = 'Please ensure the name is over 6 characters long'; }
        // opp type errors
        if (values.type=="") { errors.type = 'Please select the opportunity type'; }
        // description errors
        if (!values.desc) { errors.desc = 'Please enter a description of your event'; } else if (values.desc.length < 30) { errors.desc = 'Please ensure the description is over 30 characters long'; }
        // date errors
        if (values.datey=="") { errors.datey = 'Please select an expiry date in the future'; }

        return errors;
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            desc: '',
            datey: '',
            type: '',
        },
        validate,
        onSubmit: values => {
            const today = new Date().toISOString();
            let fullData = {
                ID:0,
                Name:values.name.toString(),
                Date:today,
                OpportunityTypeID: Math.floor(values.type),
                BusinessID: BizID,
                Description: values.desc,
                UpdatedOn: today,
                active:true,
                expiry:new Date(values.datey.toString()).toISOString()
            }
            const dataObject = (JSON.stringify(values, null, 2));

            // Post the data object
            postOpp(JSON.stringify(fullData)).then(function (response) {
                //console.log(response);
                toast({
                    title: 'And we are off!',
                    description: "Your Opportunity has been posted",
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
            })
            .catch(function (error) {
                toast({
                    title: 'Issues detected!',
                    description: "Nope sorry - something ain't working as expected",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            });

        },
    });






    return (
        <Box padding={'20px'} textColor={'darkslategray'}>
            <Grid templateRows='repeat(1, 1fr)' templateColumns='repeat(5, 1fr)' gap={10} padding={'0px'} margin={'0px'} >
                <GridItem colSpan={3} padding={'0px'} >
                    <Heading padding={'0px'} >Create a new Opportunity</Heading>
                    <Text marginY={'20px'}>An Opportunity is your chance to look for that key technologist to fill a role in your business. Tell us the name, the type of opportunity and give people some additonal details. You may be invited to pitch this at an upcoming event.</Text>

                    <FormControl  textColor={'darkslategray'}>
                <form onSubmit={formik.handleSubmit}>
                    <InputGroup marginY={'5px'}>
                        <InputLeftAddon width={'120px'}>Name</InputLeftAddon>
                        <Input id='name' name='name' type='name' onChange={formik.handleChange} value={formik.values.name}/>
                    </InputGroup>


                    <InputGroup marginY={'5px'}>
                        <InputLeftAddon width={'120px'}>Expires</InputLeftAddon>
                        <Input type="date" name="datey"  onChange={formik.handleChange} value={formik.values.datey}/>
                    </InputGroup>


                    <InputGroup marginY={'5px'}>
                        <InputLeftAddon width={'120px'}>Looking for</InputLeftAddon>
                        <Input as="select" name='type' onChange={formik.handleChange} value={formik.values.type}>
                            <option key="" value="">Please Select</option>
                            {oppTypes.length > 0 && oppTypes.map((option) => <option key={option.id}
                                                                                     value={option.id}>{option.name}</option>)}
                        </Input>
                    </InputGroup>


                    <InputGroup marginY={'5px'}>
                        <InputLeftAddon width={'120px'}>Description</InputLeftAddon>
                        <Input as="textarea" id='desc' name='desc' placeholder='Enter a description of your opportunity here' maxLength={'400'}  onChange={formik.handleChange} value={formik.values.desc}/>
                    </InputGroup>

                    <Button mt={4} colorScheme='teal' type='submit'>Submit</Button>
                </form>
            </FormControl>
                </GridItem>
                <GridItem colSpan={2} borderLeft={"solid"} paddingLeft={'20px'}>
                    <Heading>Validation</Heading>
                    <Text marginY={'20px'}>As you complete the form helpful text will appear here to help keep you on track.</Text>
                    {formik.errors.name ? <Container backgroundColor={'pink'} marginY={'7px'} borderRadius={'5px'} className={'montserrat-free'}>{formik.errors.name}</Container> : null}
                    {formik.errors.datey ? <Container backgroundColor={'pink'} marginY={'7px'} borderRadius={'5px'} className={'montserrat-free'}>{formik.errors.datey}</Container> : null}
                    {formik.errors.type ? <Container backgroundColor={'pink'} marginY={'7px'} borderRadius={'5px'} className={'montserrat-free'}>{formik.errors.type}</Container> : null}
                    {formik.errors.desc ? <Container backgroundColor={'pink'} marginY={'7px'} borderRadius={'5px'} className={'montserrat-free'}>{formik.errors.desc}</Container> : null}
                </GridItem>
            </Grid>
        </Box>
    );
}
