import {useEffect, useState} from "react";
import {isLoggedIn, postLogo} from "../common/UserAPI/UserCalls";
import {Box, Container} from "@chakra-ui/react";


export default function ImageUploader() {

    const [firstLoadComplete, setFirstLoadComplete] = useState(false);

    if(!firstLoadComplete){
        setFirstLoadComplete(true);
    }




    const [imageData, setImageData] = useState(null); // Store byte array of image
    const MAX_SIZE = 2 * 1024 * 1024; // Example: 2MB limit

    function byteArrayToBase64(byteArray) {
        let binary = '';
        const len = byteArray.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(byteArray[i]);
        }
        return btoa(binary);
    }

    const handleImageChange = async (event) => {
        const file = event.target.files[0];

        if (!file) return;

        // Check file size
        if (file.size > MAX_SIZE) {
            alert(`File size exceeds ${MAX_SIZE / (1024 * 1024)}MB limit.`);
            return;
        }

        // Read file as a byte array
        const byteArray = await fileToByteArray(file);
        setImageData(byteArray);
        const base64String = byteArrayToBase64(byteArray);
        await postLogo(2, base64String, "host");
    };

    // Convert file to byte array
    const fileToByteArray = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const arrayBuffer = reader.result;
                const byteArray = new Uint8Array(arrayBuffer);
                resolve(byteArray);
            };
            reader.onerror = (error) => reject(error);
            reader.readAsArrayBuffer(file); // Read file as array buffer
        });
    };

    return (
        <Container maxWidth={'1200px'} minHeight={'770px'} overflow={"auto"}  bg='transparent'>
            <Box maxWidth={'1200px'} minHeight={'770px'} overflow={"auto"} marginTop={'50px'} padding={'20px'} bg='white' borderRadius={'10px'} className={'montserrat-free'} textAlign={"justify"}>
                <form>
                    <label>Upload Image:</label>
                    <input type="file" accept="image/*" onChange={handleImageChange} />
                    {imageData && <p>Image successfully uploaded as byte array!</p>}
                </form>
            </Box>
        </Container>
    );
}