import {Box, HStack} from "@chakra-ui/react";
import {FaRegUserCircle} from "react-icons/fa";
import React from "react";

const linkAddress=process.env.REACT_APP_COGNITO_REG_GEN;
// console.log(linkAddress);

export function LoginBubble(){
    return(
        <a href={linkAddress}>
            <Box borderRadius={'10'} height={'30px'} width={'170px'} paddingTop={'4px'} paddingLeft={'7px'} bgColor={'white'} float={"right"} >
                <HStack padding={'0'} spacing={'2'} color={'#183C67'}>
                    <FaRegUserCircle color={'#183C67'} size={'20'}/><p className={'montserrat-header-right'}>Register / Login</p>
                </HStack>
            </Box>
        </a>
    )}