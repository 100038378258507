import {Button} from "@chakra-ui/react";
import React, {useState} from "react";
import {postInviteStatus} from "../scripts/OppsData";

export default function InviteStatus(props) {

    const [componentLoaded, setComponentLoaded] = useState(false);
    const [responded, setResponded] = useState(false);
    const [response, setResponse] = useState(false);

    const eventId = props.eventId;
    const opportunityId = props.oppId;

    if(!componentLoaded){
        postInviteStatus(eventId, opportunityId).then(result=>{
            setResponded(result.data.responseRecieved);
            setResponse(result.data.response);
        })
        setComponentLoaded(true);
    }

    if(response){
        return ( <Button variant='solid' margin={'0px'} marginTop={'0px'} colorScheme='green' size={'sm'}>Accepted</Button> );
    }
    else if(responded && !response){
        return ( <Button variant='solid' margin={'0px'} marginTop={'0px'} colorScheme='red' size={'sm'}>Declined</Button> );
    }
    else{
        return ( <Button variant='solid' margin={'0px'} marginTop={'0px'} colorScheme='orange' size={'sm'}>Invited</Button> );
    }

}
