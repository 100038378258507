import {Box, Button, Container, StackDivider, VStack} from "@chakra-ui/react";
import FFLogo from "../../../assets/Foundry Fuel_multi.jpg";
import React from "react";

export default function Thanks() {

    return (
        <Container width={'350px'} marginY={'30px'} padding={'15px'} bg='white' borderColor={'lightgray'}
                   borderRadius='lg' textAlign={"center"}>
            <Box width={'320px'} margin={'0px'} padding={'0px'} marginTop={'20px'} borderRadius='lg'>
                <VStack divider={<StackDivider borderColor='gray.200'/>} spacing={4} align='stretch'>
                    <Box h='140px' alignContent={'center'}>
                        <center><img src={FFLogo} alt="Foundry Fuel Logo" width={'250px'}/></center>
                    </Box>
                    <Box>Thanks for Voting!</Box>
                    <Box>If the business you have submitted your details to is interested they will be in touch soon.</Box>

                </VStack>
            </Box>
        </Container>
    );

}