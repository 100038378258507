import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardFooter,
    Divider,
    Heading,
    Image,
    Link,
    Stack,
    Text
} from "@chakra-ui/react";
import Code from "../../../assets/photos/technologists.jpg";

export function ChooseTech(){

    return(
        <Card width='320px' height={'560px'} variant={'elevated'} className={'montserrat-free'}>
            <CardBody>
                <Image src={Code} alt='Code image' borderRadius='lg' />
                <Stack mt='6' spacing='3'>
                    <Heading size='md'>Choose Technologist</Heading>
                    <Text textAlign={"justify"}>Technologists are technical people with specific skills that are essential to technology businesses. As a Technologist you will be able to create a profile, apply for job postings, review job posters and attend events. All for free.</Text>
                </Stack>
            </CardBody>
            <Divider />
            <CardFooter>
                <ButtonGroup spacing='2'>
                    <Link href='/register/technologist'>
                        <Button variant='solid' colorScheme='blue'>
                            Register as a Technologist
                        </Button>
                    </Link>
                </ButtonGroup>
            </CardFooter>
        </Card>
    )
}