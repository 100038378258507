import axios from "axios";
import {getAccessTokenFromCookie} from "../../../auth/TokenTools";

export async function getOTypes() {

    var AccessToken = getAccessTokenFromCookie();
    const URL = process.env.REACT_APP_OPP_API + "opptypes";

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })
    return (await client.get(URL));

}

export async function postOpp(dataObject){

    var AccessToken = getAccessTokenFromCookie();
    const URL = process.env.REACT_APP_OPP_API + "opp";

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/json', Authorization : `Bearer ${AccessToken}`}
    })

    return (await client.post(URL, dataObject));

}

export async function updateOpp(dataObject){

    var AccessToken = getAccessTokenFromCookie();
    const URL = process.env.REACT_APP_OPP_API + "opp";

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/json', Authorization : `Bearer ${AccessToken}`}
    })

    return (await client.put(URL, dataObject));

}
