import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardFooter,
    Divider, GridItem,
    Heading,
    Image,
    Link, SimpleGrid,
    Stack,
    Text
} from "@chakra-ui/react";
import Curate from '../assets/photos/curatedEvents.jpg';
import onehundred from "../assets/ICONS/events.png";


export function HomeCurated(){

    return(
        <Card maxW='100%' variant={'elevated'} className={'montserrat-free'}>
            <CardBody padding='20px' paddingTop={'0px'}>
                {/*<Image src={Curate} alt='Code image' borderRadius='lg' />*/}
                <Stack mt='6' spacing='3'>
                    <SimpleGrid columns={8} spacing={0}>
                        <GridItem colSpan={1}>
                            <img src={onehundred} width={'50px'}/>
                        </GridItem>
                        <GridItem colSpan={7} padding={'12px'}>
                            <Heading size='md' textAlign={'left'}>Curated Events</Heading>
                        </GridItem>
                    </SimpleGrid>
                    <Text textAlign={"justify"}>
                        Curated events that focus on real opportunities? Where you can come along, hear pitches and vote on what interests you? Where you vote on your phone without a hard sell? Sounds refreshing right?
                    </Text>
                </Stack>
            </CardBody>
            {/*<Divider />
            <CardFooter>
                <ButtonGroup spacing='2'>
                    <Link href={process.env.REACT_APP_REG_LINK}>
                        <Button variant='solid' colorScheme='pink'>
                            Register
                        </Button>
                    </Link>
                    <Button variant='ghost' colorScheme='blue'>
                        Find out more
                    </Button>
                </ButtonGroup>
            </CardFooter>*/}
        </Card>
    )
}