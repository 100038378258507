import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardFooter,
    Divider,
    Heading,
    Image,
    Link,
    Stack,
    Text
} from "@chakra-ui/react";
import Code from "../../../assets/photos/founder.jpg";

export function ChooseBiz(){

    return(
        <Card width='320px' height={'560px'} variant={'elevated'} className={'montserrat-free'}>
            <CardBody>
                <Image src={Code} alt='Code image' borderRadius='lg' />
                <Stack mt='6' spacing='3'>
                    <Heading size='md'>Choose Founder/Business</Heading>
                    <Text textAlign={"justify"}>
                        As a business/founder you will be able to post job opportunities to our accountable job board and create opportunities to present at curated events. Please note that some features, depending on business status, are premium and paid for.
                    </Text>
                </Stack>
            </CardBody>
            <Divider />
            <CardFooter>
                <ButtonGroup spacing='2'>
                    <Link href='/register/company'>
                        <Button variant='solid' colorScheme='red'>
                            Register as a Founder/Business
                        </Button>
                    </Link>
                </ButtonGroup>
            </CardFooter>
        </Card>
    )
}