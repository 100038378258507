import axios from "axios";
import {getAccessTokenFromCookie} from '../../routes/auth/TokenTools'


export async function postNewBusiness(dataObject){

    var AccessToken = getAccessTokenFromCookie();
    const URL = process.env.REACT_APP_API_USER + "/business/reg"

    const client = axios.create({
        baseURL:  URL,
        headers: {'content-type': 'application/json', Authorization : `Bearer ${AccessToken}`}
    })

    return (await client.post(URL, dataObject));

}
