import {useRouteError} from "react-router-dom";
import FFLogo from "./assets/Foundry Fuel_blue.jpg";
import Unlink from "./assets/ICONS/unlink.png";
import {Box, Center, Circle, Divider, Image, Square, Text} from "@chakra-ui/react";
import * as React from "react";
import { useNavigate } from "react-router-dom";

export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);

    return (
        <Box bg='white' w='468px' minHeight='468px' margin="auto" overflowY="auto" alignItems={"center"} borderRadius='lg' textAlign={"center"} marginTop={100} p={2} color='#183c67'>
            <a href="/" title="Link to Foundry Fuel Homepage"><img src={FFLogo} alt="Foundry Fuel Logo"/></a>
            <Divider paddingTop={"20px"} />
            <Text fontSize='36px' paddingTop={"20px"}>Hey! What happened?</Text>

            <Center paddingTop={"30px"} >
                    <Image src={Unlink} alt="Error logo" width={"200px"}/>
            </Center>

            <Text fontSize='16px' paddingY={"30px"}>Sorry but it looks like an error has occurred and we weren't anticipating it. Not to worry! This has been reported to the admin team for investigation automatically and the Foundry Bots will be working away to get this fixed ASAP.</Text>

            <Text fontSize='8px'>{error.data}</Text>


        </Box>
    );
}