import {Box, Button, Card, CardBody, CardFooter, CardHeader, Container, Grid, GridItem, Heading, SimpleGrid, Stack, StackDivider, Text, VStack, Wrap, WrapItem} from "@chakra-ui/react";
import React, {useState} from "react";
import {getIDFromCookie} from "../../auth/TokenTools";
import {getTechnologistIDFromCog} from "../../../common/UserAPI/UserCalls";
import {getVoteHistory} from "./scripts/TechScripts";
import {getOpportunitesForCompany} from "../opps/scripts/OppsData";
import {guardthispage} from "../../auth/PageTools";

export default function TechHistory() {

    const [oppsLoaded, setOppsLoaded] = useState(false);
    const [punchback, setPunchback] = useState(false);
    const [inviteList, setInviteList] = useState([])
    const [oppList, setOppList] = useState([]);
    const [oppSelected, setOppSelected] = useState(0);
    const [selectedOppName, setSelectedOppName] = useState("");
    const [techID, setTechID] = useState(0);

    if(!oppsLoaded){
        guardthispage("Technologist");
        getIDFromCookie().then((response) => {
            getTechnologistIDFromCog(response).then((response2) => {
                setTechID(response2);
                const oppsListRaw = getVoteHistory(response2);
                oppsListRaw.then(result=>{
                    setOppList(result.data);
                    //console.log(result.data);
                })
                setOppsLoaded(true);
            });
        });

    }

    return (
        <Box padding={'20px'} textColor={'darkslategray'} height={'450px'}>
            <Grid templateRows='repeat(1, 1fr)' templateColumns='repeat(5, 1fr)' gap={2}>
                <GridItem colSpan={5} >
                    <Heading>Your Voting History</Heading>
                    <Text marginY={'20px'}>Below is a list of the opportunities you have historically voted on. The more events you attend the larger your history will be.</Text>
                    <Box>
                        <VStack divider={<StackDivider borderColor='gray.200' />} spacing={4} align='stretch'  >
                            <Heading size='md'>Historical votes</Heading>
                            <Wrap>
                                {oppList.map(
                                    ((opp, index)=>(
                                            <WrapItem>
                                                <Card width={'207px'} height={'200px'} key={opp.oppID} background={'whitesmoke'}>
                                                    <CardHeader borderBottomColor={'slategray'} borderBottomWidth={'1px'}><Heading size='md' margin={'0px'}>{opp.oppName}</Heading></CardHeader>
                                                    <CardBody background={'white'} borderBottomColor={'slategray'} borderBottomWidth={'1px'}>
                                                        <Text><strong>{opp.companyName}</strong> </Text>
                                                        <Text marginTop={'2px'}><strong>@</strong>{opp.eventName}</Text>
                                                        <Text marginTop={'2px'}>{opp.eventDate}</Text>
                                                    </CardBody>
                                                </Card>
                                            </WrapItem>
                                        )
                                    ))}
                            </Wrap>
                        </VStack>
                    </Box>
                </GridItem>
            </Grid>
        </Box>
    );
}
