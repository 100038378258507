import CreateEventForm from "../events/forms/CreateEventForm";
import {Heading} from "@chakra-ui/react";
import {guardthispage} from "../../auth/PageTools";

export default function CreateE() {
    return (
        <div>
            <CreateEventForm/>
        </div>
    );
}
