import {
    Box,
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardFooter,
    Divider, GridItem,
    Heading,
    Image,
    Link,
    SimpleGrid,
    Stack,
    Text
} from "@chakra-ui/react";
import onehundred from '../assets/ICONS/free.png';


export function HomeFree(){

    return(
        <Card maxW='100%' variant={'elevated'} className={'montserrat-free'}>
            <CardBody padding='20px' paddingTop={'0px'}>
                {/*<Image src={Accountable} alt='Code image' borderRadius='lg' />*/}
                <Stack mt='6' spacing='3'>
                    <SimpleGrid columns={8} spacing={0}>
                        <GridItem colSpan={1}>
                            <img src={onehundred} width={'50px'}/>
                        </GridItem>
                        <GridItem colSpan={7} padding={'12px'}>
                            <Heading size='md' textAlign={'left'}>Free Forever</Heading>
                        </GridItem>
                    </SimpleGrid>

                    <Text textAlign={"justify"}>
The Foundry Fuel platform is designed to help technologists so will be free for their use, forever. What's more - where we can we will make it free for founders, accelerators and other community groups.</Text>
                </Stack>
            </CardBody>
           {/* <Divider />
            <CardFooter>
                <ButtonGroup spacing='2'>
                    <Link href={process.env.REACT_APP_REG_LINK}>
                        <Button variant='solid' colorScheme='pink'>
                            Register / Login
                        </Button>
                    </Link>
                </ButtonGroup>
            </CardFooter>*/}
        </Card>
    )
}