import {getAccessTokenFromCookie} from "../../routes/auth/TokenTools";
import axios from "axios";

export async function postPreReg(dataObject){

    var AccessToken = getAccessTokenFromCookie();
    const URL = process.env.REACT_APP_PREREG_API + "/prereg";

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/json', Authorization : `Bearer ${AccessToken}`}
    })

    return (await client.post(URL, dataObject));

}
