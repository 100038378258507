import axios from "axios";
import {getAccessTokenFromCookie} from '../../routes/auth/TokenTools'

export async function getAOB(){

    const URL = process.env.REACT_APP_API_USER + "/utilities/areaofbusiness"
    var AccessToken = getAccessTokenFromCookie();

    const client = axios.create({
        baseURL:URL,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })

    return (await client.get(URL));

}

export async function getBusinessSize(){

    const URL = process.env.REACT_APP_API_USER + "/utilities/businesssize"
    var AccessToken = getAccessTokenFromCookie();

    const client = axios.create({
        baseURL:URL,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })

    return (await client.get(URL));

}

export async function getHostType(){

    const URL = process.env.REACT_APP_API_USER + "/utilities/hosttype"
    var AccessToken = getAccessTokenFromCookie();

    const client = axios.create({
        baseURL:URL,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })

    return (await client.get(URL));

}

export async function getRegions(){

    const URL = process.env.REACT_APP_API_USER + "/utilities/regions"
    var AccessToken = getAccessTokenFromCookie();

    const client = axios.create({
        baseURL:URL,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })

    return (await client.get(URL));

}

export async function getExperiences(){

    const URL = process.env.REACT_APP_API_USER + "/utilities/experiences"
    var AccessToken = getAccessTokenFromCookie();

    const client = axios.create({
        baseURL:URL,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })

    return (await client.get(URL));

}

export async function getSpecialisms(){

    const URL = process.env.REACT_APP_API_USER + "/utilities/specialisms"
    var AccessToken = getAccessTokenFromCookie();

    const client = axios.create({
        baseURL:URL,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })
    return (await client.get(URL));

}