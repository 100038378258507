import * as React from 'react'
import {Box, Container, GridItem, SimpleGrid, VStack} from '@chakra-ui/react'
import {Outlet} from "react-router-dom";
import {Banner} from "../common/Banner";
import {Menubar} from "../common/Menubar";
import {Footer} from "../common/Footer";

export default function Register() {

    return (



        <VStack spacing={0} align='stretch' className={'bluey'}>
            <Menubar/>
            <Box height={'20px'} bg='transparent'>
                <Container maxWidth={'1200px'} bg='transparent' centerContent></Container>
            </Box>
            <Box minHeight={'420px'} overflow={"visible"} bg='transparent'>
                <Outlet />
            </Box>
            <Footer/>
        </VStack>


    );
}