import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardFooter,
    Divider, GridItem,
    Heading,
    Image,
    Link, SimpleGrid,
    Stack,
    Text
} from "@chakra-ui/react";
import Code from '../assets/photos/technologists.jpg';
import onehundred from "../assets/ICONS/onehundred.png";


export function HomeTech(){

    return(
        <Card maxW='100%' variant={'elevated'} className={'montserrat-free'}>
            <CardBody padding='20px' paddingTop={'0px'}>
                {/*<Image src={Code} alt='Code image' borderRadius='lg' height={'50px'}/>*/}
                <Stack mt='6' spacing='1'>
                    <SimpleGrid columns={8} spacing={0}>
                        <GridItem colSpan={1}>
                            <img src={onehundred} width={'50px'}/>
                        </GridItem>
                        <GridItem colSpan={7} padding={'12px'}>
                            <Heading size='md' textAlign={'left'}>100% Technologist Friendly</Heading>
                        </GridItem>
                    </SimpleGrid>
                    <Text textAlign={"justify"}>
                        We promise that you control your data and we will never share anything unless you explicitly ask us to. Engage at a level that makes you comfortable. Check out some expert advice and take control of your career all for free.</Text>
                </Stack>
            </CardBody>
            {/*<Divider />
            <CardFooter>
                <ButtonGroup spacing='2'>
                    <Link href={process.env.REACT_APP_REG_LINK}>
                        <Button variant='solid' colorScheme='pink'>
                            Register / Login
                        </Button>
                    </Link>
                </ButtonGroup>
            </CardFooter>*/}
        </Card>
    )
}