import {GridItem} from "@chakra-ui/react";
import React from "react";

export default function EventAvailability(props) {

    var eventCap = props.cap;
    var eventLevel = props.level;

    var percentage = (eventLevel / eventCap) * 100;
    var worked = "";
    var color = "#FFFFFF";


    switch (true) {
        case (percentage<80):
            worked = "Good availability";
            color = "#006600";
            break;
        case (percentage>80 && percentage<=90):
            worked = "Getting busy";
            color = "#FF5C00";
            break;
        case (percentage>90 && percentage<=100):
            worked = "Last few spaces";
            color = "#8A3324";
            break;
        case (percentage>100):
            worked = "Full";
            color = "#8A3324";
            break;
    }


    return (
        <GridItem w='100%' colSpan={4} textAlign={'right'} textColor={color}>{worked}</GridItem>
    );
}
