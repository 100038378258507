import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem, AccordionPanel,
    Box, Button,
    Grid,
    GridItem,
    Heading, Image,
    Text
} from "@chakra-ui/react";
import address from "../../assets/host/address.jpg";
import redseats from "../../assets/host/redseats.jpg";
import bulbs from "../../assets/host/bulbs.jpg";
import desk from "../../assets/host/desk.jpg";
import woman from "../../assets/host/woman.jpg";
import frames from "../../assets/host/frames.jpg";
import screens from "../../assets/host/screens.jpg";
import React, {useState} from "react";
import {getBusinessApproved, getBusinessIDFromCog, getLogo} from "../../common/UserAPI/UserCalls";
import {getIDFromCookie} from "../auth/TokenTools";

export default function BusinessDash() {

    const [firstLoad, setFirstLoad] = useState(false);

    if(!firstLoad){
        getIDFromCookie().then((response) => {
            getBusinessIDFromCog(response).then((response2) => {
                getBusinessApproved(response2).then((response3) => {
                    if(!response3){
                        window.location.replace("/notyet");
                    }
                })
            });
        });
        setFirstLoad(true);
    }

    return (
        <Grid width={'100%'} templateRows='repeat(1, 1fr)' padding={'20px'} templateColumns='repeat(10, 1fr)' gap={0} textColor={'slateGray'} >


            <GridItem rowSpan={1} colSpan={3} marginRight={'10px'} paddingRight={'10px'}>

                <Heading marginBottom={'10px'}>Let's get started</Heading>
                <Text marginBottom={'20px'}>As a <strong>Business</strong> on Foundry Fuel, your power lies in creating opportunities. These opportunities represent ways that technologist can engage with (and be part) of your journey. If suitable a host will invite you to a pitching events where you can pitch to Technologists.</Text>

            </GridItem>
            <GridItem rowSpan={1} colSpan={7} bg={'ghostwhite'} height={'auto'}>
                <Accordion allowToggle width={'100%'} defaultIndex={[0]} >
                    <AccordionItem>
                        <h2>
                            <AccordionButton bg={'lightgray'} textColor={'slategray'} >
                                <Box as='span' flex='1' textAlign='left'>
                                    <strong>1 - Create an Opportunity</strong>
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <Grid width={'100%'} templateRows='repeat(1, 1fr)' padding={'2px'} templateColumns='repeat(3, 1fr)' gap={0} textColor={'slateGray'} >
                                <GridItem rowSpan={1} colSpan={1} bg={'ghostwhite'} height={'auto'} marginRight={'15px'} marginTop={'5px'}>
                                    <Image src={woman} alt='balloons image' borderRadius={'lg'} border={'solid'} marginBottom={'10px'}/>
                                </GridItem>
                                <GridItem rowSpan={1} colSpan={2} bg={'ghostwhite'} height={'auto'}>
                                    Are you looking for someone to join your business. Create an opportunity, give it a name, select the type of opportunity it is and when it expires. It will then be available for pick up by local hosts who are putting on events.<br/><br/>
                                    <Button colorScheme='blue' onClick={event =>  window.location.href='/dashboard/business/createOpp/'}>Create an Opportunity</Button>
                                </GridItem>
                            </Grid>
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <h2>
                            <AccordionButton bg={'lightgray'} textColor={'slategray'} >
                                <Box as='span' flex='1' textAlign='left'>
                                    <strong>2 - Review your invites</strong>
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <Grid width={'100%'} templateRows='repeat(1, 1fr)' padding={'2px'} templateColumns='repeat(3, 1fr)' gap={0} textColor={'slateGray'} >
                                <GridItem rowSpan={1} colSpan={1} bg={'ghostwhite'} height={'auto'} marginRight={'15px'} marginTop={'5px'}>
                                    <Image src={redseats} alt='balloons image' borderRadius={'lg'} border={'solid'} marginBottom={'10px'}/>
                                </GridItem>
                                <GridItem rowSpan={1} colSpan={2} bg={'ghostwhite'} height={'auto'}>
                                    If selected to pitch at an event, you will receive an email asking for you to accept or reject the invitation. You can check the event here, review the date, host and either accept or reject the invite.<br/><br/>
                                    <Button colorScheme='blue' onClick={event =>  window.location.href='/dashboard/business/manageInvites/'}>Manage Invites</Button>
                                </GridItem>
                            </Grid>
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <h2>
                            <AccordionButton bg={'lightgray'} textColor={'slategray'} >
                                <Box as='span' flex='1' textAlign='left'>
                                    <strong>3 - Pitch at an event</strong>
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <Grid width={'100%'} templateRows='repeat(1, 1fr)' padding={'2px'} templateColumns='repeat(3, 1fr)' gap={0} textColor={'slateGray'} >
                                <GridItem rowSpan={1} colSpan={1} bg={'ghostwhite'} height={'auto'} marginRight={'15px'} marginTop={'5px'}>
                                    <Image src={bulbs} alt='balloons image' borderRadius={'lg'} border={'solid'} marginBottom={'10px'}/>
                                </GridItem>
                                <GridItem rowSpan={1} colSpan={2} bg={'ghostwhite'} height={'auto'}>
                                    Have you been invited to pitch at an event? Check out your opportunity invites
                                    online and respond to let the host know if you are pitching or not.<br/><br/>
                                    <Button colorScheme='blue'
                                            onClick={event => window.location.href = '/dashboard/host/inviteOpps'}>Add
                                        Event Content</Button>
                                </GridItem>
                            </Grid>
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <h2>
                            <AccordionButton bg={'lightgray'} textColor={'slategray'} >
                                <Box as='span' flex='1' textAlign='left'>
                                    <strong>4 - Review any connections</strong>
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <Grid width={'100%'} templateRows='repeat(1, 1fr)' padding={'2px'} templateColumns='repeat(3, 1fr)' gap={0} textColor={'slateGray'} >
                                <GridItem rowSpan={1} colSpan={1} bg={'ghostwhite'} height={'auto'} marginRight={'15px'} marginTop={'5px'}>
                                    <Image src={desk} alt='balloons image' borderRadius={'lg'} border={'solid'} marginBottom={'10px'}/>
                                </GridItem>
                                <GridItem rowSpan={1} colSpan={2} bg={'ghostwhite'} height={'auto'}>
                                    So you have attended an event, pitched your opportunities and people have voted. Check out your connections right here in the platform and get in touch with anyone who piques your interest.<br/><br/>
                                    <Button colorScheme='blue' onClick={event =>  window.location.href='/dashboard/business/connections'}>View Connections</Button>
                                </GridItem>
                            </Grid>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </GridItem>
        </Grid>
    );
}