import {
    Box, Button, Card, CardBody,
    Divider, Grid, GridItem,
    Heading,
    Table,
    TableCaption,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr
} from "@chakra-ui/react";
import React, {useState} from "react";
import {getIDFromCookie} from "../../auth/TokenTools";
import {getHostIDFromCog} from "../../../common/UserAPI/UserCalls";
import {getHostAddresses} from "../../../common/EventAPI/EventCalls";
import EditAddressForm from "./forms/EditAddressForm";
import DeleteConfirm from "../modules/DeleteConfirm";
import cardBG from "../../../assets/cardBG.jpg";
import {tidyDateTime} from "./scripts/Helper";
import {guardthispage} from "../../auth/PageTools";

export default function AddressBook() {

    // State variables
    const [hostID, setHostID] = useState();
    const [addresses, setAddresses] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState(0);
    const [firstLoaded, setFirstLoaded] = useState(false);

    // firstLoad
    if(!firstLoaded){
        guardthispage("Host");
        getIDFromCookie().then((response) => {
            getHostIDFromCog(response).then((response2) => {
                getHostAddresses(response2).then((response3) => {
                    setAddresses(response3.data);
                });
            });
        });
        setFirstLoaded(true);
    }

    function editAddress(addressID){
        setSelectedAddress(addressID);
    }

    function deleteAddress(addressID){
        setSelectedAddress(addressID);
    }


    //Main content
    if(selectedAddress==0){

    return (

        <Box textColor={"slategray"} padding={'20px'}>

            <Heading>Address Book</Heading>
            <Text width={'75%'}>As a host your address book is where you manage the details of your venues. You will need to create venues in your address book before creating an event.</Text>
                <Divider margin={'20px'} />
                <Box marginTop={'10px'}>
                    <Heading size={'md'}>Existing Addresses</Heading>
                    <Text >Manage your existing venue addresses using the following table.</Text>

                    <TableContainer margin={'10px'} marginTop={'30px'}>
                        <Table variant='simple' borderWidth={'1px'}>
                            <TableCaption>Your current venue addresses</TableCaption>
                            <Thead>
                                <Tr>
                                    <Th>Name</Th>
                                    <Th>Number</Th>
                                    <Th>Street</Th>
                                    <Th>Town</Th>
                                    <Th>City</Th>
                                    <Th>Postcode</Th>
                                    <Th></Th>
                                    <Th></Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {addresses.map((address, index) => (
                                    <Tr>
                                        <Td>{address.name}</Td>
                                        <Td>{address.number}</Td>
                                        <Td>{address.street}</Td>
                                        <Td>{address.town}</Td>
                                        <Td>{address.city}</Td>
                                        <Td>{address.postcode}</Td>
                                        <Td><Button marginX={'0'} colorScheme={'blue'} onClick={event =>  editAddress(address.id)}>Edit</Button></Td>
                                        <Td><DeleteConfirm id={address.id}/></Td>
                                    </Tr>
                                ))}

                            </Tbody>
                        </Table>
                    </TableContainer>


                </Box>



        </Box>
    );
    }
    else{
        return(
<EditAddressForm ID={selectedAddress}/>
        )
    }
}