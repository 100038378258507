import {Banner} from "../../common/Banner";
import {Menubar} from "../../common/Menubar";
import React from "react";
import {Box, Container, VStack} from "@chakra-ui/react";

export default function Blog(){

    return(
        <VStack spacing={0} align='stretch' >
            <Banner/>
            <Menubar/>
            <Box height={'20px'} bg='transparent'>
                <Container maxWidth={'1200px'} bg='transparent' centerContent></Container>
            </Box>
            <Box height={'420px'} bg='transparent'>
                <Container maxWidth={'1100px'} bg='transparent' centerContent>
                    <Box maxWidth={'1100px'} padding={'20px'} bg='white' centerContent borderRadius={'10px'}
                         className={'montserrat-free'} textAlign={"justify"}>
                        <h1 className={'montserrat-h1'}>Blog</h1><br/>
                    </Box>
                </Container>
            </Box>
        </VStack>
    )

}