import {
    Box,
    Button,
    Card,
    CardBody, CardFooter, CardHeader,
    Container, Divider, Grid, GridItem,
    Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay,
    SimpleGrid,
    Stack,
    StackDivider, Table, TableCaption,
    TableContainer, Tbody, Td,
    Text, Tfoot, Th, Thead, Tr, useDisclosure,
    VStack, Wrap, WrapItem
} from "@chakra-ui/react";
import InviteStatus from "../opps/components/InviteStatus";
import React, {useState} from "react";
import {getIDFromCookie} from "../../auth/TokenTools";
import {getBusinessIDFromCog} from "../../../common/UserAPI/UserCalls";
import {
    getInvitesForOpportunites,
    getOpportunitesForCompany,
    getVotesForOpportunites,
    postInviteResponse
} from "../opps/scripts/OppsData";
import {guardthispage} from "../../auth/PageTools";

export default function Business() {

    const [oppsLoaded, setOppsLoaded] = useState(false);
    const [punchback, setPunchback] = useState(false);
    const [voteList, setVoteList] = useState([])
    const [oppList, setOppList] = useState([]);
    const [oppSelected, setOppSelected] = useState(0);
    const [bizID, setBizID] = useState()

    if(!oppsLoaded){
        guardthispage("Business");
        getIDFromCookie().then((response) => {
            getBusinessIDFromCog(response).then((response2) => {
                setBizID(response2);
                const oppsListRaw = getOpportunitesForCompany(response2);
                oppsListRaw.then(result=>{
                    setOppList(result.data);
                })
                setOppsLoaded(true);
            });
        });



    }

    if(punchback){
        const inviteListRaw = getVotesForOpportunites(oppSelected);
        inviteListRaw.then(result=>{
            setVoteList(result.data);
        })
        setPunchback(false);
    }

    function punchOpp(oppId){
        setVoteList([]);
        setOppSelected(oppId);
        const inviteListRaw = getVotesForOpportunites(oppId);
        inviteListRaw.then(result=>{
            setVoteList(result.data);
        })
    }

    // Modal stuff
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedItem, setSelectedItem] = useState(null);

    const handleOpen = (item) => {
        setSelectedItem(item);
        onOpen();
    };


    return (
        <Box width={'1150px'} padding={'20px'} margin={'0px'} textColor={'darkslategray'}>

                <Heading>Review your connections</Heading>
                <Text marginY={'20px'}>An Opportunity is your chance to look for that key technologist to fill a role in your business. Tell us the name, the type of opportunity and give people some additional details. You may be invited to pitch this at an upcoming event.</Text>


                <Grid width={'1070px'} templateColumns='repeat(5, 1fr)'>
                    <GridItem colSpan={5} bg='white' marginRight={'20px'}>
                        <Box>
                            <VStack divider={<StackDivider borderColor='gray.200' />} spacing={4} align='stretch'  >

                                <Wrap>
                                    {oppList.map(
                                        ((opp, index)=>(
                                                <WrapItem>
                                                    <Card width={'207px'} height={'270px'} key={opp.id} background={'whitesmoke'}>
                                                        <CardHeader borderBottomColor={'slategray'} borderBottomWidth={'1px'}><Heading size='md' margin={'0px'}>{opp.name}</Heading></CardHeader>
                                                        <CardBody background={'white'} borderBottomColor={'slategray'} borderBottomWidth={'1px'}>
                                                            <Text><strong>Type</strong> : {opp.opportunityTypeName}</Text>
                                                            <Text marginTop={'2px'} fontSize={'small'} height={'40px'} overflow={'hidden'}>{opp.description}</Text>
                                                        </CardBody>
                                                        <CardFooter margin={'0px'}>
                                                            <Button variant='solid' margin={'15px'} marginTop={'0px'} colorScheme='blue' onClick={() => {punchOpp(opp.id)}}>Connections</Button>
                                                        </CardFooter>
                                                    </Card>
                                                </WrapItem>
                                            )
                                        ))}
                                </Wrap>

                            </VStack>
                        </Box>
                    </GridItem>
                    <GridItem colSpan={5} bg='white' marginTop={'20px'}>
                        <Divider borderColor='gray' borderWidth={'2px'} />
                    </GridItem>
                    <GridItem colSpan={5} bg='white' marginTop={'20px'}>
                        <TableContainer>
                            <Table variant='simple' fontSize='small'>
                                <TableCaption>Technologists who expressed an interest in this Opportunity</TableCaption>
                                <Thead>
                                    <Tr>
                                        <Th>Name</Th>
                                        <Th>Email</Th>
                                        <Th>Experience</Th>
                                        <Th>&nbsp;</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {voteList.map(
                                        ((vote, index)=>(
                                                <Tr>
                                                    <Td>{vote.name}</Td>
                                                    <Td>{vote.email}</Td>
                                                    <Td>{vote.experience}</Td>
                                                    <Td><Button size={'sm'} key={vote.email} onClick={() => handleOpen(vote)}>More Info</Button></Td>
                                                </Tr>
                                            )
                                        ))}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </GridItem>
                </Grid>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{selectedItem?.name}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Container>
                        <ul>
                            <li>Email : {selectedItem?.email}</li>
                            <li>Current Role : {selectedItem?.currentRole}</li>
                            <li>Experience Level : {selectedItem?.experience}</li>
                        </ul>
                        <hr/>
                        <ul>
                            <li>Engaged @ : {selectedItem?.where}</li>
                            <li>Date : {selectedItem?.when}</li>
                        </ul>
                        <hr/>
                        <ul>
                            <li>Website : {selectedItem?.website}</li>
                            <li>LinkedIn : {selectedItem?.linkedin}</li>
                            <li>GitHub : {selectedItem?.github}</li>
                        </ul>
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

        </Box>


    );


}
