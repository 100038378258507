import {Outlet, useNavigate} from "react-router-dom";
import {Box, Button, Container} from "@chakra-ui/react";

export default function Event() {

    const navigate = useNavigate();

    return (
        <Container minWidth={'1000px'} marginTop={'30px'}>
            <Container centerContent={true} paddingY={'10px'}>Events</Container>
            <Box borderWidth='1px' borderRadius='lg' padding={'10px'}>
                <Outlet />
            </Box>
            <Container centerContent={true} paddingY={'10px'}>
                <Button colorScheme='green' onClick={() => navigate("/")}>HOME</Button>
            </Container>
        </Container>
    );
}
