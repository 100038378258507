import { useForm } from "react-hook-form"
import {
    Box,
    Button,
    Container,
    FormErrorMessage,
    FormControl,
    Grid,
    GridItem,
    Input,
    InputGroup,
    InputLeftAddon,
    Progress,
    Select,
    VStack,
    Link, Checkbox,
} from '@chakra-ui/react'
import {getIDFromCookie, getEmailFromCookie, getUserFromCookie} from '../routes/auth/TokenTools'
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {getExperiences, getRegions, getSpecialisms} from "./datasources/CommonObjects";
import {postTechno} from "./datasources/TechnologyObjects";


function appendTech(data, coreID, email){
    const myObject = {UserEmail : email, name : data.fullName, region : data.region, experience : data.yearsExperience, role: data.currentRole, special1 : data.special1, special2 : data.special2, special3 : data.special3, UserId : coreID};
    return myObject;
}

export default function TechnoOne(){

    const navigate = useNavigate();

    // State variables for this page
    const [userID, setUserID] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [regions, setRegions] = useState(JSON.parse("{}"));
    const [experience, setExperience] = useState(JSON.parse("{}"))
    const [specials, setSpecials] = useState(JSON.parse("{}"))

    // USE EFFECT For Loading Up the dropdowns etc
    useEffect(() => {
        getIDFromCookie().then((response)=>{ setUserID(response);});
        setUserEmail(getEmailFromCookie());
        //const User = getUserFromCookie();
        const regionData = getRegions();
        const experienceData = getExperiences();
        const specialData = getSpecialisms();


        regionData.then((value) => {
            setRegions(value.data);
        })

        experienceData.then((value) => {
            setExperience(value.data);
        })

        specialData.then((value) => {
            setSpecials(value.data);
        })

    }, [])


    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm()

    const onSubmit = (data) => { const dataObject = appendTech(data, userID, userEmail);
        postTechno(dataObject).then(function (response) {
            //console.log(response);
            navigate("/dashboard/")
        })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (


        <Box bg='transparent' overflow={'auto'} paddingBottom={'20px'}>
            <Container maxWidth={'1100px'} bg='transparent' centerContent overflow={'auto'} >
                <Box maxWidth={'1100px'} padding={'20px'} bg='white' overflow={'auto'} centerContent borderRadius={'10px'}>

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <Container>
                            Registering with Foundry Fuel gives you free access to our product suite that is designed to help you find the best technology role for you. Complete the form below so we know what your skills are and where you are based.</Container>
                        <FormControl isInvalid={errors}>

                            <FormErrorMessage>
                                <VStack spacing='2' width={'100%'} marginTop={'2px'} marginBottom={'20px'} align='stretch'>
                                    {errors.fullName && <Box bg={"indianred"} width='100%' p={4} color='white'>Your full name is required and must be 5 characters long.</Box>}
                                    {errors.region && <Box bg={"indianred"} width='100%' p={4} color='white'>Your region is required.</Box>}
                                    {errors.yearsExperience && <Box bg={"indianred"} width='100%' p={4} color='white'>Your current role is required and must be 3 characters long</Box>}
                                    {errors.currentRole && <Box bg={"indianred"} width='100%' p={4} color='white'>Your current role is required and must be 3 characters long</Box>}
                                    {errors.special1 && <Box bg={"indianred"} width='100%' p={4} color='white'>Your first specialism is required.</Box>}
                                    {errors.special2 && <Box bg={"indianred"} width='100%' p={4} color='white'>Your second specialism is required.</Box>}
                                    {errors.special3 && <Box bg={"indianred"} width='100%' p={4} color='white'>Your third specialism is required.</Box>}
                                    {errors.privacy && <Box bg={"indianred"} width='100%' p={4} color='white'>Please ensure you read and accept the Privacy Policy.</Box>}
                                    {errors.terms && <Box bg={"indianred"} width='100%' p={4} color='white'>Please ensure you read and accept the Terms and Conditions.</Box>}
                                </VStack>
                            </FormErrorMessage>

                            <InputGroup size='md' padding={'10px'} paddingBottom='5' paddingTop='0' >
                                <InputLeftAddon borderColor={"darkblue"} minW='130'>Full Name</InputLeftAddon>
                                <Input borderColor={"darkblue"} id='fullName' {...register("fullName", {required: true, minLength: 5})}/>
                            </InputGroup>
                            <InputGroup size='md'  padding={'10px'} paddingBottom='5' paddingTop='0' >
                                <InputLeftAddon borderColor={"darkblue"} minW='130'>Region</InputLeftAddon>
                                <Select borderColor={"darkblue"} id='region' {...register("region", {required: true})}>
                                    <option value=''>** Please Select **</option>
                                    {regions.length>0 && regions.map((option) => <option key={option.Id} value={option.Id}>{option.Name}</option>)}
                                </Select>
                            </InputGroup>
                            <InputGroup borderColor={"darkblue"} size='md'  padding={'10px'} paddingBottom='5' paddingTop='0' >
                                <InputLeftAddon minW='130'>Experience</InputLeftAddon>
                                <Select borderColor={"darkblue"} id='yearsExperience' {...register("yearsExperience", {required: true})}>
                                    <option value=''>** Please Select **</option>
                                    {experience.length > 0 && experience.map((option) => <option key={option.Id} value={option.Id}>{option.Display}</option>)}
                                </Select>
                            </InputGroup>
                            <InputGroup size='md' padding={'10px'} paddingBottom='5' paddingTop='0' >
                                <InputLeftAddon borderColor={"darkblue"} minW='130'>Current Role</InputLeftAddon>
                                <Input borderColor={"darkblue"} id='currentRole' {...register("currentRole", {required: true, minLength: 3})} />
                            </InputGroup>

                            <InputGroup borderColor={"darkblue"} size='md'  padding={'10px'} paddingBottom='5' paddingTop='0' >
                                <InputLeftAddon minW='130'>Specialism 1</InputLeftAddon>
                                <Select borderColor={"darkblue"} id='special1' {...register("special1", {required: true})}>
                                    <option value=''>** Please Select **</option>
                                    {specials.length > 0 && specials.map((option) => <option key={option.Id} value={option.Id}>{option.Name}</option>)}
                                </Select>
                            </InputGroup>

                            <InputGroup borderColor={"darkblue"} size='md'  padding={'10px'} paddingBottom='5' paddingTop='0' >
                                <InputLeftAddon minW='130'>Specialism 2</InputLeftAddon>
                                <Select borderColor={"darkblue"} id='special2' {...register("special2", {required: true})}>
                                    <option value=''>** Please Select **</option>
                                    {specials.length > 0 && specials.map((option) => <option key={option.Id} value={option.Id}>{option.Name}</option>)}
                                </Select>
                            </InputGroup>

                            <InputGroup borderColor={"darkblue"} size='md'  padding={'10px'} paddingBottom='5' paddingTop='0' >
                                <InputLeftAddon minW='130'>Specialism 3</InputLeftAddon>
                                <Select borderColor={"darkblue"} id='special3' {...register("special3", {required: true})}>
                                    <option value=''>** Please Select **</option>
                                    {specials.length > 0 && specials.map((option) => <option key={option.Id} value={option.Id}>{option.Name}</option>)}
                                </Select>
                            </InputGroup>

                            <Container>
                                <Checkbox id='terms' type={'checkbox'} {...register("terms", {required: true})}>
                                    I accept the <Link href='/TANDC.pdf' isExternal id="tText" rel="noopener noreferrer" target="_blank">Terms & Conditions</Link>
                                </Checkbox>
                            </Container>

                            <Container>
                                <Checkbox id='privacy' type={'checkbox'} {...register("privacy", {required: true})}>
                                    I accept the <Link href='/PP.pdf' isExternal id="tText" rel="noopener noreferrer" target="_blank">Privacy Policy</Link>
                                </Checkbox>
                            </Container>

                        </FormControl>

                        <Grid templateColumns='repeat(5, 1fr)' gap={6} minHeight='50px' padding={'20px'} width='100%'>
                            <GridItem colSpan={4}  h='10' width='100%' paddingTop={'2px'}>&nbsp;</GridItem>
                            <GridItem h='10' width='100%' textAlign="right" ><Button type="submit" colorScheme='green' >Register</Button></GridItem>
                        </Grid>

                    </form>

                </Box>
            </Container>
        </Box>
    )
}