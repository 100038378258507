import {Avatar, Box, HStack, IconButton} from "@chakra-ui/react";
import {CloseIcon, SettingsIcon} from "@chakra-ui/icons";
import {useCookies} from "react-cookie";
import {useNavigate} from "react-router-dom";
import host from "../../../assets/ICONS/host.png";
import business from "../../../assets/ICONS/business.png";
import tech from "../../../assets/ICONS/tech.png";
import Cookies from 'js-cookie';


export default function Userbar(props) {


    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const navigate = useNavigate();

    function logout() {
        const allCookies = Cookies.get();
        // Loop through the cookie names and remove each one
        for (let cookieName in allCookies) {
            Cookies.remove(cookieName);
        }
        navigate('/');
    }


    return (
        <HStack spacing='10px' float={'right'} padding='10px' width={'100%'} paddingRight='15px'>
            <Box width={'92%'} marginLeft={'10px'} textAlign={'left'} color={'#222222'}><strong>Welcome to Foundry Fuel</strong> - Connecting technologists with opportunities</Box>
            <Box>
                {props.userType=="Host" && <Avatar name='Host Image' src={host} size={'sm'} />}
                {props.userType=="Technologist" && <Avatar name='Tech Image' src={tech} size={'sm'} />}
                {props.userType=="Business" && <Avatar name='Business Image' src={business} size={'sm'} />}
            </Box>
            {/*<Box><IconButton aria-label='Settings' colorScheme={'blue'} size={'sm'} icon={<SettingsIcon />} onClick={event =>  window.location.href='/dashboard/host'}/></Box>*/}
            <Box><IconButton aria-label='Logout' colorScheme={'red'} size={'sm'} icon={<CloseIcon />} onClick={event =>  logout()}/></Box>
        </HStack>
    )
}