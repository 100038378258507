import {
    Box,
    Button,
    Checkbox,
    Container,
    FormControl,
    FormErrorMessage,
    Grid,
    GridItem,
    Input,
    InputGroup,
    InputLeftAddon, Link,
    Progress,
    Select,
    VStack
} from "@chakra-ui/react";
import {useForm} from "react-hook-form";
import {getEmailFromCookie, getIDFromCookie, getUserFromCookie} from '../routes/auth/TokenTools';
import {getExperiences, getHostType, getRegions} from "./datasources/CommonObjects";
import React, {useEffect, useState} from "react";
import {postTechno} from "./datasources/TechnologyObjects";
import {postNewHost} from "./datasources/HostObjects";
import {useNavigate, useParams} from "react-router-dom";

export default function HostOne(){

    const navigate = useNavigate();


    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm()

    // State variables for this page
    const [userID, setUserID] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [hostTypes, setHostTypes] = useState(JSON.parse("{}"));
    const [regions, setRegions] = useState(JSON.parse("{}"));

    // USE EFFECT For Loading Up the dropdowns etc
    useEffect(() => {
        getIDFromCookie().then((response)=>{ setUserID(response);});
        setUserEmail(getEmailFromCookie());
        //const User = getUserFromCookie();
        const hostData = getHostType();
        const regionData = getRegions();


        hostData.then((value) => {
            setHostTypes(value.data);
        })

        regionData.then((value) => {
            setRegions(value.data);
        })



    }, [])


    function appendUser(data, userID, userEmail){
        const myObject = {name : data.bizName, regionID : data.region, HostTypeID : data.hosttype, CoreUserID : 0, CognitoID : userID, Email : userEmail};
        return myObject;
    }


    const onSubmit = (data) => { const dataObject = appendUser(data, userID, userEmail);
        //console.log(dataObject);
        postNewHost(dataObject).then(function (response) {
            //console.log(response);
            navigate("/dashboard/")
        })
            .catch(function (error) {
                console.log(error);
            });
    }


    return (

        <Box bg='transparent'  overflow={'auto'} paddingBottom={'20px'}>
            <Container maxWidth={'1100px'} bg='transparent' centerContent overflow={'auto'} >
                <Box maxWidth={'1100px'} padding={'20px'} bg='white' centerContent borderRadius={'10px'} overflow={'auto'} >

        <form onSubmit={handleSubmit(onSubmit)}>

            <Container>
                Registering as a Host on Foundry Fuel will enable you to manage your events, curate who pitches at them and how they are run. This is the first step in connecting great technologists with great opportunities.</Container>

            <FormControl isInvalid={errors}>

                <FormErrorMessage>
                    <VStack spacing='2' width={'100%'} marginTop={'2px'} marginBottom={'20px'} align='stretch'>
                        {errors.bizName && <Box bg={"indianred"} width='100%' p={4} color='white'>Your business name must be at least 5 characters long.</Box>}
                        {errors.hosttype && <Box bg={"indianred"} width='100%' p={4} color='white'>Your type of business is required.</Box>}
                        {errors.region && <Box bg={"indianred"} width='100%' p={4} color='white'>Your region is required.</Box>}
                        {errors.privacy && <Box bg={"indianred"} width='100%' p={4} color='white'>Please ensure you read and accept the Privacy Policy.</Box>}
                        {errors.terms && <Box bg={"indianred"} width='100%' p={4} color='white'>Please ensure you read and accept the Terms and Conditions.</Box>}
                    </VStack>
                </FormErrorMessage>

                <InputGroup size='md' padding={'10px'} paddingBottom='5' paddingTop='0' >
                    <InputLeftAddon borderColor={"darkblue"} minW='130'>Business Name</InputLeftAddon>
                    <Input borderColor={"darkblue"} id='bizName' {...register("bizName", {required: true, minLength: 5})}/>
                </InputGroup>

                <InputGroup size='md'  padding={'10px'} paddingBottom='5' paddingTop='0' >
                    <InputLeftAddon borderColor={"darkblue"} minW='130'>Type of Business</InputLeftAddon>
                    <Select borderColor={"darkblue"} id='hosttype' {...register("hosttype", {required: true})}>
                        <option value=''>** Please Select **</option>
                        {hostTypes.length>0 && hostTypes.map((option) => <option key={option.Id} value={option.Id}>{option.Name}</option>)}
                    </Select>
                </InputGroup>
                <InputGroup size='md'  padding={'10px'} paddingBottom='5' paddingTop='0' >
                    <InputLeftAddon borderColor={"darkblue"} minW='130'>Region</InputLeftAddon>
                    <Select borderColor={"darkblue"} id='region' {...register("region", {required: true})}>
                        <option value=''>** Please Select **</option>
                        {regions.length>0 && regions.map((option) => <option key={option.Id} value={option.Id}>{option.Name}</option>)}
                    </Select>
                </InputGroup>

                <Container>
                    <Checkbox id='terms' type={'checkbox'} {...register("terms", {required: true})}>
                        I accept the <Link href='/TANDC.pdf' isExternal id="tText" rel="noopener noreferrer" target="_blank">Terms & Conditions</Link>
                    </Checkbox>
                </Container>

                <Container>
                    <Checkbox id='privacy' type={'checkbox'} {...register("privacy", {required: true})}>
                        I accept the <Link href='/PP.pdf' isExternal id="tText" rel="noopener noreferrer" target="_blank">Privacy Policy</Link>
                    </Checkbox>
                </Container>

            </FormControl>

            <Grid templateColumns='repeat(5, 1fr)' gap={6} minHeight='50px' padding={'20px'} width='100%'>
                <GridItem colSpan={4}  h='10' width='100%' paddingTop={'2px'}>&nbsp;</GridItem>
                <GridItem h='10' width='100%' textAlign="right" ><Button type="submit" colorScheme='green'  >Complete</Button></GridItem>
            </Grid>

        </form>

                </Box>
            </Container>
        </Box>

    )




}