import {
    Box,
    Button,
    Center,
    Checkbox,
    Container,
    HStack,
    Spinner,
    StackDivider,
    Text,
    useToast,
    VStack
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import FFLogo from "../../../assets/Foundry Fuel_multi.jpg";
import {getIDFromCookie} from "../../auth/TokenTools";
import {getCoreUserIDFromCog, getTechnologistIDFromCog, isLoggedIn} from "../../../common/UserAPI/UserCalls";
import {getEventCandidates, getEventSummary, getVoteStatus, submitvotes, coreUserCheckIn} from "../../../common/EventAPI/EventCalls";
import { useSearchParams } from 'react-router-dom'
import {FaRegUserCircle} from "react-icons/fa";
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import placeholder from "../../../assets/placeholder.png";


export default function Vote() {

    const toast = useToast();
    const navigate = useNavigate();

    function getLimit(countVonCount){

        if (countVonCount > 2) {
            return Math.floor(countVonCount * 0.75);
        } else {
            return countVonCount;
        }

    }

    const [stillLoading, setStillLoading] = useState(true);
    const [firstLoadComplete, setFirstLoadComplete] = useState(false);
    const [secondLoadComplete, setSecondLoadComplete] = useState(false);

    const [cogID, setCogID] = useState('');
    const [techID, setTechID] = useState('');
    const [hasVoted, setHasVoted] = useState();
    const [justVoted, setJustVoted] = useState(false)
    const [eventID, setEventID] = useState('');
    const [candidates, setCandidates] = useState()
    const [eventData, setEventData] = useState("");
    const [candidateCount, setCandidateCount] = useState();
    const [candidateLimit, setCandidateLimit] = useState();

    const [failedLogin, setFailedLogin] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const linkAddress=process.env.REACT_APP_COGNITO_REG_GEN;

    const [checkedItems, setCheckedItems] = useState({});


    // Handler to toggle checkbox state
    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckedItems((prevCheckedItems) => ({
            ...prevCheckedItems,
            [name]: checked,
        }));
        //console.log(checkedItems);
    };

    // Form submission handler
    const handleSubmit = (event) => {
        event.preventDefault();
        const selectedItems = Object.keys(checkedItems).filter((key) => checkedItems[key]);
        //console.log('Selected Items:', selectedItems);
        // Do something with the selected items (e.g., send them to an API)
        //console.log("CL : " + candidateLimit);
        //console.log("SI : " + selectedItems.length);
        if(selectedItems.length <= candidateLimit){
            submitvotes(eventID, techID, selectedItems).then((response3) => {
                if(response3.data){
                    Cookies.remove('eventID');
                    navigate('/dashboard/tech/thanks');
                }
            });
            }
        else{
            toast({
                title: 'Too many items selected!',
                description: "You can only select " + candidateLimit + " of the options available. Please de-select something.",
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
    };


    if(!firstLoadComplete){
        setEventID(searchParams.get('event'));
        Cookies.set('eventID', searchParams.get('event'), { expires: 1 });
        if(!isLoggedIn()){
            setFailedLogin(true);
        }
        else{

        }
        setFirstLoadComplete(true);
    }

    if(firstLoadComplete && !secondLoadComplete && !failedLogin){
        // Do the user housekeeping - log in and check in
        getIDFromCookie().then((response) => {
            setCogID(response);


            getTechnologistIDFromCog(response).then((response2) => {
                setTechID(response2);
                // Have they already voted - eventvotestatus
                const eventVoteStatus = {};
                eventVoteStatus.eventCode=eventID;
                eventVoteStatus.userId=response2;
                getVoteStatus(eventVoteStatus).then((response3) => {
                    // If they haven't already voted then............
                    if(!response3.data){
                        // Get event details - getEventSummary
                        getEventSummary(eventID).then((result)=>{
                            setEventData(result.data);

                            getCoreUserIDFromCog(response).then((coreResponse) => {
                                 const checkin =coreUserCheckIn(coreResponse, result.data.id);
                            })

                            // Get opportunities - eventcandidates/4D2053D6-34E0-4AB5-A412-8B3F210ECDB9
                            getEventCandidates(eventID).then((result2)=>{
                                setCandidates(result2.data);
                                setCandidateCount(result2.data.length);
                                setCandidateLimit(getLimit(result2.data.length))
                                //console.log(JSON.stringify(result2.data));
                            })
                        });
                    }
                    else{
                        setHasVoted(true);
                    }
                })

            })
        });
        setSecondLoadComplete(true);
        setStillLoading(false);
    }


    if(failedLogin){
        return (
            <Container width={'350px'} marginY={'30px'} padding={'15px'} bg='white' borderColor={'lightgray'} borderRadius='lg' textAlign={"center"}>

                <Box width={'320px'} margin={'0px'} padding={'0px'} marginTop={'20px'} borderRadius='lg'>
                    <VStack divider={<StackDivider borderColor='gray.200'/>} spacing={4} align='stretch'>
                        <Box h='140px' alignContent={'center'}>
                            <center><img src={FFLogo} alt="Foundry Fuel Logo" width={'250px'}/></center>
                        </Box>
                        <Box> Let's get you logged in </Box>
                        <Box backgroundColor={'pink'}>
                            <Center bg="white" h="30px" color="white">
                                <a href={linkAddress}>
                                    <Box borderRadius={'10'} height={'30px'} width={'170px'} paddingTop={'4px'}
                                         paddingLeft={'7px'} bgColor={'white'}>
                                        <HStack padding={'0'} spacing={'2'} color={'#183C67'}>
                                            <FaRegUserCircle color={'#183C67'} size={'20'}/><p className={'montserrat-header-right'}>Register / Login</p>
                                        </HStack>
                                    </Box>
                                </a>
                            </Center>
                        </Box>
                    </VStack>
                </Box>


            </Container>
        );
    }
    // While loading show this
    else if(!failedLogin && stillLoading){
        return (
            <Container width={'350px'} marginY={'30px'} padding={'15px'} bg='white' borderColor={'lightgray'} borderRadius='lg' textAlign={"center"}>
                <Box width={'320px'} margin={'0px'} padding={'0px'} marginTop={'20px'} borderRadius='lg'>
                    <VStack divider={<StackDivider borderColor='gray.200'/>} spacing={4} align='stretch'>
                        <Box h='140px' alignContent={'center'}>
                            <center><img src={FFLogo} alt="Foundry Fuel Logo" width={'250px'}/></center>
                        </Box>
                        <Box>
                            <Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='blue.500'
                                size='xxl'
                            />
                        </Box>
                        <Box>LOADING VOTING</Box>
                    </VStack>
                </Box>
            </Container>
        );
    }
    else{
        //LOADED NOW

        // If not a technologist then they cannot vote
        if(techID<1 && secondLoadComplete && !failedLogin){
            return (
                <Container width={'350px'} marginY={'30px'} padding={'15px'} bg='white' borderColor={'lightgray'} borderRadius='lg' textAlign={"center"}>
                    <Box width={'320px'} margin={'0px'} padding={'0px'} marginTop={'20px'} borderRadius='lg'>
                        <VStack divider={<StackDivider borderColor='gray.200'/>} spacing={4} align='stretch'>
                            <Box h='140px' alignContent={'center'}>
                                <center><img src={FFLogo} alt="Foundry Fuel Logo" width={'250px'}/></center>
                            </Box>
                            <Box> Sorry but this is for technologists only and you are logged in as another type of user. Please return to the homepage.</Box>
                        </VStack>
                    </Box>
                </Container>
            );

        }
        // If all signs are good
        else if(!hasVoted && secondLoadComplete && !failedLogin && techID>0){
            return (
                <Container width={'350px'} marginY={'30px'} padding={'15px'} bg='white' borderColor={'lightgray'} borderRadius='lg' textAlign={"center"}>

                    <Box width={'320px'} margin={'0px'} padding={'0px'} marginTop={'20px'} borderRadius='lg'>
                        <form onSubmit={handleSubmit}>

                            {eventData.locked ? (
                                    <VStack divider={<StackDivider borderColor='gray.200'/>} spacing={4} align='stretch'>
                                <Box alignContent={'center'} textAlign={'center'}>
                                    <center><img src={'data:image/jpeg;base64,' + eventData.hostImage} width={'300px'}/>
                                    </center>
                                    <p>powered by</p>
                                    <center><img src={FFLogo} alt="Foundry Fuel Logo" width={'150px'}/></center>

                                </Box>


                                <Box> The list below shows the opportunities you can vote on at this session. You can vote on a
                                    maximum of {candidateLimit} items from the {candidateCount} being presented.</Box>
                                <Box>
                                    <VStack divider={<StackDivider borderColor='gray.200'/>} align='stretch'>

                                        // List the Opportunity candidates for this event
                                        {candidates && candidates.map(item => (
                                            <Box key={item.id} borderRadius='lg' minHeight={'80px'}>
                                                <HStack spacing='0px'>
                                                    <Box w='90px' marginRight={'10px'}>
                                                    {item.businessLogo ? <img src={'data:image/jpeg;base64,' + item.businessLogo} width={'70px'}/> : <img src={placeholder} width={'70px'}/>}</Box>
                                                    <Box w='210px' textAlign={"left"}>
                                                        <Text fontSize='lg'>{item.name}</Text>
                                                        <Text fontSize='md'>{item.businessName}</Text>
                                                        <Text fontSize='xs'>{item.oType}</Text>
                                                    </Box>
                                                    <Box w='50px' paddingTop={'30px'}>
                                                        <Checkbox value={item.id} name={item.id} onChange={handleCheckboxChange} size={'lg'}></Checkbox>
                                                    </Box>
                                                </HStack>
                                            </Box>
                                        ))}

                                    </VStack>

                                </Box>
                                <Box h='50px'>
                                    {(eventData.votingOpen && !eventData.votingClosed) && <Button variant='solid' margin={'15px'} marginTop={'0px'} size={'lg'} colorScheme='green' type={"submit"}>Vote  Now!</Button>}
                                    {(eventData.votingOpen && eventData.votingClosed) && <Button variant='solid' margin={'15px'} marginTop={'0px'} size={'lg'} colorScheme='red' type={"submit"} isDisabled={true}>Voting Closed</Button>}
                                    {(!eventData.votingOpen && !eventData.votingClosed) && <Button variant='solid' margin={'15px'} marginTop={'0px'} size={'lg'} colorScheme='yellow' type={"submit"}  isDisabled={true}>Voting Not Open</Button>}
                                </Box>
                            </VStack>
                                ) : (
                                    <VStack divider={<StackDivider borderColor='gray.200'/>} spacing={4} align='stretch'>
                                        <Box> Sorry this event is not locked in yet - come back another time</Box>
                                    </VStack>
                            )
                            }
                        </form>
                    </Box>


                </Container>
            );
        }
        // If has already voted
        else if(hasVoted && !failedLogin){
            return (
                <Container width={'350px'} marginY={'30px'} padding={'15px'} bg='white' borderColor={'lightgray'} borderRadius='lg' textAlign={"center"}>
                    <Box width={'320px'} margin={'0px'} padding={'0px'} marginTop={'20px'} borderRadius='lg'>
                        <VStack divider={<StackDivider borderColor='gray.200'/>} spacing={4} align='stretch'>
                            <Box h='140px' alignContent={'center'}>
                                <center><img src={FFLogo} alt="Foundry Fuel Logo" width={'250px'}/></center>
                            </Box>
                            <Box> Hi! You have already voted on this event you cheeky monkey. Sorry, no second goes. We'll be in touch soon.</Box>
                        </VStack>
                    </Box>
                </Container>
            );

        }
        // If has just voted
        else if(justVoted && !failedLogin){
            return (
                <Container width={'350px'} marginY={'30px'} padding={'15px'} bg='white' borderColor={'lightgray'} borderRadius='lg' textAlign={"center"}>
                    <Box width={'320px'} margin={'0px'} padding={'0px'} marginTop={'20px'} borderRadius='lg'>
                        <VStack divider={<StackDivider borderColor='gray.200'/>} spacing={4} align='stretch'>
                            <Box h='140px' alignContent={'center'}>
                                <center><img src={FFLogo} alt="Foundry Fuel Logo" width={'250px'}/></center>
                            </Box>
                            <Box>Thanks for Voting!</Box>
                            <Box>If the business you have submitted your details to is interested they will be in touch soon.</Box>
                        </VStack>
                    </Box>
                </Container>
            );

        }
    }

}
