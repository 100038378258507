import axios from "axios";
import {getAccessTokenFromCookie, getIDFromCookie} from '../../routes/auth/TokenTools'


const auth_api_url = process.env.REACT_APP_API_AUTH
const user_api_url = process.env.REACT_APP_API_USER

export async function getRegStatus(userID){

    var result = "";
    const AccessToken = getAccessTokenFromCookie();
    const myUrl= user_api_url + "/users/userstatus/" + userID;

    const client = axios.create({
        baseURL: myUrl,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })
    try{

    await client.get(myUrl).then(response => {
        var obj = JSON.parse(response.data);
        result = response.data.toString();
    })
    }
    catch (ex){
        console.log("User doesnt exist");
    }

    return result;

}