import {Banner} from "../../common/Banner";
import {Menubar} from "../../common/Menubar";
import React from "react";
import {Box, Container, Divider, Heading, Text, VStack} from "@chakra-ui/react";
import {Footer} from "../../common/Footer";

export default function Manifesto(){

    const principles = [
        {
            title: "Human-Centric Design",
            description:
                "We prioritize the needs, rights, and well-being of people over profit or efficiency. Every feature, function, and interface is designed to enhance user experience, respect autonomy, and protect from harm. We want to champion accessibility and where we can we will provide tools and interfaces that can be accessed and used by all people, regardless of age, ability, or circumstance.",
        },
        {
            title: "Privacy as a Fundamental Right",
            description:
                "Personal data is exactly that: personal. We will collect only the data necessary for service delivery, never sell it, and always give users full control over their information. Data is stored securely. We will endeavour to ensure users are empowered with clear, concise options to manage their information.",
        },
        {
            title: "Transparency and Accountability",
            description:
                "Users have a right to understand how our technology works. We are committed to explaining how data is processed, decisions are made, and algorithms function in a way that is clear and accessible. We openly share our goals, limitations, and challenges. Mistakes are acknowledged with humility, and we continually improve by acting on constructive feedback.",
        },
        {
            title: "Promoting Digital Well-Being",
            description:
                "Our platform is built to enrich lives, not to trap attention or exploit addictive behaviors. We will avoid manipulative design patterns and prioritize user well-being over engagement metrics. We provide tools to help users set limits, take breaks, and maintain a healthy digital-life balance.",
        },
        {
            title: "Equity and Inclusivity",
            description:
                "We are committed to technology that serves everyone, regardless of race, gender, orientation, or background. We actively work to avoid biases in our algorithms and ensure that marginalized communities are heard and served. Diversity is a strength.",
        },
        {
            title: "Fair Economic Impact",
            description:
                "Our business model is built on fair, transparent monetization practices. We believe in fair wages, equitable partnerships, and reinvesting in the communities that sustain us. Our platform provides opportunities for creators and developers to thrive without exploiting their work. We will be a force for good in the digital economy, promoting fair compensation and shared prosperity.",
        },
        {
            title: "Freedom from Exploitation",
            description:
                "Our platform will not host or support content or services that exploit or endanger users. We stand against harassment, disinformation, hate speech, and any form of exploitation, taking active steps to protect users from harm. We believe in free speech that respects human dignity. While supporting open expression, we remain vigilant against misuse that could lead to harm or division.",
        },
    ];





    return(
        <VStack spacing={0} align='stretch' >
            <Banner/>
            <Menubar/>
            <Box height={'20px'} bg='transparent'>
                <Container maxWidth={'1200px'} bg='transparent' centerContent></Container>
            </Box>
            <Box height={'auto'} bg='transparent' paddingBottom={'40px'}>
                <Container maxWidth={'1100px'} bg='transparent' centerContent>
                    <Box maxWidth={'1100px'} padding={'20px'} bg='white' centerContent borderRadius={'10px'}
                         className={'montserrat-free'} textAlign={"justify"}>


                        <Box
                            maxW="800px"
                            mx="auto"
                            p={{ base: 4, md: 8 }}
                            border="1px solid"
                            borderColor="gray.200"
                            borderRadius="md"
                            bg="yellow.50"
                            color="gray.700"
                            boxShadow="lg"
                        >
                            <Heading as="h1" fontSize={{ base: "2xl", md: "4xl" }} textAlign="center" mb={6}>
                                Foundry Fuel Manifesto
                            </Heading>
                            <Text fontSize="lg" textAlign="center" mb={4}>
                                Our Vision: A Responsible and Inclusive Digital Future
                            </Text>
                            <Text fontSize="md" mb={6}>
                                In a world transformed by technology, we believe in building platforms that serve humanity,
                                respect individual rights, and foster collective wellbeing. Our platform exists to advance
                                ethical technology that empowers individuals, strengthens communities, and supports those with neurodiverse needs.
                                This manifesto outlines our unwavering commitment to creating technology that is safe,
                                transparent, equitable, and sustainable.
                            </Text>
                            <Divider borderColor="gray.400" />

                            <VStack align="start" spacing={6} mt={6}>
                                {principles.map((principle, index) => (
                                    <Box key={index}>
                                        <Heading as="h3" fontSize="xl" color="darkslategray" mb={2}>
                                            {principle.title}
                                        </Heading>
                                        <Text fontSize="md">{principle.description}</Text>
                                    </Box>
                                ))}
                            </VStack>

                            <Divider borderColor="gray.400" mt={6} />

                            <Text fontSize="md" mt={6}>
                                <strong>Commitment to Continuous Improvement</strong>
                            </Text>
                            <Text fontSize="md" mb={6}>
                                The work of building an ethical platform and business is never finished. We are committed to continuous
                                assessment, research, and development to improve our standards. We are not perfect but we will engage
                                openly with our users, communities, and stakeholders, welcoming their insights and holding
                                ourselves accountable to high standards of transparency and responsiveness.
                            </Text>
                            <Text fontSize="lg" fontWeight="bold" textAlign="center" mb={6}>
                                Join Us on This Mission
                            </Text>
                            <Text fontSize="md" textAlign="center">
                                We invite all stakeholders—users, partners, developers, and advocates—to join us in creating
                                a more ethical digital world. Together, we can foster technology that reflects our highest
                                ideals and aspirations.
                            </Text>
                        </Box>


                    </Box>
                </Container>
            </Box>
            <Footer/>
        </VStack>
    )

}