import {Box, Container, Grid, GridItem, ListItem, UnorderedList} from "@chakra-ui/react";
import React from "react";

export default function Privacy() {

    return (

        <Container maxWidth={'1200px'} minHeight={'770px'} overflow={"auto"}  bg='transparent'>
            <Box maxWidth={'1200px'} minHeight={'770px'} overflow={"auto"} marginTop={'50px'} padding={'20px'} bg='white' borderRadius={'10px'} className={'montserrat-free'} textAlign={"justify"}>

                <Grid h='200px' templateRows='repeat(1, 1fr)' templateColumns='repeat(6, 1fr)' gap={4}>
                    <GridItem colSpan={6} bg='white' padding={'10px'}>
                        <Box paddingBottom={'50px'}>

                        </Box>
                    </GridItem>
                </Grid>
            </Box>
        </Container>


    );
}