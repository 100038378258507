import {Box, Spinner, Text, Wrap, WrapItem} from "@chakra-ui/react";
import React, {useState} from "react";
import {getIDFromCookie} from "../../../auth/TokenTools";
import {getHostDashboardStats, getHostIDFromCog} from "../../../../common/UserAPI/UserCalls";

export default function HostDashStats(props) {

    const [firstLoadDone, setFirstLoadDone] = useState(false);
    const [dashboardStats, setDashboardStats] = useState(null);

    if(!firstLoadDone){

        getIDFromCookie().then((response) => {
            getHostIDFromCog(response).then((response2) => {
                var stats = getHostDashboardStats(response2);
                stats.then((value) => {
                    setDashboardStats(value);
                })
            });
        });
        setFirstLoadDone(true);
    }


    if(dashboardStats==null){
        return(
            <Wrap marginBottom={'20px'}>
                <WrapItem>
                    <Box textAlign={'center'} borderWidth='1px' borderRadius='lg' padding={'10px'} width={'135px'} height={'150px'}>
                        <Text fontSize={'md'}>Total Events Created</Text>
                        <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' marginTop={'10px'}/>
                    </Box>
                </WrapItem>
                <WrapItem>
                    <Box textAlign={'center'} borderWidth='1px' borderRadius='lg' padding={'10px'} width={'135px'} height={'150px'}>
                        <Text fontSize={'md'}>Events Completed</Text>
                        <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' marginTop={'10px'}/>
                    </Box>
                </WrapItem>
                <WrapItem>
                    <Box textAlign={'center'} borderWidth='1px' borderRadius='lg' padding={'10px'} width={'135px'} height={'150px'}>
                        <Text fontSize={'md'}>Businesses Invited</Text>
                        <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' marginTop={'10px'}/>
                    </Box>
                </WrapItem>
                <WrapItem>
                    <Box textAlign={'center'} borderWidth='1px' borderRadius='lg' padding={'10px'} width={'135px'} height={'150px'}>
                        <Text fontSize={'md'}>Connections Made</Text>
                        <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' marginTop={'10px'}/>
                    </Box>
                </WrapItem>
            </Wrap>
        );
    }
    else{
        return(
            <Wrap marginBottom={'20px'}>
                <WrapItem>
                    <Box textAlign={'center'} borderWidth='1px' borderRadius='lg' padding={'10px'} width={'135px'} height={'150px'}>
                        <Text fontSize={'md'}>Total Events Created</Text>
                        <Text fontSize={'6xl'} color={'darkslategray'}>{dashboardStats.eventCount}</Text>
                    </Box>
                </WrapItem>
                <WrapItem>
                    <Box textAlign={'center'} borderWidth='1px' borderRadius='lg' padding={'10px'} width={'135px'} height={'150px'}>
                        <Text fontSize={'md'}>Events Completed</Text>
                        <Text fontSize={'6xl'} color={'darkslategray'}>{dashboardStats.eventsComplete}</Text>
                    </Box>
                </WrapItem>
                <WrapItem>
                    <Box textAlign={'center'} borderWidth='1px' borderRadius='lg' padding={'10px'} width={'135px'} height={'150px'}>
                        <Text fontSize={'md'}>Businesses Invited</Text>
                        <Text fontSize={'6xl'} color={'darkslategray'}>{dashboardStats.invitedOpps}</Text>
                    </Box>
                </WrapItem>
                <WrapItem>
                    <Box textAlign={'center'} borderWidth='1px' borderRadius='lg' padding={'10px'} width={'135px'} height={'150px'}>
                        <Text fontSize={'md'}>Connections Made</Text>
                        <Text fontSize={'6xl'} color={'darkslategray'}>{dashboardStats.connectionsMade}</Text>
                    </Box>
                </WrapItem>
            </Wrap>
        );
    }



}
