import {
    Box,
    Button,
    Checkbox,
    Container,
    FormControl,
    FormErrorMessage,
    Grid,
    GridItem,
    Input,
    InputGroup,
    InputLeftAddon, Link,
    Progress,
    Select,
    VStack
} from "@chakra-ui/react";
import {useForm} from "react-hook-form";
import {getEmailFromCookie, getIDFromCookie, getUserFromCookie} from '../routes/auth/TokenTools'
import {getAOB, getBusinessSize, getHostType, getRegions} from "./datasources/CommonObjects";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {postNewBusiness} from "./datasources/BusinessObjects";


export default function BizOne(){

    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm()

    // State variables for this page
    const [userID, setUserID] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [areaOfBusiness, setAreaOfBusiness] = useState("");
    const [businessSize, setBusinessSize] = useState("");
    const [regions, setRegions] = useState(JSON.parse("{}"));


    // USE EFFECT For Loading Up the dropdowns etc
    useEffect(() => {
        getIDFromCookie().then((response)=>{ setUserID(response);});
        setUserEmail(getEmailFromCookie());
        const AOBData = getAOB();
        const SizeData = getBusinessSize();
        const regionData = getRegions();


        regionData.then((value) => {
            setRegions(value.data);
        })

        AOBData.then((value) => {
            setAreaOfBusiness(value.data);
        })

        SizeData.then((value) => {
            setBusinessSize(value.data);
        })


    }, [])

    function appendUser(data, userID, userEmail){
        const myObject = {Name : data.bizName, CognitoID : userID, Email : userEmail, TagLine:"", CorpID:"", AOBID : data.aob, BusinessSizeID:data.sizeofbiz, RegionID:data.region};
        return myObject;
    }



    const onSubmit = (data) => { const dataObject = appendUser(data, userID, userEmail);
        //console.log(dataObject);
        postNewBusiness(dataObject).then(function (response) {
            //console.log(response);
            navigate("/dashboard/")
        })
            .catch(function (error) {
                console.log(error);
            });
    }


    return (

        <Box bg='transparent'  overflow={'auto'} paddingBottom={'20px'}>
            <Container maxWidth={'1100px'} bg='transparent' centerContent overflow={'auto'} >
                <Box maxWidth={'1100px'} padding={'20px'} bg='white' centerContent borderRadius={'10px'} overflow={'auto'} >

        <form onSubmit={handleSubmit(onSubmit)}>

            <Container>
                As a registered business on Foundry Fuel you can create opportunities which get invited to pitching events. Once there, technologists can vote on your pitch and express their interest in working with you.
            </Container>

            <FormControl isInvalid={errors}>

                <FormErrorMessage>
                    <VStack spacing='2' width={'100%'} marginTop={'2px'} marginBottom={'20px'} align='stretch'>
                        {errors.bizName && <Box bg={"indianred"} width='100%' p={4} color='white'>Your business name is required and must be at least 5 characters long.</Box>}
                        {errors.region && <Box bg={"indianred"} width='100%' p={4} color='white'>Your region is required.</Box>}
                        {errors.privacy && <Box bg={"indianred"} width='100%' p={4} color='white'>Please ensure you read and accept the Privacy Policy.</Box>}
                        {errors.terms && <Box bg={"indianred"} width='100%' p={4} color='white'>Please ensure you read and accept the Terms and Conditions.</Box>}
                        {errors.aob && <Box bg={"indianred"} width='100%' p={4} color='white'>Your Area of Business (AOB) is required.</Box>}
                        {errors.sizeofbiz && <Box bg={"indianred"} width='100%' p={4} color='white'>Your business size is required.</Box>}
                    </VStack>
                </FormErrorMessage>

                <InputGroup size='md' padding={'10px'} paddingBottom='5' paddingTop='0' >
                    <InputLeftAddon borderColor={"darkblue"} minW='130'>Business name</InputLeftAddon>
                    <Input borderColor={"darkblue"} id='bizName' {...register("bizName", {required: true, minLength: 5})}/>
                </InputGroup>

                <InputGroup size='md'  padding={'10px'} paddingBottom='5' paddingTop='0' >
                    <InputLeftAddon borderColor={"darkblue"} minW='130'>Area of business</InputLeftAddon>
                    <Select borderColor={"darkblue"} id='aob' {...register("aob", {required: true})}>
                        <option value=''>** Please Select **</option>
                        {areaOfBusiness.length>0 && areaOfBusiness.map((option) => <option key={option.Id} value={option.Id}>{option.Name}</option>)}
                    </Select>
                </InputGroup>
                <InputGroup size='md'  padding={'10px'} paddingBottom='5' paddingTop='0' >
                    <InputLeftAddon borderColor={"darkblue"} minW='130'>Region</InputLeftAddon>
                    <Select borderColor={"darkblue"} id='region' {...register("region", {required: true})}>
                        <option value=''>** Please Select **</option>
                        {regions.length>0 && regions.map((option) => <option key={option.Id} value={option.Id}>{option.Name}</option>)}
                    </Select>
                </InputGroup>

                <InputGroup size='md'  padding={'10px'} paddingBottom='5' paddingTop='0' >
                    <InputLeftAddon borderColor={"darkblue"} minW='130'>Size of business</InputLeftAddon>
                    <Select borderColor={"darkblue"} id='sizeofbiz' {...register("sizeofbiz", {required: true})}>
                        <option value=''>** Please Select **</option>
                        {businessSize.length>0 && businessSize.map((option) => <option key={option.Id} value={option.Id}>{option.Name}</option>)}
                    </Select>
                </InputGroup>

                <Container>
                    <Checkbox id='terms' type={'checkbox'} {...register("terms", {required: true})}>
                        I accept the <Link href='/TANDC.pdf' isExternal id="tText" rel="noopener noreferrer" target="_blank">Terms & Conditions</Link>
                    </Checkbox>
                </Container>

                <Container>
                    <Checkbox id='privacy' type={'checkbox'} {...register("privacy", {required: true})}>
                        I accept the <Link href='/PP.pdf' isExternal id="tText" rel="noopener noreferrer" target="_blank">Privacy Policy</Link>
                    </Checkbox>
                </Container>

            </FormControl>

            <Grid templateColumns='repeat(5, 1fr)' gap={6} minHeight='50px' padding={'20px'} width='100%'>
                <GridItem colSpan={4}  h='10' width='100%' paddingTop={'2px'}>&nbsp;</GridItem>
                <GridItem h='10' width='100%' textAlign="right" ><Button type="submit" colorScheme='green'  >Complete</Button></GridItem>
            </Grid>

        </form>

                </Box>
            </Container>
        </Box>


    )



}