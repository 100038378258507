import {
    Box,
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardFooter,
    Container,
    Divider,
    Grid, GridItem,
    Heading,
    Image,
    SimpleGrid,
    Stack,
    StackDivider,
    Text,
    VStack
} from "@chakra-ui/react";
import React, {useState} from "react";
import InviteStatus from "./components/InviteStatus";
import {getEvents, getOpportunites, getOppsInvited, getUnstartedEvents, postOppInvite} from "./scripts/InviteData";
import {getIDFromCookie} from "../../auth/TokenTools";
import {getHostIDFromCog} from "../../../common/UserAPI/UserCalls";
import {getAddresses} from "./scripts/FormData";
import {tidyDateTime} from "./scripts/Helper";
import cardBG from "../../../assets/cardBG.jpg";
import {sendBusinessInviteEmail} from "../../../common/EventAPI/EventCalls";
import {guardthispage} from "../../auth/PageTools";

export default function InviteO() {

    const [eventsLoaded, setEventsLoaded] = useState(false);
    const [eventsList, setEventsList] = useState([]);
    const [oppList, setOppList] = useState([]);
    const [invitedList, setInvitedList] = useState([]);
    const [eventSelected, setEventSelected] = useState(0);
    const [hostID, setHostID] = useState(0)

    if(!eventsLoaded){
        guardthispage("Host");
        getIDFromCookie().then((response) => {
            getHostIDFromCog(response).then((response2) => {
                const eventListRaw = getUnstartedEvents(response2);
                eventListRaw.then(result=>{
                    setEventsList(result.data);
                    //console.log(result.data);
                })
                setHostID(response2);
            });
        });

        setEventsLoaded(true);
    }

    function punchEvent(eventId){
        setEventSelected(eventId);
        const oppListRaw = getOpportunites(eventId);
        oppListRaw.then(result=>{
            setOppList(result.data);
            //console.log(result.data);
        })

        const invitedListRaw =getOppsInvited(eventId);
        invitedListRaw.then(result=>{
            setInvitedList(result.data);
            //console.log(result.data);
        })
    }

    function punchInvite(oppId, email, name){
        postOppInvite(eventSelected, oppId).then(result=>{

            const success = sendBusinessInviteEmail(name, email);

            const oppListRaw = getOpportunites(eventSelected);
            oppListRaw.then(result=>{
                setOppList(result.data);
                //console.log(result.data);
            })

            const invitedListRaw =getOppsInvited(eventSelected);
            invitedListRaw.then(result=>{
                setInvitedList(result.data);
                //console.log(result.data);
            })
        })
    }


    return (
        <Box padding={'10px'} minHeight={'450px'} textColor={'darkslategray'}>
            <Heading>Invite Opportunities</Heading>

            <Text marginY={'20px'}>Once you have created an event you can invite businesses/founders to pitch their opportunities. Select the Event below and then invite the available opportunities for your area.</Text>
            <Divider></Divider>
            <Container width={'1080px'} marginTop={'20px'} maxW='container.xl' textAlign={"center"}>
                <SimpleGrid columns={3} spacing={10}>
                    <Box>
                        <VStack divider={<StackDivider borderColor='gray.200' />} spacing={4} align='stretch' >
                            <Box h='15px'><Heading size={'small'}>Your Upcoming Events</Heading></Box>
                            {eventsList.map(
                                ((event, index)=>(
                                    <Card maxW='sm' padding={'0'} bgImage={cardBG} key={event.id} variant={'elevated'}>
                                        <CardBody>
                                            <Grid templateRows='repeat(2, 1fr)' templateColumns='repeat(5, 1fr)' gap={1} >
                                                <GridItem colSpan={5} bg='white' borderRadius={'lg'}><Heading size='md' paddingTop={'10px'} color={"black"}>{event.name}</Heading></GridItem>
                                                <GridItem colSpan={4}><Text fontSize='s' paddingTop={'15px'} color={'white'}>{tidyDateTime(event.when)}</Text></GridItem>
                                                <GridItem rowSpan={1} colSpan={1}><Button marginTop={'6px'} variant='solid'colorScheme='blue' onClick={() => {punchEvent(event.id)}}>Select</Button></GridItem>
                                            </Grid>
                                        </CardBody>
                                    </Card>
                                )
                            ))}

                        </VStack>
                    </Box>
                    <Box>
                        <VStack divider={<StackDivider borderColor='gray.200' />} spacing={4} align='stretch'  >
                            <Box h='15px'><Heading size={'small'}>Available Opportunities</Heading></Box>
                            {oppList.length > 0 ? (
                                oppList.map(
                                    ((event, index)=>(
                                            <Card maxW='sm' padding={'0'} bgImage={cardBG}  key={event.id}>
                                                <CardBody>
                                                    <Grid templateRows='repeat(2, 1fr)' templateColumns='repeat(5, 1fr)' gap={1} >
                                                        <GridItem colSpan={5} bg='none' borderRadius={'lg'}><Heading size='lg' paddingTop={'2px'} color={"white"}>{event.businessName}</Heading></GridItem>
                                                        <GridItem colSpan={4}>
                                                            <Text fontSize='s' padding={'0px'} color={'white'}><strong>{event.name}</strong></Text>
                                                            <Text fontSize='s' padding={'0px'} color={'white'}><strong>Looking for : </strong>{event.typeName}</Text>
                                                        </GridItem>
                                                        <GridItem rowSpan={1} colSpan={1}><Button marginTop={'6px'} variant='solid'colorScheme='green' onClick={() => {punchInvite(event.id, event.email, event.businessName)}}>Invite</Button></GridItem>
                                                    </Grid>
                                                </CardBody>
                                            </Card>
                                        )
                                    ))
                            ) : (
                                <Card maxW='sm' padding={'0'} backgroundColor={'lightgray'} borderWidth='2px' borderRadius='lg'>
                                    <CardBody>
                                        <Container><strong>Sorry - No Opportunities Found</strong><br/>Either you have added them all or people have not created opportunities in your area yet.</Container>
                                    </CardBody>
                                </Card>
                            )}
                        </VStack>
                    </Box>
                    <Box>
                        <VStack divider={<StackDivider borderColor='gray.200' />} spacing={4} align='stretch'  >
                            <Box h='15px'><Heading size={'small'}>Invited Opportunities</Heading></Box>

                            {invitedList.length > 0 ? (
                                invitedList.map(
                                    ((event, index)=>(
                                            <Card maxW='sm' padding={'0'} bgImage={cardBG}  key={event.id}>
                                                <CardBody>
                                                    <Grid templateRows='repeat(2, 1fr)' templateColumns='repeat(5, 1fr)' gap={1} >
                                                        <GridItem colSpan={5} bg='none' borderRadius={'lg'}><Heading size='lg' paddingTop={'2px'} color={"white"}>{event.businessName}</Heading></GridItem>
                                                        <GridItem colSpan={4}>
                                                            <Text fontSize='s' padding={'0px'} color={'white'}><strong>{event.name}</strong></Text>
                                                            <Text fontSize='s' padding={'0px'} color={'white'}><strong>Looking for : </strong>{event.typeName}</Text>
                                                        </GridItem>
                                                        <GridItem rowSpan={1} colSpan={1}><InviteStatus response={event.response} responseRecieved={event.responseRecieved}/></GridItem>
                                                    </Grid>
                                                </CardBody>
                                            </Card>
                                        )
                                    ))
                            ) : (
                                <Card maxW='sm' padding={'0'} backgroundColor={'lightgray'} borderWidth='2px' borderRadius='lg'>
                                    <CardBody>
                                        <Container><strong>Sorry - No Opportunities Found</strong><br/>Add some opportunities from the list to get started.</Container>
                                    </CardBody>
                                </Card>
                            )}
                        </VStack>
                    </Box>
                </SimpleGrid>
            </Container>
        </Box>
    );
}
