import {Box, Button, Divider, Stack, VStack} from '@chakra-ui/react'
import { Icon } from '@chakra-ui/react'
import { useNavigate } from "react-router-dom";
import {MdHome, MdAddHome, MdOutlineCalendarMonth, MdPersonAdd, MdManageHistory, MdAutoAwesome} from 'react-icons/md'

export default function Host() {

    let navigate = useNavigate();

    return (
     <VStack spacing={'15px'} color={'#222222'}>
         <Divider />
         <Button leftIcon={<MdHome />} w='100%' h='30px' bg='white.100' borderRadius={'lg'} paddingY={'2px'} paddingX={'10px'} justifyContent="flex-start" onClick={event =>  window.location.href='/dashboard/host'}>Dashboard</Button>
         <Button leftIcon={<MdAutoAwesome/>} w='100%' h='30px' bg='white.100' borderRadius={'lg'} paddingY={'2px'} paddingX={'10px'} justifyContent="flex-start"  onClick={event =>  window.location.href='/dashboard/host/updatelogo/'}>Update your Logo</Button>
         <Button leftIcon={<MdAddHome />} w='100%' h='30px' bg='white.100' borderRadius={'lg'} paddingY={'2px'} paddingX={'10px'} justifyContent="flex-start" onClick={event =>  window.location.href='/dashboard/host/createaddress'}>Create New Address</Button>
         <Button leftIcon={<MdAddHome />} w='100%' h='30px' bg='white.100' borderRadius={'lg'} paddingY={'2px'} paddingX={'10px'} justifyContent="flex-start" onClick={event =>  window.location.href='/dashboard/host/addressbook'}>Your Address Book</Button>
         <Button leftIcon={<MdOutlineCalendarMonth />} w='100%' h='30px' bg='white.100' borderRadius={'lg'} paddingY={'2px'} paddingX={'10px'} justifyContent="flex-start" onClick={event =>  window.location.href='/dashboard/host/createEvent'}>Create Event</Button>
         <Button leftIcon={<MdPersonAdd />} w='100%' h='30px' bg='white.100' borderRadius={'lg'} paddingY={'2px'} paddingX={'10px'} justifyContent="flex-start" onClick={event =>  window.location.href='/dashboard/host/inviteOpps'}>Manage Event Content</Button>
         <Button leftIcon={<MdManageHistory />} w='100%' h='30px' bg='white.100' borderRadius={'lg'} paddingY={'2px'} paddingX={'10px'} justifyContent="flex-start" onClick={event =>  window.location.href='/dashboard/host/manageEvent'}>Manage Events</Button>
     </VStack>
    )
}