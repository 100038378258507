import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardFooter,
    Divider,
    Heading,
    Image,
    Link,
    Stack,
    Text
} from "@chakra-ui/react";
import Code from "../../../assets/photos/host.jpg";

export function ChooseHost(){

    return(
        <Card width='320px' height={'560px'} variant={'elevated'} className={'montserrat-free'}>
            <CardBody>
                <Image src={Code} alt='Code image' borderRadius='lg' />
                <Stack mt='6' spacing='3'>
                    <Heading size='md'>Choose Event Host</Heading>
                    <Text textAlign={"justify"}>As a Host you will be able to create and curate your own events for businesses and founders to attend. This is designed for Accelerators, Incubators, Community Groups and Enterprise businesses that conduct recruitment rounds.</Text>
                </Stack>
            </CardBody>
            <Divider />
            <CardFooter>
                <ButtonGroup spacing='2'>
                    <Link href='/register/host'>
                        <Button variant='solid' colorScheme='green'>
                            Register as an Event Host
                        </Button>
                    </Link>
                </ButtonGroup>
            </CardFooter>
        </Card>
    )
}