import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider,} from "react-router-dom";
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import './index.css';
import './foundryfuel.css';

import Accountable from "./routes/public/accountable";
import Auth from './routes/authorise';
import BusinessDash from "./routes/dashboard/businessDash";
import Blog from "./routes/public/blog";
import Choose from "./routes/registration/Choose";
import Company from "./routes/registration/company";
import CuratedEvents from "./routes/public/curatedevents";
import Dashboard from "./routes/dashboard";
import ErrorPage from "./error-page";
import FFManifesto from "./routes/ffmanifesto";
import Home from "./routes/home";
import Host from "./routes/registration/host";
import HostDash from "./routes/dashboard/hostDash";
import Licenses from "./routes/licences";
import Manifesto from "./routes/public/manifesto";
import PreRegister from "./routes/PreRegister";
import Privacy from "./routes/privacy";
import Register from "./routes/register";
import Techno from "./routes/public/techno";
import Technologist from "./routes/registration/technologist";
import TechnoDash from "./routes/dashboard/technoDash";
import Terms from "./routes/terms";
// Dashboard - EVENTS
import Event from "./routes/dashboard/events/Event";
import BusinessEvents from "./routes/dashboard/events/BusinessEvents";
import AddressBook from "./routes/dashboard/events/AddressBook";
import CreateAddress from "./routes/dashboard/events/CreateAddress";
import EditAddress from "./routes/dashboard/events/EditAddress";
import CheckIn from './routes/dashboard/events/CheckIn';
import CreateE from './routes/dashboard/events/CreateE';
import InviteO from "./routes/dashboard/events/InviteO";
import ManageE from "./routes/dashboard/events/ManageE";
// Dashboard - OPPORTUNITIES
import CreateO from "./routes/dashboard/opps/CreateO";
import ManageO from "./routes/dashboard/opps/ManageO";
import ManageOI from "./routes/dashboard/opps/ManageInvites";
// Active Event
import Vote from "./routes/dashboard/techno/Vote";
import EventQR from "./routes/event";
import Thanks from "./routes/dashboard/techno/Thanks";
// Business Pages
import UpdateHostLogo from "./routes/dashboard/host/UpdateHostLogo";
// Business Pages
import UpdateImage from "./routes/dashboard/Business/UpdateImage";
// Tech Pages
import TechProfile from "./routes/dashboard/techno/TechProfile";
import TechInvites from "./routes/dashboard/techno/TechInvites";
import TechHistory from "./routes/dashboard/techno/TechHistory";
// Connections
import BizConnections from "./routes/dashboard/connections/Business";
// Testing
import ImageUploader from "./routes/ImageUploader";
import NotYet from "./NotYet";



const theme = extendTheme({
    styles: {
        global: () => ({
            body: {
                bg: ""
            }
        })
    }
});

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/notyet",
        element: <NotYet/>
    },
    {
        path: "/manifesto",
        element: <FFManifesto/>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/register/choose/",
        element: <Choose/>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/techno",
        element: <Techno/>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/curated",
        element: <CuratedEvents/>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/accountable",
        element: <Accountable/>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/blog",
        element: <Blog/>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/event",
        element: <EventQR/>,
        errorElement: <ErrorPage />,
    },
    {
        path: "auth/",
        element: <Auth/>,
        errorElement: <ErrorPage />,
    },
    {
        path: "getready/",
        element: <PreRegister/>,
        errorElement: <ErrorPage />,
    },
    {
        path: "terms/",
        element: <Terms/>,
        errorElement: <ErrorPage />,
    },
    {
        path: "privacy/",
        element: <Privacy/>,
        errorElement: <ErrorPage />,
    },
    {
        path: "licenses/",
        element: <Licenses/>,
        errorElement: <ErrorPage />,
    },
    {
        path: "imagetest/",
        element: <ImageUploader/>,
        errorElement: <ErrorPage />,
    },
    {
        path: "dashboard/",
        element: <Dashboard/>,
        children: [
            {
                path: "tech",
                element: <TechnoDash />,
            },
            {
                path: "host",
                element: <HostDash />,
            },
            {
                path: "biz",
                element: <BusinessDash />,
            },
            {
                path: "host/updatelogo/",
                element: <UpdateHostLogo/>,
            },
            {
                path: "business/createOpp/",
                element: <CreateO/>,
            },
            {
                path: "business/manageOpp/",
                element: <ManageO/>,
            },
            {
                path: "business/manageInvites/",
                element: <ManageOI/>,
            },
            {
                path: "business/events/",
                element: <BusinessEvents/>,
            },
            {
                path: "business/connections/",
                element: <BizConnections/>,
            },
            {
                path: "business/updatelogo/",
                element: <UpdateImage/>,
            },
            {
                path: "checkIn/",
                element: <CheckIn/>,
            },
            {
                path: "host/addressbook/",
                element: <AddressBook/>,
            },
            {
                path: "host/createAddress/",
                element: <CreateAddress/>,
            },
            {
                path: "host/editAddress/",
                element: <EditAddress/>,
            },
            {
                path: "host/createEvent/",
                element: <CreateE/>,
            },
            {
                path: "host/inviteOpps/",
                element: <InviteO/>,
            },
            {
                path: "host/manageEvent/",
                element: <ManageE/>,
            },
            {
                path: "tech/profile/",
                element: <TechProfile/>,
            },
            {
                path: "tech/invites/",
                element: <TechInvites/>,
            },
            {
                path: "tech/history/",
                element: <TechHistory/>,
            }

        ],
        errorElement: <ErrorPage />,
    },
    {
        path: "dashboard/tech/vote/",
        element: <Vote/>,
        errorElement: <ErrorPage />,
    },
    {
        path: "dashboard/tech/thanks/",
        element: <Thanks/>,
        errorElement: <ErrorPage />,
    },
    {
        path: "register/",
        element: <Register />,
        children: [
            {
                path: "choosey/",
                element: <Choose />,
            },
            {
                path: "company/",
                element: <Company />,
            },
            {
                path: "host/",
                element: <Host />,
            },
            {
                path: "technologist/",
                element: <Technologist />,
            },
        ],
        errorElement: <ErrorPage />,
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

        <ChakraProvider theme={theme}>
            <RouterProvider router={router} />
        </ChakraProvider>
);
