import TechnoOne from "../../forms/TechnoOne";
import * as React from "react";

export default function Technologist() {

    return (
        <div>
            <TechnoOne></TechnoOne>
        </div>
    );
}