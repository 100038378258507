import Cookies from "js-cookie";

export function guardthispage(userType){

    try {
        const storedType = readUserTypeCookie();
        if(storedType!=userType){
            switch(storedType) {
                case "Host":
                    window.location.replace("/dashboard/host");
                    break;
                case "Business":
                    window.location.replace("/dashboard/biz");
                    break;
                default:
                    window.location.replace("/dashboard/tech");
            }

        }
    }
    catch (e) {
        window.location.replace('/dashboard/');
    }
}

export function writeUserTypeCookie(userType){
    Cookies.set("FFUserType", userType, { expires: 7 });
}

export function readUserTypeCookie(){
    return Cookies.get("FFUserType");
}
