import {Box, Container, Flex, Grid, GridItem, HStack} from "@chakra-ui/react";
import React from "react";
import {IoHomeOutline} from "react-icons/io5";
import {LoginBubble} from "./Banner/LoginBubble";

export function Banner(){
    return(
        <Box h='50px' bg='transparent' color={'white'}>
            <Container maxWidth={'1090px'} bg='transparent' centerContent>
                <Grid templateColumns='repeat(8, 1fr)' width={'100%'} background={'transparent'}  gap={0}>
                    <GridItem w='100%' h='20' paddingTop={'15px'} bg='transparent'><Container ><a href={'/'}><IoHomeOutline color={'white'} size={'20'}/></a></Container></GridItem>
                    <GridItem w='100%' h='20' colSpan={5} bg='transparent'>&nbsp;</GridItem>
                    <GridItem w='100%' h='20' colSpan={2} paddingRight={'0'} paddingTop={'10px'}>
                        <LoginBubble/>
                    </GridItem>
                </Grid>
            </Container>
        </Box>
    );

}