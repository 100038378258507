
import {getEventPotentialTechCount, getEventStats} from "../scripts/InviteData";
import {
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    StatArrow,
    StatGroup, GridItem, Grid,
} from '@chakra-ui/react'
import React, {useState} from "react";
import {tidyDateTime} from "../scripts/Helper";
import { PhoneIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons'

export default function EventStats(props) {

    const [hasLoaded, setHasLoaded] = useState(false);
    const [stats, setStats] = useState("")

    if(!hasLoaded){
        const techcount = getEventStats(props.id);
        techcount.then(result=>{
            setStats(result.data);
            //console.log(result.data);
        })
        setHasLoaded(true);
    }

    if(hasLoaded && stats!=""){
    return(
        <Grid templateColumns='repeat(6, 1fr)' gap={0}>
            <GridItem w='100%' colSpan={5}><PhoneIcon h={3} color={"darkslategray"}/> Businesses Invited</GridItem>
            <GridItem w='100%' colSpan={1} textAlign={'right'}>{stats.invited}</GridItem>
            <GridItem w='100%' colSpan={5}><CheckIcon h={3} color={"green"}/> Businesses Accepted</GridItem>
            <GridItem w='100%' colSpan={1} textAlign={'right'}>{stats.accepted}</GridItem>
            <GridItem w='100%' colSpan={5}><CloseIcon h={3} color={"red"}/> Businesses Declined</GridItem>
            <GridItem w='100%' colSpan={1} textAlign={'right'}>{stats.declined}</GridItem>
        </Grid>
    )}
    else{
        return(
        <Grid templateColumns='repeat(6, 1fr)' gap={0}>
            <GridItem w='100%' colSpan={5}><PhoneIcon h={3} color={"darkslategray"}/> Businesses Invited</GridItem>
            <GridItem w='100%' colSpan={1} textAlign={'right'}>0</GridItem>
            <GridItem w='100%' colSpan={5}><CheckIcon h={3} color={"green"}/> Businesses Accepted</GridItem>
            <GridItem w='100%' colSpan={1} textAlign={'right'}>0</GridItem>
            <GridItem w='100%' colSpan={5}><CloseIcon h={3} color={"red"}/> Businesses Declined</GridItem>
            <GridItem w='100%' colSpan={1} textAlign={'right'}>0</GridItem>
        </Grid>)
    }

}