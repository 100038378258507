import {CognitoUserPool} from "amazon-cognito-identity-js";

const poolData={
    UserPoolId:"eu-west-1_oMmyfYWxu",
    ClientId:"548cbvqv22bf09be120hd5tlen",
    ClientSecret:"1ttn83jc0502nvc7fd28e0igc4i9tsub0fprkkq8v4nc5gcffo2g"
}

/*const poolData={
    UserPoolId:"eu-west-1_6PYOskjST",
    ClientId:"15tso0b12q6j2v71n0d4bc1l2o",
    ClientSecret:"fgohfsh0ft545lc3unul6a8cgpatai4g97slh3oqj1cqekgdigi"
}*/

export default new CognitoUserPool(poolData);