import React, {useEffect, useState} from "react";
import {
    getBusinessIDFromCog,
    isLoggedIn,
    postLogo,
    getLogo,
    getHostIDFromCog,
    getHostLogo
} from "../../../common/UserAPI/UserCalls";
import {
    Box, Button,
    Container,
    FormControl,
    Grid,
    GridItem,
    Heading,
    Input,
    InputGroup,
    InputLeftAddon,
    Text
} from "@chakra-ui/react";
import {getIDFromCookie} from "../../auth/TokenTools";
import {guardthispage} from "../../auth/PageTools";


export default function UpdateHostLogo() {

    const [firstLoadComplete, setFirstLoadComplete] = useState(false);
    const [bizID, setBizID] = useState(0);
    const [logo, setLogo] = useState();
    const [fileType, setFileType] = useState('');

    if(!firstLoadComplete){
        guardthispage("Host");
        getIDFromCookie().then((response) => {
            getHostIDFromCog(response).then((response2) => {
                setBizID(response2);
                getHostLogo(response2).then((response3) => {
                    setLogo(response3.currentLogo);
                });
            });
        });

        setFirstLoadComplete(true);
    }

    const [imageData, setImageData] = useState(null); // Store byte array of image
    const MAX_SIZE = 2 * 1024 * 1024; // Example: 2MB limit

    function byteArrayToBase64(byteArray) {
        let binary = '';
        const len = byteArray.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(byteArray[i]);
        }
        return btoa(binary);
    }

    function isJPEG(file) {
        return file && file.type === 'image/jpeg';
    }

    const handleImageChange = async (event) => {
        const file = event.target.files[0];

        if (!file) return;

        // Check file size
        if (file.size > MAX_SIZE) {
            alert(`File size exceeds ${MAX_SIZE / (1024 * 1024)}MB limit.`);
            return;
        }

        if (file) {
            // Check the MIME type
            const mimeType = file.type;
            setFileType(mimeType);

            // Example: Check if the file is an image
            if (mimeType.startsWith('image/')) {
                //console.log('File is an image.');
            } else {
                alert('File is not an image.');
                return;
            }
        }

        if (isJPEG(file)) {
            //console.log("The file is a JPEG image.");
        } else {
            alert("The file is not a JPEG image.");
            return;
        }

        // Read file as a byte array
        const byteArray = await fileToByteArray(file);
        setImageData(byteArray);
        const base64String = byteArrayToBase64(byteArray);
        await postLogo(bizID, base64String, "host");
    };

    // Convert file to byte array
    const fileToByteArray = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const arrayBuffer = reader.result;
                const byteArray = new Uint8Array(arrayBuffer);
                resolve(byteArray);
            };
            reader.onerror = (error) => reject(error);
            reader.readAsArrayBuffer(file); // Read file as array buffer
        });
    };

    return (

        <Box padding={'20px'} textColor={'darkslategray'} height={'400px'}>
            <Grid templateRows='repeat(1, 1fr)' templateColumns='repeat(5, 1fr)' gap={10} padding={'0px'} margin={'0px'} >
                <GridItem colSpan={5} padding={'0px'}>
                    <Heading padding={'0px'}>Update your Logo</Heading>
                    <Text marginY={'20px'}>You can use this form to upload a logo for your events.</Text>



                </GridItem>
                <GridItem colSpan={2} padding={'0px'}>

                    <Box maxW='sm' borderWidth='1px' borderRadius='lg' overflow='hidden' padding={'10px'}>
                        <Box mt='1' fontWeight='semibold' as='h4' lineHeight='tight' noOfLines={1} marginLeft={'20px'}>
                            <Text fontSize='2xl'>Your Current Logo</Text>
                        </Box>
                        {logo && <img src={'data:image/jpeg;base64,' + logo} width={'300px'}/>}

                    </Box>

                </GridItem>
                <GridItem colSpan={3} padding={'0px'}>

                    <Box maxW='sm' borderWidth='1px' borderRadius='lg' padding={'10px'}>
                        <Box mt='1' fontWeight='semibold' as='h4' lineHeight='tight' noOfLines={1} marginLeft={'20px'}>
                            <Text fontSize='2xl'>Upload New Image:</Text>
                        </Box>
                        <Box mt='1' fontWeight='semibold' as='h4' lineHeight='tight' marginLeft={'20px'} marginTop={'10px'}>
                            <Text fontSize='small' color={'red'}>Image must be a JPEG under 1024*1024 file size limit</Text><br/>
                            <form>
                                <input type="file" accept="image/*" onChange={handleImageChange}/>
                            </form>
                        </Box>
                        <Box margin={'20px'}>
                            {imageData && <Text fontSize='2xl' color={"green"}>Image Uploaded</Text>}
                        </Box>

                    </Box>


                    <Box>

                    </Box>
                </GridItem>

            </Grid>
        </Box>


    );
}