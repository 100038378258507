import {Button} from "@chakra-ui/react";
import React from "react";

export default function InviteStatus(props) {

    const responded = props.responseRecieved;
    const response = props.response;

    //console.log("Responded " + responded);
    //console.log("Response " + response);

    if(response){
        console.log("Accepted")
        return ( <Button variant='solid' marginTop={'6px'} colorScheme='green' isDisabled={true}>Accepted</Button> );
    }
    else if(responded && !response){
        console.log("Declined")
        return ( <Button variant='solid' marginTop={'6px'} colorScheme='red' isDisabled={true}>Declined</Button> );
    }
    else{
        console.log("Invited")
        return ( <Button variant='solid' marginTop={'6px'} colorScheme='gray' isDisabled={true}>Invited</Button> );
    }

}
