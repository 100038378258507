import React, {useState} from "react";
import {getFutureEvents, getPastEvents, getEventAvailability, acceptInvite, declineInvite} from "./scripts/TechScripts";
import {
    background,
    Box,
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardFooter,
    Container,
    Divider,
    Grid,
    GridItem,
    Heading,
    HStack,
    Image,
    Stack,
    StackDivider,
    Text,
    useDisclosure,
    useToast,
    VStack,
    Wrap,
    WrapItem
} from "@chakra-ui/react";
import {getIDFromCookie} from "../../auth/TokenTools";
import {getBusinessIDFromCog, getHostIDFromCog, getTechnologistIDFromCog} from "../../../common/UserAPI/UserCalls";
import {tidyDateTime} from "../events/scripts/Helper";
import {CalendarIcon} from '@chakra-ui/icons'
import EventAvailability from "./components/eventAvailability";
import {postInviteResponse} from "../opps/scripts/OppsData";
import {guardthispage} from "../../auth/PageTools";


export default function TechInvites() {
    const [eventsLoaded, setEventsLoaded] = useState(false);
    const [eventsList, setEventsList] = useState([]);
    const [pastEventList, setPastEventList] = useState([]);
    const [techID, setTechID] = useState(0);

    const toast = useToast()

    if(!eventsLoaded){
        guardthispage("Technologist");
        getIDFromCookie().then((response) => {
            getTechnologistIDFromCog(response).then((response2) => {
                setTechID(response2);
                const eventListRaw = getFutureEvents(response2);
                const pastEventListRaw = getPastEvents(response2);
                eventListRaw.then(result=>{
                    setEventsList(result.data);
                    //console.log(result.data);
                })
                pastEventListRaw.then(result=>{
                    setPastEventList(result.data);
                    //console.log(result.data);
                })
                setEventsLoaded(true);
            });
        });
    }

    function punchInvite(eventID, response){

        let fullData = {
            TechnologistID:techID,
            EventID:eventID
        }

        if(response){
            var status = acceptInvite(fullData);
            status.then(result=>{
                if(result){
                    toast({
                        title: 'Invite Accepted!',
                        description: "Your response has been recorded",
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    });
                }
                else{
                    toast({
                        title: 'Issues detected!',
                        description: "Nope sorry - something ain't working as expected",
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                }
            })
        }
        else{
            var status = declineInvite(fullData);
            status.then(result=>{
                if(result){
                    toast({
                        title: 'Invite Declined!',
                        description: "Your response has been recorded",
                        status: 'warning',
                        duration: 9000,
                        isClosable: true,
                    });
                }
                else{
                    toast({
                        title: 'Issues detected!',
                        description: "Nope sorry - something ain't working as expected",
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                }
            })
        }


    }

    return (

        <Grid templateRows='repeat(1, 1fr)' templateColumns='repeat(7, 1fr)' gap={5} paddingLeft={'10px'}>
            <GridItem colSpan={7} >
                <Box padding={'10px'} textColor={'darkslategray'} >

                    <Heading marginY={'20px'}>Upcoming Events</Heading>
                    <Text marginY={'20px'}>Here are the details of your upcoming invites</Text>
                    <Box paddingY={'20px'}>
                        <Wrap justify='left' spacing={'10px'}>
                            {eventsList.map(
                                ((event, index)=>(
                                        <WrapItem  key={event.id}>
                                            <Card width={'340px'} key={event.id} height={'350px'} backgroundColor={'white'} borderWidth={'2px'} borderColor={'gray.200'}>
                                                <CardBody padding={'0px'} margin={'0px'}>
                                                    <Stack mt='6' spacing='1'>
                                                        <Box backgroundColor={'#7CDAFF'} textColor={'slategray'} paddingX={'10px'} paddingY={'5px'}>
                                                            <Heading size='lg'>{event.eventName}</Heading>
                                                        </Box>
                                                        <Box paddingX={'10px'} paddingY={'5px'}>
                                                            <Text size='md' height={'30px'} overflow={'hidden'}><strong>You are invited to attend at :</strong></Text>
                                                            <Divider marginY={'5px'} />
                                                            <Grid templateColumns='repeat(6, 1fr)' gap={0}>
                                                                <GridItem w='100%' colSpan={2}><CalendarIcon h={3} color={"darkslategrey"}/> Date</GridItem>
                                                                <GridItem w='100%' colSpan={4} textAlign={'right'}><strong>{tidyDateTime(event.eventDate)}</strong></GridItem>
                                                            </Grid>
                                                            <Divider marginY={'5px'} />
                                                            <Grid templateColumns='repeat(6, 1fr)' gap={0}>
                                                                <GridItem w='100%' colSpan={2}>Name</GridItem>
                                                                <GridItem w='100%' colSpan={4} textAlign={'right'}>{event.eventAddress1}</GridItem>
                                                                <GridItem w='100%' colSpan={2}>Street</GridItem>
                                                                <GridItem w='100%' colSpan={4} textAlign={'right'}>{event.eventAddress2} {event.eventAddress3}</GridItem>
                                                                <GridItem w='100%' colSpan={2}>Town / City</GridItem>
                                                                <GridItem w='100%' colSpan={4} textAlign={'right'}>{event.eventAddress4}, {event.eventAddress5}</GridItem>
                                                                <GridItem w='100%' colSpan={2}>Postcode</GridItem>
                                                                <GridItem w='100%' colSpan={4} textAlign={'right'}>{event.eventAddress6}</GridItem>
                                                            </Grid>
                                                            <Divider marginY={'5px'} />
                                                            <Grid templateColumns='repeat(6, 1fr)' gap={0}>
                                                                <GridItem w='100%' colSpan={2}><CalendarIcon h={3} color={"darkslategrey"}/> Availability</GridItem>
                                                                <EventAvailability cap={event.eventCapacity} level={event.eventLevel}/>
                                                            </Grid>
                                                            <Divider marginY={'5px'} />
                                                            <Grid templateColumns='repeat(6, 1fr)' gap={0} margin={'10px'}>

                                                                {event.eventLevel<event.eventCapacity ? (
                                                                    <GridItem w='100%' colSpan={3} textAlign={'right'}><Button variant='solid' width={'100px'} margin={'5px'} marginTop={'0px'} colorScheme='green' isDisabled={event.response && event.responded} onClick={() => {punchInvite(event.eventID, true)}}>Accept</Button></GridItem>
                                                                ) : (
                                                                    <GridItem w='100%' colSpan={3} textAlign={'right'}><Button variant='solid' width={'100px'} margin={'5px'} marginTop={'0px'} colorScheme='green' isDisabled={true}>SOLD OUT</Button></GridItem>
                                                                )}

                                                                {event.eventLevel<event.eventCapacity ? (
                                                                    <GridItem w='100%' colSpan={3} textAlign={'left'}><Button variant='solid' width={'100px'} margin={'5px'} marginTop={'0px'} colorScheme='red' isDisabled={!event.response && event.responded} onClick={() => {punchInvite(event.eventID, false)}}>Decline</Button></GridItem>
                                                                ) : (
                                                                    <GridItem w='100%' colSpan={3} textAlign={'left'}><Button variant='solid' width={'100px'} margin={'5px'} marginTop={'0px'} colorScheme='red' isDisabled={true}>SOLD OUT</Button></GridItem>

                                                                )}



                                                                </Grid>
                                                            <Container>


                                                            </Container>
                                                        </Box>
                                                    </Stack>
                                                </CardBody>
                                            </Card>
                                        </WrapItem>
                                    )
                                ))}
                        </Wrap>
                    </Box>

                    <Divider></Divider>
                    <Heading marginY={'20px'}>Past Events</Heading>
                    <Box paddingY={'20px'}>
                        <Wrap justify='left' spacing={'20px'}>
                            {pastEventList.map(
                                ((event, index)=>(
                                        <WrapItem  key={event.id}>
                                            <Card width={'320px'} key={event.id} backgroundColor={'white'} borderWidth={'2px'} borderColor={'gray.200'}>
                                                <CardBody padding={'0px'} margin={'0px'}>
                                                    <Stack mt='6' spacing='3'>
                                                        <Box backgroundColor={'#FF7E79'} textColor={'white'} paddingX={'10px'} paddingY={'5px'}>
                                                            <Heading size='lg'>{event.eventName}</Heading>
                                                        </Box>
                                                        <Box paddingX={'10px'} paddingY={'5px'}>
                                                            <Text size='md' height={'30px'} overflow={'hidden'}><strong>You attended on : </strong></Text>
                                                            <Divider marginY={'5px'} />
                                                            <Grid templateColumns='repeat(6, 1fr)' gap={0}>
                                                                <GridItem w='100%' colSpan={2}><CalendarIcon h={3} color={"darkslategrey"}/> Date</GridItem>
                                                                <GridItem w='100%' colSpan={4} textAlign={'right'}><strong>{tidyDateTime(event.eventDate)}</strong></GridItem>
                                                            </Grid>
                                                            <Divider marginY={'5px'} />
                                                            <Grid templateColumns='repeat(6, 1fr)' gap={0}>
                                                                <GridItem w='100%' colSpan={2}>Name</GridItem>
                                                                <GridItem w='100%' colSpan={4} textAlign={'right'}>{event.eventAddress1}</GridItem>
                                                                <GridItem w='100%' colSpan={2}>Street</GridItem>
                                                                <GridItem w='100%' colSpan={4} textAlign={'right'}>{event.eventAddress2} {event.eventAddress3}</GridItem>
                                                                <GridItem w='100%' colSpan={2}>Town / City</GridItem>
                                                                <GridItem w='100%' colSpan={4} textAlign={'right'}>{event.eventAddress4}, {event.eventAddress5}</GridItem>
                                                                <GridItem w='100%' colSpan={2}>Postcode</GridItem>
                                                                <GridItem w='100%' colSpan={4} textAlign={'right'}>{event.eventAddress6}</GridItem>
                                                            </Grid>

                                                        </Box>
                                                    </Stack>
                                                </CardBody>
                                            </Card>
                                        </WrapItem>
                                    )
                                ))}
                        </Wrap>
                    </Box>

                </Box>
            </GridItem>
        </Grid>


    );



}
