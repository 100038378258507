import {Box, Button, Divider, Stack, VStack} from '@chakra-ui/react'
import { Icon } from '@chakra-ui/react'
import { MdHome, MdAutoAwesome, MdOutlineCalendarMonth, MdAddLink, MdManageHistory } from 'react-icons/md'

export default function Business() {
    return (
        <VStack spacing={'15px'} color={'#222222'}>
            <Divider />
            <Button leftIcon={<MdHome/>} w='100%' h='30px' bg='white.100' borderRadius={'lg'} paddingY={'2px'} paddingX={'10px'} justifyContent="flex-start" onClick={event =>  window.location.href='/dashboard/biz/'}>Dashboard</Button>
            <Button leftIcon={<MdAutoAwesome/>} w='100%' h='30px' bg='white.100' borderRadius={'lg'} paddingY={'2px'} paddingX={'10px'} justifyContent="flex-start"  onClick={event =>  window.location.href='/dashboard/business/updatelogo/'}>Update your Logo</Button>
            <Button leftIcon={<MdAutoAwesome/>} w='100%' h='30px' bg='white.100' borderRadius={'lg'} paddingY={'2px'} paddingX={'10px'} justifyContent="flex-start"  onClick={event =>  window.location.href='/dashboard/business/createOpp/'}>Create Opportunity</Button>
            <Button leftIcon={<MdAutoAwesome/>} w='100%' h='30px' bg='white.100' borderRadius={'lg'} paddingY={'2px'} paddingX={'10px'} justifyContent="flex-start"  onClick={event =>  window.location.href='/dashboard/business/manageOpp/'}>Manage Opportunities</Button>
            <Button leftIcon={<MdOutlineCalendarMonth/>} w='100%' h='30px' bg='white.100' borderRadius={'lg'} paddingY={'2px'} paddingX={'10px'} justifyContent="flex-start" onClick={event =>  window.location.href='/dashboard/business/manageInvites/'}>View Invites</Button>
            <Button leftIcon={<MdOutlineCalendarMonth/>} w='100%' h='30px' bg='white.100' borderRadius={'lg'} paddingY={'2px'} paddingX={'10px'} justifyContent="flex-start" onClick={event =>  window.location.href='/dashboard/business/events/'}>View Events</Button>
            <Button leftIcon={<MdAddLink/>} w='100%' h='30px' bg='white.100' borderRadius={'lg'} paddingY={'2px'} paddingX={'10px'} justifyContent="flex-start" onClick={event =>  window.location.href='/dashboard/business/connections/'}>Review my Connections</Button>
        </VStack>
    )
}