import {
    Box,
    Button, Center,
    Checkbox,
    Container,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    Grid,
    GridItem,
    Input,
    InputGroup,
    InputLeftAddon, Link,
    Select, Skeleton,
    Stack,
    Text,
    useToast,
    VStack
} from "@chakra-ui/react";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useFormik} from 'formik';
import {postPreReg} from "./datasources/PreRegisterObjects";
import {ReCAPTCHA} from 'react-google-recaptcha';

export default function PreRegisterForm() {

    const navigate = useNavigate();

    const siteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;
    const secretKey = process.env.REACT_APP_CAPTCHA_SECRET_KEY;

    // State variables for this page
    const [userID, setUserID] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [regions, setRegions] = useState(JSON.parse("{}"));
    const [experience, setExperience] = useState(JSON.parse("{}"));
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [posterror, setPosterror] = useState(false);
    const [captchaDone, setCaptchaDone] = useState(false);

    const toast = useToast()

    const validate = values => {
        const errors = {};
        if (!values.fullName) {
            errors.fullName = 'Your full name is required';
        } else if (values.fullName.length < 6) {
            errors.fullName = 'Your full name must be 6 characters or more';
        }

        if (!values.emailAddress) {
            errors.emailAddress = 'Your email address is essential';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.emailAddress)) {
            errors.emailAddress = 'Sorry - Invalid email address';
        }

        if (values.currentRole === '') {
            errors.currentRole = 'Please select your current career level';
        }
        if (values.region === '') {
            errors.region = 'Please select your region';
        }
        if (values.interest === '') {
            errors.interest = 'Please select your area of interest';
        }
        if (!values.terms) {
            errors.terms = 'Please accept the Terms and Conditions';
        }
        if (!values.privacy) {
            errors.privacy = 'Please accept the Privacy Policy';
        }

        return errors;
    };

    const formik = useFormik({
        initialValues: {
            fullName: '',
            emailAddress: '',
            currentRole: '',
            region: '',
            interest: '',
            terms: '',
            privacy: '',
        },
        validate,
        onSubmit: values => {


            setSubmitting(true);
            toast({
                title: 'Form submitting',
                description: "We've submitted your details",
                status: 'info',
                duration: 9000,
                isClosable: true,
            });
            const dataObject = (JSON.stringify(values, null, 2));
            postPreReg(dataObject).then(function (response) {
                //console.log(response);
                setSubmitting(false);
                setSubmitted(true);
                toast({
                    title: 'All done',
                    description: "All good - We'll be in touch",
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
            })
                .catch(function (error) {
                    setSubmitting(false);
                    setSubmitted(false);
                    setPosterror(true);
                    toast({
                        title: 'Issues detected!',
                        description: "Nope sorry - something ain't working as expected",
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                });


        },
    });

    function onChange(){
        setCaptchaDone(true);
    }


    if (!submitting && !submitted && !posterror) {
        return (
            <Box maxWidth={'600px'} marginTop={'30px'} padding={'20px'} bg='white' border={'solid'} borderRadius={'10px'}>

                <Center bg='white' fontSize={'x-large'} paddingBottom={'20px'}><strong><code>Pre-Registration Form</code></strong></Center>
                <form onSubmit={formik.handleSubmit}>
                    <Container marginBottom={'20px'}>Complete the details below and we will let you know when we're
                        going live.</Container>
                    {formik.errors.fullName ? <Container backgroundColor={'pink'} marginY={'7px'} borderRadius={'5px'}
                                                         className={'montserrat-free'}>{formik.errors.fullName}</Container> : null}
                    {formik.errors.emailAddress ?
                        <Container backgroundColor={'lightyellow'} marginY={'7px'} borderRadius={'5px'}
                                   className={'montserrat-free'}>{formik.errors.emailAddress}</Container> : null}
                    {!formik.errors.fullName && !formik.errors.emailAddress && formik.errors.currentRole ?
                        <Container backgroundColor={'lightblue'} marginY={'7px'} borderRadius={'5px'}
                                   className={'montserrat-free'}>{formik.errors.currentRole}</Container> : null}
                    {!formik.errors.fullName && !formik.errors.emailAddress && formik.errors.region ?
                        <Container backgroundColor={'lightgreen'} marginY={'7px'} borderRadius={'5px'}
                                   className={'montserrat-free'}>{formik.errors.region}</Container> : null}
                    {!formik.errors.fullName && !formik.errors.emailAddress && formik.errors.interest ?
                        <Container backgroundColor={'grey'} color={'white'} marginY={'7px'} borderRadius={'5px'}
                                   className={'montserrat-free'}>{formik.errors.interest}</Container> : null}
                    {!formik.errors.fullName && !formik.errors.emailAddress && !formik.errors.currentRole && !formik.errors.region && !formik.errors.interest && formik.errors.terms ?
                        <Container backgroundColor={'lightblue'} color={'black'} marginY={'7px'} borderRadius={'5px'}
                                   className={'montserrat-free'}>{formik.errors.terms}</Container> : null}
                    {!formik.errors.fullName && !formik.errors.emailAddress && !formik.errors.currentRole && !formik.errors.region && !formik.errors.interest && formik.errors.privacy ?
                        <Container backgroundColor={'palevioletred'} color={'white'} marginY={'7px'}
                                   borderRadius={'5px'}
                                   className={'montserrat-free'}>{formik.errors.privacy}</Container> : null}
                    <FormControl paddingTop={'20px'}>
                        <InputGroup size='md' padding={'10px'} paddingBottom='5' paddingTop='0'>
                            <InputLeftAddon borderColor={"darkblue"} minW='130'>Full Name</InputLeftAddon>
                            <Input borderColor={"darkblue"} id='fullName' name='fullName' onChange={formik.handleChange}
                                   value={formik.values.fullName}/>
                        </InputGroup>
                        <InputGroup size='md' padding={'10px'} paddingBottom='5' paddingTop='0'>
                            <InputLeftAddon borderColor={"darkblue"} minW='130'>Email</InputLeftAddon>
                            <Input borderColor={"darkblue"} id='emailAddress' name='emailAddress'
                                   onChange={formik.handleChange} value={formik.values.emailAddress}/>
                        </InputGroup>
                        <InputGroup size='md' padding={'10px'} paddingBottom='5' paddingTop='0'>
                            <InputLeftAddon borderColor={"darkblue"} minW='130'>Current Role</InputLeftAddon>
                            <Select borderColor={"darkblue"} id='currentRole' name='currentRole'
                                    onChange={formik.handleChange}
                                    value={formik.values.currentRole}>
                                <option value=''>** Please Select **</option>
                                <option value='Founder'>Founder</option>
                                <option value='Student'>Student</option>
                                <option value='Graduate'>Graduate</option>
                                <option value='Junior'>Junior</option>
                                <option value='Seasoned'>Seasoned</option>
                                <option value='Senior'>Senior</option>
                                <option value='Management'>Management</option>
                                <option value='Snr Management'>Snr Management</option>
                                <option value='Director'>Director</option>
                                <option value='Executive'>Executive</option>
                            </Select>
                        </InputGroup>
                        <InputGroup size='md' padding={'10px'} paddingBottom='5' paddingTop='0'>
                            <InputLeftAddon borderColor={"darkblue"} minW='130'>Region</InputLeftAddon>
                            <Select borderColor={"darkblue"} id='region' name='region' onChange={formik.handleChange}
                                    value={formik.values.region}>
                                <option value=''>** Please Select **</option>
                                <option value='NI'>Northern Ireland</option>
                                <option value='ROI'>Republic of Ireland</option>
                                <option value='IRE'>Whole island of Ireland</option>
                            </Select>
                        </InputGroup>
                        <InputGroup size='md' padding={'10px'} paddingBottom='5' paddingTop='0'>
                            <InputLeftAddon borderColor={"darkblue"} minW='130'>Key Interest</InputLeftAddon>
                            <Select borderColor={"darkblue"} id='interest' name='interest'
                                    onChange={formik.handleChange} value={formik.values.interest}>
                                <option value=''>** Please Select **</option>
                                <option value='Tech'>Finding exciting tech opportunities</option>
                                <option value='CoFounders'>Finding great co-founders</option>
                                <option value='Hiring'>Finding great team members</option>
                                <option value='Hosting'>Hosting community events</option>
                            </Select>
                        </InputGroup>
                        <InputGroup size='md' padding={'10px'} paddingBottom='5' paddingTop='0'>
                            <Stack spacing={5} direction='row'>
                                <Checkbox colorScheme='red' id='terms' name='terms' onChange={formik.handleChange}
                                          value={formik.values.terms} aria-labelledby="tText"/>
                                <Link href='/TANDC.pdf' isExternal id="tText" rel="noopener noreferrer" target="_blank">Terms & Conditions</Link>
                                <Checkbox colorScheme='green' id='privacy' name='privacy' onChange={formik.handleChange}
                                          value={formik.values.privacy} aria-labelledby="pText"/>
                                <Link href='/PP.pdf'  isExternal id="pText" target="_blank" rel="noopener noreferrer">Privacy Policy</Link>
                            </Stack>
                        </InputGroup>
                    </FormControl>

                    <Grid templateColumns='repeat(5, 1fr)' gap={6} minHeight='50px' padding={'20px'} width='100%'>
                        <GridItem colSpan={2} h='10' width='100%' paddingTop={'2px'}>
                                <ReCAPTCHA sitekey={siteKey}  onChange={onChange} />
                        </GridItem>
                        <GridItem colSpan={2} h='10' width='100%' paddingTop={'2px'}></GridItem>
                        <GridItem h='10' width='100%' textAlign="right">
                            <Button type="submit" colorScheme='green' disabled={!captchaDone}>Pre-Register</Button>
                        </GridItem>
                    </Grid>

                </form>
            </Box>

        )
    }

    if(submitting){
        return (
            <Box maxWidth={'600px'} minHeight={'670px'} marginTop={'30px'} padding={'20px'} bg='white' border={'solid'}
                 borderRadius={'10px'}>
                <Center bg='white' fontSize={'xx-large'}
                        paddingBottom={'20px'}><strong><code>Submitting!</code></strong></Center>
                <Center bg='white' paddingY={'20px'}><Box paddingBottom={'10px'}><img src="/clock.png" width={'320px'}
                                                                                      alt={'Waiting is the hardest part'}/></Box></Center>
                We're submitting your details to our servers. We know that waiting can be tough but hopefully this will
                be swift and the cloud will do it's thing!
                <br/><br/>
                <h1 className={'montserrat-h2'}>Why not follow us on social media while we get things ready.</h1>
            </Box>
        )
    }

    if (submitted) {
        return (
            <Box maxWidth={'600px'} minHeight={'670px'} marginTop={'30px'} padding={'20px'} bg='white' border={'solid'}
                 borderRadius={'10px'}>
                <Center bg='white' fontSize={'xx-large'} paddingBottom={'20px'}><strong><code>Submitted!</code></strong></Center>
                <Center bg='white' paddingY={'20px'}><Box paddingBottom={'10px'}><img src="/superhero-ff.png"
                                                                                      width={'320px'}
                                                                                      alt={'You are a hero - to us'}/></Box></Center>
                The term 'Hero' is used a lot these days but you are a hero to us.<br/><br/>Give yourself a pat on the
                back (careful now with that super strength) and we will be in touch when it's time to make a change.<br/><br/>
                <h1 className={'montserrat-h2'}>Why not follow us on social media while we get things ready.</h1>
            </Box>
        )
    }

    if (posterror) {
        return (
            <Box maxWidth={'600px'} marginTop={'30px'} padding={'20px'} bg='white' border={'solid'}
                 borderRadius={'10px'}>
                <Center bg='white' fontSize={'xx-large'} paddingBottom={'20px'}><strong><code>Something is not
                    right!</code></strong></Center>
                <Center bg='white' paddingY={'20px'}><Box paddingBottom={'10px'}><img src="/sadness.png" width={'320px'}
                                                                                      alt={'You are a hero - to us'}/></Box></Center>
                Sorry we have detected an issue.<br/><br/>Maybe you already registered or maybe there is an issue in the
                space-time continuum. Please try again later or contact us online.<br/><br/>
                <h1 className={'montserrat-h2'}>Why not follow us on social media while we get things ready.</h1>

            </Box>
        )
    }

}

//<a href="https://www.flaticon.com/free-icons/superhero" title="superhero icons">Superhero icons created by Freepik - Flaticon</a>
//<a href="https://www.flaticon.com/free-icons/sad" title="sad icons">Sad icons created by Flat Icons - Flaticon</a>