import {
    Box,
    Button,
    Card,
    CardBody, CardFooter, CardHeader,
    Container, Divider, Grid, GridItem,
    Heading,
    SimpleGrid,
    Stack,
    StackDivider,
    Text,
    VStack, Wrap, WrapItem
} from "@chakra-ui/react";
import InviteStatus from "./components/InviteStatus";
import React, {useState} from "react";
import {
    activateOpp,
    getEvents, getExpiredOpportunitesForCompany, getInvitesForOpportunites,
    getOpportunites,
    getOpportunitesForCompany,
    getOppsInvited, postInviteResponse,
    postOppInvite
} from "../opps/scripts/OppsData";
import {getIDFromCookie} from "../../auth/TokenTools";
import {getBusinessIDFromCog} from "../../../common/UserAPI/UserCalls";
import UpdateOppForm from "../opps/forms/UpdateOppForm";
import {guardthispage} from "../../auth/PageTools";

export default function ManageO() {

    const [oppsLoaded, setOppsLoaded] = useState(false);
    const [punchback, setPunchback] = useState(false);
    const [inviteList, setInviteList] = useState([])
    const [oppList, setOppList] = useState([]);
    const [expiredList, setExpiredList] = useState([]);
    const [oppSelected, setOppSelected] = useState(0);
    const [bizID, setBizID] = useState();
    const [reloader, setReloader] = useState(false)

    if(!oppsLoaded){
        guardthispage("Business");
        getIDFromCookie().then((response) => {
            getBusinessIDFromCog(response).then((response2) => {
                setBizID(response2);
                const oppsListRaw = getOpportunitesForCompany(response2);
                const expListRaw = getExpiredOpportunitesForCompany(response2);
                oppsListRaw.then(result=>{ setOppList(result.data); });
                expListRaw.then(result2=>{ setExpiredList(result2.data);})
                setOppsLoaded(true);
            });
        });



    }

    if(punchback){
        const inviteListRaw = getInvitesForOpportunites(oppSelected);
        inviteListRaw.then(result=>{
            setInviteList(result.data);
        })
        setPunchback(false);
    }

    function punchOpp(oppId){
        setInviteList([]);
        setOppSelected(oppId);
        const inviteListRaw = getInvitesForOpportunites(oppId);
        inviteListRaw.then(result=>{
            setInviteList(result.data);
        })
    }

    function activeOpp(oppId){
        const activateSuccess = activateOpp(oppId);
        activateSuccess.then(result=>{
            setOppsLoaded(false);
        })
    }


    if(oppSelected>0){
        return(
            <UpdateOppForm ID={oppSelected}/>
        );

    }
    else {
        return (
            <Box padding={'20px'} textColor={'darkslategray'}>
                <Grid templateRows='repeat(1, 1fr)' templateColumns='repeat(5, 1fr)' gap={2}>
                    <GridItem colSpan={3}>
                        <Heading>Manage Your Opportunities</Heading>
                        <Text marginY={'20px'}>An Opportunity is your chance to look for that key technologist to fill a
                            role in your business. Tell us the name, the type of opportunity and give people some
                            additional details. You may be invited to pitch this at an upcoming event.</Text>
                        <Box>
                            <VStack divider={<StackDivider borderColor='gray.200'/>} spacing={4} align='stretch'>
                                <Heading size='md'>Active Opportunities</Heading>
                                <Wrap>
                                    {oppList.map(
                                        ((opp, index) => (
                                                <WrapItem>
                                                    <Card width={'207px'} height={'270px'} key={opp.id}
                                                          background={'whitesmoke'}>
                                                        <CardHeader borderBottomColor={'slategray'}
                                                                    borderBottomWidth={'1px'}><Heading size='md'
                                                                                                       margin={'0px'}>{opp.name}</Heading></CardHeader>
                                                        <CardBody background={'white'} borderBottomColor={'slategray'}
                                                                  borderBottomWidth={'1px'}>
                                                            <Text><strong>Type</strong> : {opp.opportunityTypeName}
                                                            </Text>
                                                            <Text marginTop={'2px'} fontSize={'small'} height={'40px'} overflow={'hidden'}>{opp.description}</Text>
                                                        </CardBody>
                                                        <CardFooter margin={'0px'}>
                                                            {opp.active ? (
                                                                <Button variant='solid' margin={'0px'} marginRight={'5px'} colorScheme='yellow' isDisabled={'true'}>Edit</Button>
                                                            ) : (
                                                                <Button variant='solid' margin={'0px'} marginRight={'5px'} colorScheme='yellow' onClick={() => { punchOpp(opp.id) }}>Edit</Button>
                                                            )}

                                                            {opp.active ? (
                                                                <Button variant='solid' margin={'0px'} marginTop={'0px'} colorScheme='red' isDisabled={'true'}>Activate</Button>
                                                            ) : (
                                                                <Button variant='solid' margin={'0px'} marginTop={'0px'} colorScheme='red' onClick={() => { activeOpp(opp.id) }}>Activate</Button>
                                                            )}
                                                        </CardFooter>
                                                    </Card>
                                                </WrapItem>
                                            )
                                        ))}
                                </Wrap>
                                <Heading size='md'>Expired Opportunities</Heading>
                                <Wrap>
                                    {expiredList.map(
                                        ((opp, index) => (
                                                <WrapItem>
                                                    <Card width={'207px'} height={'190px'} key={opp.id}
                                                          background={'lightpink'}>
                                                        <CardHeader borderBottomColor={'slategray'}
                                                                    borderBottomWidth={'1px'}><Heading size='md'
                                                                                                       margin={'0px'}>{opp.name}</Heading></CardHeader>
                                                        <CardBody background={'white'}>
                                                            <Text><strong>Type</strong> : {opp.opportunityTypeName}
                                                            </Text>
                                                            <Text marginTop={'2px'}>{opp.description}</Text>
                                                        </CardBody>
                                                    </Card>
                                                </WrapItem>
                                            )
                                        ))}
                                </Wrap>


                            </VStack>
                        </Box>
                    </GridItem>
                    <GridItem colSpan={2} borderLeft={"solid"} paddingLeft={'20px'}>
                        <Heading>Notes</Heading>
                        <Text marginY={'20px'}>As you complete the form helpful text will appear here to help keep you
                            on track.</Text>
                    </GridItem>
                </Grid>
            </Box>
        );
    }
}
